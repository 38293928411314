import { ReactNode } from "react";
import { Tooltip, TooltipContent, TooltipPortal, TooltipProvider, TooltipTrigger } from "@geotab/react-component-library";
import { IconCircleInfo } from "../../Navbar/icons";

export default function getDataTypeData(dataType: string): ReactNode {
    return hasToolTip(dataType) ? getToolTipInfoForDataType(dataType) : getDefaultDataTypeInfo(dataType);
}

function hasToolTip(dataType: string): boolean {
    return (
        dataType === "long" ||
        dataType === "Long" ||
        dataType === "Int64" ||
        dataType === "DateTime" ||
        dataType === "Nullable{DateTime}" ||
        dataType === "date" ||
        dataType === "TimeSpan" ||
        dataType === "timespan" ||
        dataType === "byteArray"
    );
}

function generateToolTip(description: string): ReactNode {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild={true}>
                    <IconCircleInfo />
                </TooltipTrigger>
                <TooltipPortal>
                    <TooltipContent variant="large" side="right" align="center">
                        {description}
                    </TooltipContent>
                </TooltipPortal>
            </Tooltip>
        </TooltipProvider>
    );
}

function getToolTipInfoForDataType(dataType: string): ReactNode {
    if (dataType === "long" || dataType === "Long" || dataType === "Int64") {
        return (
            <div className="dataTypeToolTip__container">
                <span>String</span>
                {generateToolTip("64bit integer is represented by a hex string in JSON because some languages do not support 64bit number type")}
            </div>
        );
    } else if (dataType === "DateTime" || dataType === "Nullable{DateTime}" || dataType === "date") {
        return (
            <div className="dataTypeToolTip__container">
                <span>String</span>
                {generateToolTip(`Date time is represented by an ISO string in UTC time ("2000-01-01T01:00:00.000Z")`)}
            </div>
        );
    } else if (dataType === "TimeSpan" || dataType === "timespan") {
        return (
            <div className="dataTypeToolTip__container">
                <span>String</span>
                {generateToolTip(`An time span interval represented in the format [-][d.]hh:mm:ss[.fffffff]. For example: "2.05:02:44.928"`)}
            </div>
        );
    } else {
        return (
            <div className="dataTypeToolTip__container">
                <span>String</span>
                {generateToolTip("A byte array represented by a base64 encoded string")}
            </div>
        );
    }
}

function getDefaultDataTypeInfo(dataType: string): ReactNode {
    if (dataType.includes("[]") || dataType.includes("List") || dataType.includes("ICollection")) {
        return "Array";
    } else if (dataType.includes("Dictionary") || dataType.includes("`1")) {
        return "Object";
    } else if (dataType === "Double" || dataType === "Byte" || dataType === "Int32") {
        return "Number";
    } else if (dataType === "Guid") {
        return "String";
    } else if (dataType === "Currencies") {
        return "Object";
    }
    else if (dataType === "Currency") {
        return "String";
    }
    else if (dataType === "DayOfWeek") {
        return "String";
    }
    else if (dataType === "ISort") {
        return "Object";
    } else {
        return dataType;
    }
}
