import { useParams } from "react-router-dom";
import { driveMethods, driveObjects } from "./utils/driveReferenceEntries";
import NotFoundPage from "../../pages/404Page";
import ApiObject from "./ApiObject";
import ApiMethod, { ApiMethodParameter } from "./ApiMethod";
import { HeaderSections } from "../Header/headerSectionsEnum";
import { CodeSamples } from "../CodeSamplesContainer";
import { ReferenceSections } from "./referenceSectionsEnum";
import { PageTitleProps } from "../PageTitle/PageTitle";
import { WIP_STRING } from "./utils/apiReferenceUtils";

export default function ApiReferenceDetails(): JSX.Element {
    const { referenceId = "" } = useParams();
    let displayBetaTag: boolean | undefined;
    let returns: JSX.Element | undefined;
    let parameters: ApiMethodParameter[] | undefined;
    if (referenceId in driveMethods) {
        let codeSamples: CodeSamples = driveMethods[referenceId].codeSamples ? driveMethods[referenceId].codeSamples : { javascript: WIP_STRING };
        let title: PageTitleProps = {
            title: referenceId + " (...)",
            breadCrumbItems: [ReferenceSections.Drive, "API Reference", "Methods", referenceId + " (...)"],
            isBeta: displayBetaTag
        };
        let intro: JSX.Element = <div>{driveMethods[referenceId].description}</div>;
        if (driveMethods[referenceId].returnType) {
            returns = driveMethods[referenceId].returnType;
        }
        if (driveMethods[referenceId].parameters) {
            parameters = driveMethods[referenceId].parameters;
        }
        return <ApiMethod introduction={intro} codeSamples={codeSamples} parameters={parameters} title={title} headerSection={HeaderSections.Drive} returns={returns} />;
    } else if (referenceId in driveObjects) {
        if (!driveObjects[referenceId].properties) {
            driveObjects[referenceId].properties = [];
        }
        let title: PageTitleProps = {
            title: referenceId,
            breadCrumbItems: [ReferenceSections.Drive, "API Reference", "Objects", referenceId],
            isBeta: displayBetaTag
        };
        let intro: JSX.Element | undefined;
        if (driveObjects[referenceId].description) {
            intro = <div>{driveObjects[referenceId].description}</div>;
        }
        return <ApiObject introduction={intro} title={title} properties={driveObjects[referenceId].properties} headerSection={HeaderSections.Drive} />;
    } else {
        return <NotFoundPage />;
    }
}
