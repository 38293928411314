/* eslint-disable max-len */
import * as React from "react";
import { LogoProps } from "@geotab/react-component-library";

export const LogoGeotabFooter = React.forwardRef<SVGSVGElement, LogoProps>(() => (
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="16" viewBox="0 0 120 16" fill="none">
        <g clipPath="url(#clip0_1216_2759)">
            <path
                d="M119.667 13.5494C119.668 14.0027 119.513 14.4426 119.227 14.7943C118.941 15.146 118.542 15.3876 118.097 15.4782C117.653 15.5688 117.191 15.5026 116.79 15.291C116.389 15.0794 116.074 14.7355 115.898 14.3177C115.722 13.8998 115.697 13.434 115.825 12.9993C115.954 12.5646 116.23 12.1881 116.605 11.9336C116.98 11.6792 117.432 11.5626 117.883 11.6037C118.335 11.6448 118.758 11.841 119.081 12.159C119.269 12.3382 119.418 12.5541 119.519 12.7933C119.619 13.0325 119.67 13.2898 119.667 13.5494ZM119.4 13.5494C119.398 13.331 119.354 13.1151 119.268 12.9142C119.183 12.7132 119.058 12.5312 118.902 12.3786C118.589 12.0606 118.161 11.8801 117.715 11.8768C117.268 11.8735 116.838 12.0477 116.52 12.361L116.502 12.3786C116.183 12.6989 116.004 13.1325 116.004 13.5845C116.004 14.0365 116.183 14.4701 116.502 14.7904C116.816 15.1084 117.243 15.2889 117.69 15.2922C118.137 15.2955 118.566 15.1213 118.885 14.808L118.902 14.7904C119.066 14.6309 119.194 14.4394 119.28 14.2278C119.365 14.0163 119.406 13.7892 119.4 13.5611V13.5494ZM118.964 14.5592H118.455L117.705 13.7542H117.339V14.5592H116.956V12.4752H117.664C117.78 12.4679 117.897 12.4679 118.013 12.4752C118.108 12.4881 118.2 12.5178 118.285 12.563C118.37 12.6066 118.442 12.6723 118.493 12.7532C118.539 12.8381 118.562 12.9344 118.557 13.0313C118.561 13.165 118.515 13.2954 118.428 13.3972C118.331 13.5019 118.209 13.5813 118.074 13.6284L118.964 14.5592ZM118.165 13.0752C118.167 13.0246 118.157 12.9744 118.135 12.9285C118.112 12.8832 118.075 12.8463 118.03 12.8231C117.984 12.799 117.935 12.7832 117.883 12.7764H117.345V13.4789H117.637C117.718 13.4873 117.8 13.4873 117.88 13.4789C117.941 13.4687 117.998 13.4446 118.047 13.4086C118.09 13.3766 118.124 13.3344 118.147 13.2857C118.173 13.2157 118.181 13.1401 118.17 13.0662L118.165 13.0752Z"
                fill="white"
            />
            <path d="M21.8248 15.5109H36.6214V13.9976H24.4977V8.50573H36.1196V6.98853H24.4977V1.99053H36.6214V0.473328H21.8248V15.5109Z" fill="white" />
            <path
                d="M56.5285 1.56771C55.4499 0.741981 52.704 0.323181 48.3874 0.323181C44.6537 0.323181 42.0658 0.690648 40.6968 1.41565C39.3277 2.14065 38.6146 3.52938 38.6146 5.52465L38.5813 6.94505V7.99405C38.5813 11.4294 39.1206 13.5788 40.1775 14.4243C41.2344 15.2698 44.0042 15.6768 48.3877 15.6768C52.7712 15.6768 55.5686 15.2422 56.5979 14.4124C57.6272 13.5827 58.1941 11.3484 58.1941 7.79845C58.1721 4.49938 57.6032 2.40138 56.5285 1.56771ZM55.6079 7.98611C55.6079 11.0461 55.3056 12.8577 54.7031 13.3713C54.1006 13.8849 51.967 14.1496 48.3598 14.1496C44.7525 14.1496 42.6308 13.8869 42.0382 13.3713C41.4455 12.8557 41.1333 11.0462 41.1333 7.98618C41.1333 4.92618 41.4454 3.12618 42.046 2.61285C42.6465 2.09951 44.7781 1.83451 48.3676 1.83451C51.4948 1.83451 53.5039 2.05771 54.3514 2.49631C55.1988 2.93491 55.6079 3.98178 55.6079 5.59998V7.98611Z"
                fill="white"
            />
            <path d="M76.7439 0.473328H59.2152V2.13273H66.6747V15.5109H69.3515V2.13273H76.7439V0.473328Z" fill="white" />
            <path
                d="M86.1907 0.473328H82.5143L74.0789 15.5109H76.92L78.68 12.3501H90.2189L91.9969 15.5109H94.7902L86.2027 0.510861L86.1907 0.473328ZM79.474 10.985L84.4127 1.99253L89.4147 10.9811L79.474 10.985Z"
                fill="white"
            />
            <path
                d="M111.337 7.62666C113.212 7.16833 114.166 6.10753 114.166 4.46586C114.166 2.82419 113.727 1.74359 112.844 1.23593C111.961 0.728261 110.116 0.473328 107.313 0.473328H97.1333V15.5109H108.393C110.764 15.5109 112.447 15.1829 113.407 14.5409C114.367 13.8988 114.859 12.7629 114.859 11.1627C114.871 9.22286 113.682 8.03333 111.337 7.62666ZM99.8138 1.99053H107.261C109.089 1.99053 110.286 2.13866 110.817 2.42906C111.349 2.71946 111.608 3.35953 111.608 4.34333C111.608 5.48119 111.305 6.22206 110.703 6.54599C110.1 6.86993 108.708 7.04379 106.554 7.04379H99.8138V1.99053ZM111.424 13.4919C110.831 13.8199 109.533 13.9819 107.582 13.9819H99.8138V8.56106H106.418C109.126 8.56106 110.806 8.71319 111.412 9.01146C112.019 9.30973 112.309 10.1158 112.309 11.4295C112.313 12.4765 112.015 13.1719 111.424 13.4919Z"
                fill="white"
            />
            <path
                d="M9.40665 7.98618V9.50138H16.8189V10.3706V10.4259C16.8189 12.0972 16.487 13.1459 15.8608 13.5373C15.2345 13.9287 13.504 14.144 10.762 14.144C7.17051 14.144 4.94198 13.9286 4.12031 13.5019C3.29865 13.0752 2.90145 11.9352 2.90145 10.0843V7.95098L2.92711 5.95765C2.92711 4.13031 3.29851 2.97265 4.02745 2.51831C4.75638 2.06398 6.64111 1.82665 9.61818 1.82665C12.8106 1.82665 14.8394 1.97085 15.602 2.25731C16.3645 2.54378 16.6866 3.30225 16.6866 4.49938V4.89451H19.2982V4.28785C19.2982 2.62051 18.7194 1.52211 17.5756 1.04405C16.4317 0.565981 13.9188 0.323181 10.0409 0.323181C6.16298 0.323181 3.56131 0.692581 2.28118 1.42351C1.00105 2.15445 0.333313 3.65785 0.333313 5.87631V9.40258C0.333313 12.0438 0.92598 13.7666 2.08165 14.525C3.23731 15.2834 5.85878 15.6708 9.88491 15.6708C14.146 15.6708 16.8189 15.3468 17.8324 14.7048C18.8458 14.0628 19.3002 12.5674 19.3634 10.1612V7.98785L9.40665 7.98618Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_1216_2759">
                <rect width="120" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
));

LogoGeotabFooter.displayName = "LogoGeotabFooter";
