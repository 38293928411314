import { ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Button } from "@dev/zenith";
import { ButtonType } from "@dev/zenith/dist/button/buttonType";
import "../../components/ApiReference/reference.scss";
import { ApiMethodParameter } from "../../components/ApiReference/ApiMethod";
import { ApiObjectProperty } from "../../components/ApiReference/ApiObject";
import { CodeSample, CodeSamples } from "../../components/CodeSamplesContainer";
import { driveMethods, driveObjects } from "../../components/ApiReference/utils/driveReferenceEntries";
import BetaPill from "../../components/BetaPill/BetaPill";
import { PageTitleProps } from "../../components/PageTitle/PageTitle";
import { TableOfContentsItem } from "../../components/TableOfContents/TableOfContents";
import { HeaderSections } from "../../components/Header/headerSectionsEnum";
import { Page } from "../../components";

export interface DriveReferenceEntry {
    description?: JSX.Element;
    parameters?: ApiMethodParameter[];
    properties?: ApiObjectProperty[];
    returnType?: JSX.Element;
    codeSamples?: CodeSamples;
    isBeta?: boolean;
}

const driveLifeCycle: ReactNode = (
    <div className="paragraph">
        <p>
            The onStartup and onShutdown properties respectively enable the <strong>startup</strong> and <strong>shutdown</strong> lifecycle methods.
        </p>
        <h2>Startup</h2>
        <p>
            When the dashboard page is visible, the startup method is only called once. If the user navigates away from the page then navigates back, the startup method is not called again. If the
            Add-In requires re-initialization, the user must either log out and log in again, or refresh the application.
        </p>
        <CodeSample
            language="javascript"
            code={`startup: function (freshApi, freshState, initializeCallback) {
    // Code that needs to be executed on dashboard should go here
    initializeCallback();
}`}
        />
        <h2>Shutdown</h2>
        <p>
            Shutdown Add-Ins are executed when the final driver logs out of the Drive App. If there are co-drivers, and one of the co-drivers logs out (while other drivers remain logged in to the
            Drive App), the shutdown Add-In is not executed.
        </p>
        <p>
            Additionally, the Add-In is expected to return a promise since shutdown Add-Ins have a 15-second time limit to perform their function before the Add-Ins time out and the logout process
            is completed. The time limit prevents the application from freezing in the middle of the logout process as a result of faulty Add-Ins.
        </p>
        <CodeSample
            language="javascript"
            code={`shutdown: function (api, state, callback) {
    return new Promise(resolve => {
        // Do work, make any api calls etc
        resolve() // eventually need to call this somewhere so the promise resolves
    });
}`}
        />
    </div>
);

export default function ApiReference() {
    const navigate = useNavigate();
    const handleViewDetails = (linkPath: string) =>
        () => navigate(`./${linkPath}`);


    const methods: ReactNode = (
        <div className="reference-accordion">
            {Object.keys(driveMethods)
                .sort()
                .map((method, index) => (
                    <div key={index} className="paragraph method-object-container" id={method}>
                        <h3 className="methods__method-title">
                            {method + " (...)"}
                            {driveMethods[method].isBeta && <BetaPill />}
                            <Button type={ButtonType.Primary} className="methods__view-button" title={`View ${method}(...) details`} aria-label={`View ${method} details`} onClick={handleViewDetails(method)}>
                                View
                            </Button>
                        </h3>
                        {driveMethods[method].description}
                    </div>
                ))}
        </div>
    );

    const objects: ReactNode = (
        <div className="reference-accordion">
            {Object.keys(driveObjects).map((object) => (
                <div key={object} className="paragraph method-object-container" id={object}>
                    <h3 className="methods__method-title">
                        {object}
                        {driveObjects[object].isBeta && <BetaPill />}
                        <Button type={ButtonType.Primary} className="methods__view-button" title={`View ${object} details`} aria-label={`View ${object} details`} onClick={handleViewDetails(object)}>
                            View
                        </Button>
                    </h3>
                    {driveObjects[object].description}
                </div>
            ))}
        </div>
    );

    const pageTitle: PageTitleProps = {
        title: "API Reference",
        breadCrumbItems: ["Drive", "API Reference"]
    };

    const pageSections: TableOfContentsItem[] = [
        {
            elementId: "drive-add-in-lifecycle-methods",
            summary: "Geotab Drive page lifecycle methods",
            details: driveLifeCycle
        },
        {
            elementId: "methods",
            summary: "Methods",
            details: methods
        },
        {
            elementId: "objects",
            summary: "Objects",
            details: objects
        }
    ];

    return (
        <Page section={HeaderSections.Drive} pageTitle={pageTitle} tableOfContents={pageSections}>
            <div className="paragraph">
                <p>
                    Inside the Geotab Drive app, we provide the same <Link to="/myGeotab/apiReference/objects/API">API</Link> and
                    <HashLink to="/myGeotab/addIns/developingAddIns#geotab-add-in-js-action-button"> state</HashLink> properties for your initialize method that we do for our normal Add-Ins. In
                    addition to this, we provide you with properties and methods to allow access to mobile device sensors/actuators.
                </p>
            </div>
        </Page>
    );
}
