import { SideNavigationMenuItemType } from "@geotab/react-component-library";
import { IconCircleInfo, IconDocument, IconDocumentWithMagnifyingGlass, IconCodeBox } from "../icons";

export const HARDWARE_NAVBAR_ITEMS: SideNavigationMenuItemType[] = [
    {
        label: "Introduction",
        route: "/hardware/introduction",
        level: 0,
        order: 0,
        userTypes: [],
        roles: [],
        Icon: IconCircleInfo
    },
    {
        label: "Guides",
        level: 0,
        order: 1,
        userTypes: [],
        roles: [],
        Icon: IconDocument,
        children: [
            {
                label: "Design Guide",
                route: "/hardware/guides/designGuide",
                level: 1,
                order: 0,
                roles: [],
                userTypes: []
            },
            {
                label: "IO Expander Protocol",
                route: "/hardware/guides/IOExpanderProtocol",
                level: 1,
                order: 1,
                roles: [],
                userTypes: []
            },
            {
                label: "MIME Protocol",
                route: "/hardware/guides/mimeProtocol",
                level: 1,
                order: 2,
                roles: [],
                userTypes: []
            }
        ]
    },
    {
        label: "Protocol Reference",
        level: 0,
        order: 2,
        userTypes: [],
        roles: [],
        Icon: IconDocumentWithMagnifyingGlass,
        children: [
            {
                label: "RS232 & USB",
                route: "/hardware/protocolReference/rs232Usb",
                level: 1,
                order: 0,
                roles: [],
                userTypes: []
            },
            {
                label: "CAN",
                route: "/hardware/protocolReference/can",
                level: 1,
                order: 1,
                roles: [],
                userTypes: []
            },
            {
                label: "BLE",
                route: "/hardware/protocolReference/ble",
                level: 1,
                order: 2,
                roles: [],
                userTypes: []
            }
        ]
    },
    {
        label: "Code Samples",
        level: 0,
        order: 3,
        userTypes: [],
        roles: [],
        Icon: IconCodeBox,
        children: [
            {
                label: "Python",
                route: "/hardware/codeSamples/python",
                level: 1,
                order: 0,
                roles: [],
                userTypes: []
            },
            {
                label: "Android",
                externalRoute: "https://github.com/Geotab/android-external-device-example",
                level: 1,
                order: 1,
                roles: [],
                userTypes: []
            },
            {
                label: "Raspberry Pi",
                externalRoute: "https://github.com/Geotab/IOX-raspberryPi-demo",
                level: 1,
                order: 2,
                roles: [],
                userTypes: []
            }
        ]
    }
];
