import copyCode from "./CopyCode";
import { CodeBlock } from "react-code-blocks";
import "./codeSample.scss";
import { IconCopy, IconDarkModeMoon, IconLightModeSun } from "./icons";
import CustomDarkModeTheme from "./themes/CustomDarkModeTheme";
import CustomLightModeTheme from "./themes/CustomLightModeTheme";
import CodeSampleStyle from "./CodeSampleStyle";
import { Button } from "@dev/zenith";
import { ButtonType } from "@dev/zenith/dist/button/buttonType";
import { useToast } from "@geotab/react-component-library";
import "../../App.scss";
import { useContext, useCallback } from "react";
import CodeSampleLightDarkModeContext from "./CodeSampleLightDarkModeContext";

// Documentation for react-code-blocks: https://www.npmjs.com/package/react-code-blocks

let globalToastActive = false;
const toastDuration = 1000;

interface CodeSampleProps {
    language: string;
    code: string;
}

export default function CodeSample(props: CodeSampleProps): JSX.Element {
    const { lightMode, setLightMode } = useContext(CodeSampleLightDarkModeContext);
    const toast = useToast();

    const handleCopyClick = useCallback((): void => {
        copyCode(props.code);
        if (!globalToastActive) {
            globalToastActive = true;
            toast.info({
                sensitivity: "foreground",
                body: "Copied to clipboard",
                duration: toastDuration,
                icon: null
            });
            setTimeout(() => {
                globalToastActive = false;
            }, toastDuration);
        }
    }, [props.code, toast]);

    const handleLightDarkModeToggleButtonClick = (): void => {
        setLightMode(!lightMode);
    };

    return (
        <div className={lightMode ? "snippetBox" : "snippetBox snippetBox--dark-mode"} data-testid="snippet-box">
            <CodeBlock text={props.code} language={props.language} showLineNumbers={false} theme={lightMode ? CustomLightModeTheme() : CustomDarkModeTheme()} customStyle={CodeSampleStyle()} />

            <div className="snippetBox__toolsContainer">
                <Button
                    type={ButtonType.TertiaryBlack}
                    className={lightMode ? "" : "snippetBox__tool--dark-mode"}
                    onClick={handleLightDarkModeToggleButtonClick}
                    title="Toggle light/dark mode"
                    aria-label="Toggle light/dark mode"
                    data-testid="light-dark-mode-toggle"
                >
                    {lightMode ? <IconDarkModeMoon /> : <IconLightModeSun />}
                </Button>

                <Button
                    type={ButtonType.TertiaryBlack}
                    className={lightMode ? "" : "snippetBox__tool--dark-mode"}
                    onClick={handleCopyClick}
                    title="Copy code sample"
                    aria-label="Copy code sample"
                    data-testid="copy-button"
                >
                    <IconCopy />
                </Button>
            </div>
        </div>
    );
}
