/* eslint-disable max-len */
import * as React from "react";
import { LogoProps } from "@geotab/react-component-library";

export const IconMyAShort = React.forwardRef<SVGSVGElement, LogoProps>(() => (
    <svg width="24" height="9" viewBox="0 0 24 9" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="icon-mya-short-svg">
        <path d="M9.86219 0.156195H11.4622L13.083 4.22188L14.8718 0.156195H16.4763L12.5682 9.00631H10.9514L12.2341 6.15261L9.86219 0.156195Z" fill="#0078D3" />
        <path
            d="M0 0.166722H1.5656V0.911184C1.83333 0.605456 2.13118 0.377231 2.45799 0.226296C2.78541 0.0755034 3.14187 0 3.5286 0C3.91862 0 4.27074 0.0993616 4.58435 0.297728C4.89829 0.496237 5.15074 0.786178 5.34225 1.16727C5.59092 0.786178 5.89585 0.496237 6.2576 0.297728C6.61936 0.0993616 7.01427 0 7.44288 0C7.88636 0 8.27686 0.107148 8.6133 0.321586C8.95029 0.536024 9.19215 0.815894 9.33977 1.16141C9.48711 1.50678 9.56058 2.06859 9.56058 2.84677V6.6463H7.98335V3.35901C7.98335 2.62447 7.89544 2.1271 7.71955 1.86716C7.54373 1.607 7.27986 1.47707 6.92808 1.47707C6.66049 1.47707 6.42063 1.55643 6.20828 1.71515C5.99621 1.87401 5.83841 2.09331 5.73516 2.37325C5.63198 2.65319 5.58039 3.10278 5.58039 3.7221V6.64623H4.00316V3.50787C4.00316 2.9282 3.96216 2.50833 3.87941 2.24832C3.79714 1.98816 3.67339 1.79465 3.50899 1.66757C3.34411 1.54043 3.14497 1.47707 2.91102 1.47707C2.65018 1.47707 2.41348 1.55743 2.20093 1.71822C1.9881 1.87908 1.82906 2.10424 1.72389 2.39418C1.61823 2.68398 1.5656 3.14057 1.5656 3.76403V6.6463H0V0.166722Z"
            fill="#0078D3"
        />
        <path
            d="M20.3597 0.156128H18.8047L15.2356 6.76317H16.4367L17.1815 5.37295H22.0607L22.8128 6.76317H23.9999L20.3653 0.166336L20.3597 0.156128ZM21.7199 4.77445H17.5151L19.6035 0.82419L21.7199 4.77445Z"
            fill="#25477B"
        />
    </svg>
));

IconMyAShort.displayName = "IconMyAShort";
