import React, { createContext, ErrorInfo, ReactNode } from "react";
import UnexpectedErrorPage from "./UnexpectedErrorPage";

export const ErrorHandlerContext = createContext({
    resetError: () => {}
});

interface ErrorBoundaryProps {
    children?: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    resetError = () => {
        this.setState({ hasError: false });
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorHandlerContext.Provider value={{ resetError: this.resetError }}>
                    <UnexpectedErrorPage />
                </ErrorHandlerContext.Provider>
            );
        }

        return <ErrorHandlerContext.Provider value={{ resetError: this.resetError }}>{this.props.children}</ErrorHandlerContext.Provider>;
    }

    static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        return { hasError: true };
    }
}

export default ErrorBoundary;
