import { ReactNode } from "react";
import { Page } from "../../../components";
import { PageTitleProps } from "../../../components/PageTitle/PageTitle";
import { HeaderSections } from "../../../components/Header/headerSectionsEnum";
import { TableOfContentsItem } from "../../../components/TableOfContents/TableOfContents";
import CodeSample from "../../../components/CodeSamplesContainer/CodeSample";
import mapVehicleInfoImage from "./../../../assets/images/mapAddins/map-add-ins-docs-1.jpg";
import mapAddinSizeOnDesktopImage from "./../../../assets/images/mapAddins/map-add-ins-docs-2.jpg";
import mapAddinSizeOnMobileImage from "./../../../assets/images/mapAddins/map-add-ins-docs-3.jpg";
import multipleMapAddinsOnTheMapPageImage from "./../../../assets/images/mapAddins/map-add-ins-docs-4.jpg";
import { HashLink } from "react-router-hash-link";

const mapAddinConfigurationCodeSample = `{
    "name": "Tooltip",
    "supportEmail": "yourSupportEmail@company.com",
    "version": "1.0",
    "items": [{
        "page": "map",
        "title": "Tooltips",
        "noView": false,
        "mapScript": {
            "src": "addin.js",
            "style": "addin.css",
            "url": "addin.html"
        }
    }]
}`;

const installation: ReactNode = (
    <div className="paragraph">
        <p>
            Map Add-Ins are installed by uploading an <HashLink to="/myGeotab/addIns/developingAddIns#geotab-add-in-configuration">Add-In Configuration file</HashLink> into MyGeotab. Click on Administration {">"}{" "}
            System... {">"} System Settings {">"} Add-Ins {">"} New Add-In, and upload the Add-In Configuration file. The following is an example of a Map Add-In's Configuration file:
        </p>
        <CodeSample language="json" code={mapAddinConfigurationCodeSample} />
        <p>The main properties of the Configuration file are as follows:</p>
        <ol>
            <li>
                <strong>page</strong> - This defines which page the Map Add-In will reside within. This could be “<strong>map</strong>” (The Map page) or “<strong>tripsHistory</strong>” (the Trips
                History page). The default value is “<strong>map</strong>”.
            </li>
            <li>
                <strong>title</strong> - This is a heading displayed at the top of the panel when there are multiple Map Add-Ins installed. If a "title" is not provided, the Add-In defaults to the
                "name" parameter.
            </li>
            <li>
                <strong>noView</strong> - If <code>true</code>, the Add-In will not be displayed in the right-side panel. The default value is{" "}
                <code>false</code>.
            </li>
            <li>
                <strong>src</strong> - The JavaScript file reference for the Add-In. This can be an externally hosted file or uploaded into MyGeotab by dragging and dropping it into the
                Configuration file window.
            </li>
            <li>
                <strong>style</strong> - The CSS file reference for this Add-In. This can be externally hosted or uploaded to MyGeotab.
            </li>
            <li>
                <strong>url</strong> - The HTML file reference for this Add-In. This option can be used instead of src and style. Links to CSS and JavaScript files can be made within this HTML file.
                All content within the
                {"<"}body{">"} tags will be added to the Map Add-In UI {"("}
                {"("}
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/Geotab/sdk-map-addin-samples/tree/master/html" aria-label="Example">
                    Example
                </a>
                {")"}.
            </li>
        </ol>
        <p>
            You can find example Map Add-Ins{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/Geotab/sdk-map-addin-samples" aria-label="Example Map Add-Ins">
                here
            </a>
            .
        </p>
    </div>
);

const usage: ReactNode = (
    <div className="paragraph">
        <h2>Iframe setup</h2>
        <p>
            In the MyGeotab portal, Map Add-Ins are loaded when the user visits the MyGeotab page containing the Add-In. For example, the Add-In in Figure #1 loads when the user visits the Map page.
        </p>
        <p>
            Map Add-Ins are loaded inside an iframe with its sandbox attribute set to <code>allow-scripts</code>. This allows the Map Add-In to run custom scripts, such as the JavaScript file from
            the <code>src</code> parameter in the Add-In Configuration file.
        </p>
        <p>Important Notes:</p>
        <ul>
            <li>
                All Map Add-Ins installed in a database are active at the same time, when the user is on the Map or Trips History page. If different Add-Ins use the same variable name, it will cause
                an error and will lead to a non-working Add-In. You should name your variables according to your solution name - e.g. for "ABC Add-In" you should use "abc" as a prefix for all your
                variables.
            </li>
            <li>When the user leaves the page, the content of the iframe is cleared by the browser.</li>
            <li>When the user returns to the page, the scripts inside the iframe are reloaded.</li>
            <li>If necessary, the current Add-In state can be saved in Local Storage, IndexedDB, or elsewhere.</li>
        </ul>
        <p>
            Do not access elements or APIs outside the iframe panel; they can be changed without notice in future versions of MyGeotab, which might break your Add-In. Use only the services and
            methods documented here and on the API Reference page when interacting with a page outside the iframe.
        </p>
        <h2>Panel size</h2>
        <p>
            The iframe panel has a fixed width of 450 pixels; the width shrinks to accomodate screens with widths below 600 pixels. The panel height is responsive, always reaching the bottom of the
            page.
        </p>
        <p>The figures below display how the panel size changes between a desktop screen and a mobile phone screen.</p>
        <figure>
            <img className="mapAddins__image" src={mapAddinSizeOnDesktopImage} alt="Figure #2 - Map Add-In size on desktop." />
            <figcaption>Figure #2 - Map Add-In size on desktop.</figcaption>
        </figure>
        <figure>
            <img className="mapAddins__image" src={mapAddinSizeOnMobileImage} alt="Figure #3 - Map Add-In size on mobile." />
            <figcaption>Figure #3 - Map Add-In size on mobile.</figcaption>
        </figure>
        <p>
            If multiple Map Add-Ins are installed, each is accessible by selecting the appropriate tab at the top of the panel. In Figure #4, there are 3 Add-Ins installed on the page, denoted by
            the 3 tabs at the top of the panel. The Add-Ins are titled "Live Trip History", "Tooltip", and "Vehicle Info".
        </p>
        <figure>
            <img className="mapAddins__image" src={multipleMapAddinsOnTheMapPageImage} alt="Figure #4 - Multiple Add-Ins on the Map page." />
            <figcaption>Figure #4 - Multiple Add-Ins on the Map page.</figcaption>
        </figure>
        <h2>Structure</h2>
        <p>
            The starting point for the Map Add-n JavaScript code is the function that is added as a method to <code>window.geotab.addin</code>. For example:
        </p>
        <CodeSample
            language="javascript"
            code={`geotab.addin.request = (elt, service) => {
    // code for Map Add-In
}`}
        />
        <p>This function has two arguments:</p>
        <ol>
            <li>
                <strong>elt</strong> - The HTMLElement of the Add-In. It contains everything that belongs to the Add-In.
            </li>
            <li>
                <strong>
                    <HashLink to="#services-list">service</HashLink>{" "}
                </strong>
                - The JavaScript file reference for the Add-n.
            </li>
        </ol>
        <p>
            The Add-n function will be called when a user visits the Add-n by clicking on its tab. After that, the "focus" and "blur" events will be fired when the user opens or leaves the Add-In
            tab, respectively. Refer to the <HashLink to="#page-service">Page service section</HashLink> for more details.
        </p>
    </div>
);

const mapAddinServices: ReactNode = (
    <div id="services-list" className="paragraph">
        <div className="mapAddins__docs-service">
            <div className="mapAddins__docs-service__part">
                <h2 id="page-service">
                    Page service (
                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/Geotab/sdk-map-addin-samples/tree/master/page" aria-label="Example">
                        Example
                    </a>
                    )
                </h2>
                <p>Service for getting/setting page state and moving between MyGeotab pages.</p>
                <h3>Methods</h3>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Service methods</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    set {"("}key: string, value: string{")"}: Promise{"<"}boolean
                                    {">"};
                                </td>
                                <td>Sets new key/value pair to page state</td>
                            </tr>
                            <tr>
                                <td>
                                    get {"("}
                                    {")"}: Promise{"<"}object{">"};
                                </td>
                                <td>Gets page state</td>
                            </tr>
                            <tr>
                                <td>
                                    go {"("}page: string, state?: object{")"}: Promise{"<"}boolean
                                    {">"};
                                </td>
                                <td>Changes current page</td>
                            </tr>
                            <tr>
                                <td>
                                    hasAccessToPage {"("}page: string{")"}: Promise{"<"}boolean
                                    {">"};
                                </td>
                                <td>Checks whether current user has access to MyGeotab page</td>
                            </tr>
                            <tr>
                                <td>
                                    getFilterState(): Promise{"<"}
                                    <HashLink to="#IGroupFilterId">IGroupFilterId</HashLink>
                                    {"["}
                                    {"]"}
                                    {">"};
                                </td>
                                <td>Gets current company filter state</td>
                            </tr>
                            <tr>
                                <td>
                                    attach {"("}eventName: string, eventHandler: {"("}serviceData: any{")"} ={">"}; void{")"}: void
                                </td>
                                <td>Attaches event handler to service</td>
                            </tr>
                            <tr>
                                <td>
                                    detach {"("}eventName: string, eventHandler?:
                                    {"("}serviceData: any{")"} ={">"}; void{")"}: void
                                </td>
                                <td>Detaches event handler to service</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h3>Properties</h3>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Service methods</th>
                                <th>Type</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>active</td>
                                <td>boolean</td>
                                <td>Current map Add-In state. It's true if current Add-In is focused</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h3>Events</h3>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Event name</th>
                                <th>Event object</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>focus</td>
                                <td>Empty object</td>
                                <td>Event is fired when user focuses on current map Add-In</td>
                            </tr>
                            <tr>
                                <td>blur</td>
                                <td>Empty object</td>
                                <td>Event is fired when user focuses on another map Add-In</td>
                            </tr>
                            <tr>
                                <td>stateChange</td>
                                <td>state: (object) - Updated page state</td>
                                <td>Event is fired when the state of the page is changed</td>
                            </tr>
                            <tr>
                                <td>filterChange</td>
                                <td>
                                    groups: (<HashLink to="#IGroupFilterId">IGroupFilterId</HashLink>
                                    []) - Updated array of filter groups
                                </td>
                                <td>Event is fired when the company filter groups is changed</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="IGroupFilterId">Interface IGroupFilterId</h4>
                <p>Group id object that is selected by user in company filter</p>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>id</td>
                                <td>string</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="mapAddins__docs-service">
            <div className="mapAddins__docs-service__part">
                <h2>
                    LocalStorage service (
                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/Geotab/sdk-map-addin-samples/tree/master/localStorage" aria-label="Example">
                        Example
                    </a>
                    )
                </h2>
                <p>Service to request information stored in the browser's LocalStorage.</p>
                <h3>Methods</h3>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Service methods</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    set {"("}key: string, value: string{")"}: Promise{"<"}boolean
                                    {">"};
                                </td>
                                <td>Sets key-value pairs to browser localStorage with key</td>
                            </tr>
                            <tr>
                                <td>
                                    remove {"("}key: string{")"}: Promise{"<"}boolean{">"};
                                </td>
                                <td>Removes value from browser localStorage by key</td>
                            </tr>
                            <tr>
                                <td>
                                    get (key: string): Promise{"<"}string{">"};
                                </td>
                                <td>Gets value from browser localStorage by key</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="mapAddins__docs-service">
            <div className="mapAddins__docs-service__part">
                <h2>
                    Api service (
                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/Geotab/sdk-map-addin-samples/tree/master/request" aria-label="Example">
                        Example
                    </a>
                    )
                </h2>
                <p>Service for requesting data from the Geotab server.</p>
                <h3>Methods</h3>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Service methods</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    call (method: string, params: object): Promise{"<"}any[]{">"};
                                </td>
                                <td>Sends single request to Geotab server</td>
                            </tr>
                            <tr>
                                <td>
                                    multiCall (calls: <HashLink to="#TApiCall">TApiCall</HashLink>
                                    []): Promise
                                    {"<"}any[][]{">"};
                                </td>
                                <td>Sends multiple requests to Geotab server in one batch</td>
                            </tr>
                            <tr>
                                <td>
                                    getSession (): Promise{"<"}
                                    <HashLink to="#ISessionInfo">ISessionInfo</HashLink>
                                    {">"};
                                </td>
                                <td>Gets current user session information</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="ISessionInfo">Interface ISessionInfo</h4>
                <p>Current user session information</p>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>database</td>
                                <td>string</td>
                            </tr>
                            <tr>
                                <td>userName</td>
                                <td>string</td>
                            </tr>
                            <tr>
                                <td>sessionId</td>
                                <td>string</td>
                            </tr>
                            <tr>
                                <td>domain</td>
                                <td>string</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="TApiCall">Type TApiCall</h4>
                <p>
                    <code>[string, object]</code>
                </p>
                <p>Tuple for calling server methods where first element is method name and second is an object with parameters.</p>
            </div>
        </div>
        <div className="mapAddins__docs-service">
            <div className="mapAddins__docs-service__part">
                <h2>
                    Events service (
                    <a target="_blank" rel="noopener noreferrer" href="https://github.com/Geotab/sdk-map-addin-samples/tree/master/events" aria-label="Example">
                        Example
                    </a>
                    )
                </h2>
                <p>Service for catching events that happens when the user interacts with different entities on the map.</p>
                <h3>Methods</h3>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Service methods</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>attach (eventName: string, eventHandler: (serviceData: any) ={">"}; void): void</td>
                                <td>Attaches event handler to service</td>
                            </tr>
                            <tr>
                                <td>detach (eventName: string, eventHandler?: (serviceData: any) ={">"}; void): void</td>
                                <td>Detaches event handler to service</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h3>Events</h3>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Event name</th>
                                <th>Event object</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>move</td>
                                <td>
                                    data: (<HashLink to="#ICoordinate">ICoordinate</HashLink>) - Position of the pointer on the map
                                </td>
                                <td>Event is fired when user moves pointer over the map</td>
                            </tr>
                            <tr>
                                <td>over</td>
                                <td>
                                    data: (<HashLink to="#TEventData">TEventData</HashLink>) - Main information about entity
                                </td>
                                <td>Event is fired when user moves pointer over an object on the map</td>
                            </tr>
                            <tr>
                                <td>out</td>
                                <td>
                                    data: (<HashLink to="#TEventData">TEventData</HashLink>) - Main information about entity
                                </td>
                                <td>Event is fired when user moves pointer out of an object on the map</td>
                            </tr>
                            <tr>
                                <td>click</td>
                                <td>
                                    data: (<HashLink to="#TEventData">TEventData</HashLink>) - Main information about entity
                                </td>
                                <td>Event is fired when user clicks on an object on the map</td>
                            </tr>
                            <tr>
                                <td>change</td>
                                <td>
                                    data: (<HashLink to="#TEntityEventData">TEntityEventData</HashLink>) - Main information about entity and its state
                                </td>
                                <td>Event is fired when status of the entity on the map is changed</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="IZoneEvent">Interface IZoneEvent</h4>
                <p>Event object that is sent to the Add-In when the user interacts with a zone.</p>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>type</td>
                                <td>"zone"</td>
                            </tr>
                            <tr>
                                <td>entity</td>
                                <td>
                                    <HashLink to="#IZoneEventData">IZoneEventData</HashLink>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="IZoneEventData">Interface IZoneEventData</h4>
                <p>Zone object that is sent to the Add-In when the user interacts with a zone.</p>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>id</td>
                                <td>string</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="IDeviceEvent">Interface IDeviceEvent</h4>
                <p>Event object that is sent to the Add-In when the user interacts with a device.</p>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>type</td>
                                <td>"device"</td>
                            </tr>
                            <tr>
                                <td>entity</td>
                                <td>
                                    <HashLink to="#IDeviceEventData">IDeviceEventData</HashLink>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="IDeviceEventData">Interface IDeviceEventData</h4>
                <p>Device object that is sent to the Add-In when the user interacts with a device.</p>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>id</td>
                                <td>string</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="IRouteEvent">Interface IRouteEvent</h4>
                <p>Event object that is sent to the Add-In when the user interacts with a route.</p>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>type</td>
                                <td>"route"</td>
                            </tr>
                            <tr>
                                <td>entity</td>
                                <td>
                                    <HashLink to="#IRouteEventData">IRouteEventData</HashLink>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="IRouteEventData">Interface IRouteEventData</h4>
                <p>Route object that is sent to the Add-In when the user interacts with a route.</p>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>id</td>
                                <td>string</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="ITripEvent">Interface ITripEvent</h4>
                <p>Event object that is sent to the Add-In when the user interacts with a trip.</p>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>type</td>
                                <td>"trip"</td>
                            </tr>
                            <tr>
                                <td>entity</td>
                                <td>
                                    <HashLink to="#ITripEventData">ITripEventData</HashLink>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="ITripEventData">Interface ITripEventData</h4>
                <p>Trip object that is sent to the Add-In when the user interacts with a trip.</p>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Type</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>id</td>
                                <td>string</td>
                                <td>Id of the trip</td>
                            </tr>
                            <tr>
                                <td>device</td>
                                <td>
                                    {"{"} id: string; {"}"}
                                </td>
                                <td>Device id object that drove this trip</td>
                            </tr>
                            <tr>
                                <td>dateTime</td>
                                <td>string</td>
                                <td>Date and time of the trip point</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="IExceptionsEvent">Interface IExceptionsEvent</h4>
                <p>Event object that is sent to the Add-In when the user interacts with a device's exceptions.</p>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>type</td>
                                <td>"exceptions"</td>
                            </tr>
                            <tr>
                                <td>entity</td>
                                <td>
                                    <HashLink to="#IExceptionsEventData">IExceptionsEventData</HashLink>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="IExceptionsEventData">Interface IExceptionsEventData</h4>
                <p>Exceptions object that is sent to the Add-In when the user interacts with an exception icon on the map.</p>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>exceptions</td>
                                <td>
                                    <HashLink to="#IExceptionEventData">IExceptionEventData</HashLink>
                                    []
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="IExceptionEventData">Interface IExceptionEventData</h4>
                <p>Exception object that is sent to the Add-In when the user interacts with an exception icon on the map.</p>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Type</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>to</td>
                                <td>string</td>
                                <td>Date and time when this exception ends</td>
                            </tr>
                            <tr>
                                <td>from</td>
                                <td>string</td>
                                <td>Date and time when this exception starts</td>
                            </tr>
                            <tr>
                                <td>id</td>
                                <td>string</td>
                                <td>Id of the exception</td>
                            </tr>
                            <tr>
                                <td>rule</td>
                                <td>
                                    {"{"} id: string; {"}"}
                                </td>
                                <td>Rule id object of this exception</td>
                            </tr>
                            <tr>
                                <td>device</td>
                                <td>
                                    {"{"} id: string; {"}"}
                                </td>
                                <td>Device ID object where this exception happen</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="IDeviceChangeEvent">Interface IDeviceChangeEvent</h4>
                <p>Event object that is sent to the Add-In when the vehicle location on the map changes.</p>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>type</td>
                                <td>"device"</td>
                            </tr>
                            <tr>
                                <td>entity</td>
                                <td>
                                    <HashLink to="#IDeviceEventData">IDeviceEventData</HashLink>
                                </td>
                            </tr>
                            <tr>
                                <td>visible</td>
                                <td>boolean</td>
                            </tr>
                            <tr>
                                <td>location</td>
                                <td>
                                    <HashLink to="#ILocation">ILocation</HashLink>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="TEventData">Type TEventData</h4>
                <pre>
                    <HashLink to="#IZoneEvent">IZoneEvent</HashLink> | <HashLink to="#IDeviceEvent">IDeviceEvent</HashLink> | <HashLink to="#IRouteEvent">IRouteEvent</HashLink> |{" "}
                    <HashLink to="#ITripEvent">ITripEvent</HashLink> | <HashLink to="#IExceptionsEvent">IExceptionsEvent</HashLink>
                </pre>
                <p>Event object that is sent to the Add-In when the user interacts with different types of entities on the map.</p>
            </div>
            <div className="mapAddins__docs-service__part">
                <h4 id="TEntityEventData">Type TEntityEventData</h4>
                <pre>
                    <HashLink to="#IDeviceChangeEvent">IDeviceChangeEvent</HashLink>
                </pre>
                <p>Event object that is sent to the Add-In when something has changed with different types of entities on the map.</p>
            </div>
            <div className="mapAddins__docs-service">
                <div className="mapAddins__docs-service__part">
                    <h2>
                        Map service (
                        <a target="_blank" rel="noopener noreferrer" href="https://github.com/Geotab/sdk-map-addin-samples/tree/master/map" aria-label="Example">
                            Example
                        </a>
                        )
                    </h2>
                    <p>Service for manipulating the viewport of the map and getting updates about the changed map viewport.</p>
                    <h3>Methods</h3>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Service methods</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        setBounds (bounds: <HashLink to="#IMapBounds">IMapBounds</HashLink>
                                        ): Promise{"<"}boolean{">"}
                                    </td>
                                    <td>Sets map bounds</td>
                                </tr>
                                <tr>
                                    <td>
                                        getBounds (): Promise{"<"}
                                        <HashLink to="#IMapBounds">IMapBounds</HashLink>
                                        {">"}
                                    </td>
                                    <td>Gets current map bounds</td>
                                </tr>
                                <tr>
                                    <td>
                                        setZoom (zoom: number): Promise{"<"}boolean{">"}
                                    </td>
                                    <td>Sets map zoom level</td>
                                </tr>
                                <tr>
                                    <td>
                                        getZoom (): Promise{"<"}number{">"}
                                    </td>
                                    <td>Gets current map zoom level</td>
                                </tr>
                                <tr>
                                    <td>attach (eventName: string, eventHandler: (serviceData: any) ={">"} void): void</td>
                                    <td>Attaches event handler to service</td>
                                </tr>
                                <tr>
                                    <td>detach (eventName: string, eventHandler?: (serviceData: any) ={">"} void): void</td>
                                    <td>Detaches event handler to service</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h3>Events</h3>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Event name</th>
                                    <th>Event object</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>change</td>
                                    <td>Empty object</td>
                                    <td>Event is fired when viewport of map is changed. This event fires each time when use drags or zooms map</td>
                                </tr>
                                <tr>
                                    <td>changed</td>
                                    <td>
                                        <div>
                                            viewport: (<HashLink to="#IChangedViewport">IChangedViewport</HashLink>) - Current map zoom and bounds
                                        </div>
                                    </td>
                                    <td>Event is fired when user finished dragging and zooming map</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="IChangedViewport">Interface IChangedViewport</h4>
                    <p>Current map viewport</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>zoom</td>
                                    <td>number</td>
                                    <td>Current map zoom</td>
                                </tr>
                                <tr>
                                    <td>bounds</td>
                                    <td>
                                        <HashLink to="#IMapBounds">IMapBounds</HashLink>
                                    </td>
                                    <td>Current map bounds</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="IMapBounds">Interface IMapBounds</h4>
                    <p>Object that represents a map bounding box.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>sw</td>
                                    <td>
                                        <HashLink to="#IMapLatLng">IMapLatLng</HashLink>
                                    </td>
                                    <td>The southwest corner of the bounding box</td>
                                </tr>
                                <tr>
                                    <td>ne</td>
                                    <td>
                                        <HashLink to="#IMapLatLng">IMapLatLng</HashLink>
                                    </td>
                                    <td>The northeast corner of the bounding box</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="IMapLatLng">Interface IMapLatLng</h4>
                    <p>An object that represents longitude and latitude</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>lat</td>
                                    <td>number</td>
                                    <td>Latitude, measured in degrees</td>
                                </tr>
                                <tr>
                                    <td>lng</td>
                                    <td>number</td>
                                    <td>Longitude, measured in degrees</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="mapAddins__docs-service">
                <div className="mapAddins__docs-service__part">
                    <h2>
                        Tooltip service (
                        <a target="_blank" rel="noopener noreferrer" href="https://github.com/Geotab/sdk-map-addin-samples/tree/master/tooltip" aria-label="Example">
                            Example
                        </a>
                        )
                    </h2>
                    <p>Service for showing additional information in an entity's tooltip.</p>
                    <h3>Methods</h3>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Service methods</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        showAt (position: <HashLink to="#TPosition">TPosition</HashLink>, pattern: <HashLink to="#ITooltip">ITooltip</HashLink>, sequence: number): void
                                    </td>
                                    <td>Shows custom tooltip at certain position on the map</td>
                                </tr>
                                <tr>
                                    <td>
                                        show (pattern: <HashLink to="#ITooltip">ITooltip</HashLink>, sequence: number): void
                                    </td>
                                    <td>Adds additional information to already shown tooltip on the map</td>
                                </tr>
                                <tr>
                                    <td>hide (): void</td>
                                    <td>Hides additional information from already shown and custom tooltip</td>
                                </tr>
                                <tr>
                                    <td>
                                        setConfig (config: <HashLink to="#ITooltipConfig">ITooltipConfig</HashLink>
                                        ): Promise{"<"}boolean{">"}
                                    </td>
                                    <td>Sets configuration for changing current application tooltip</td>
                                </tr>
                                <tr>
                                    <td>
                                        getConfig (): Promise{"<"}
                                        <HashLink to="#ITooltipConfig">ITooltipConfig</HashLink>
                                        {">"}
                                    </td>
                                    <td>Sets configuration for current application tooltip</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="ITooltip">Interface ITooltip</h4>
                    <p>Custom map Add-In tooltip options. It can be either a text information or an image.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>icon</td>
                                    <td>string</td>
                                    <td>Icon image for custom tooltip part</td>
                                </tr>
                                <tr>
                                    <td>image</td>
                                    <td>
                                        <HashLink to="#ITooltipImage">ITooltipImage</HashLink>
                                    </td>
                                    <td>Image options that should be shown instead of text in tooltip</td>
                                </tr>
                                <tr>
                                    <td>main</td>
                                    <td>string</td>
                                    <td>Main tooltip text</td>
                                </tr>
                                <tr>
                                    <td>secondary</td>
                                    <td>string[]</td>
                                    <td>Secondary information that will be written with smaller font</td>
                                </tr>
                                <tr>
                                    <td>additional</td>
                                    <td>string[]</td>
                                    <td>Some additional tooltip information</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="ITooltipImage">Interface ITooltipImage</h4>
                    <p>Custom tooltip image options. It can be either link to an external image, base64 image, or image stored in an ArrayBuffer.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>url</td>
                                    <td>string</td>
                                    <td>Either link to external image or serialized base64 image or stringified SVG image</td>
                                </tr>
                                <tr>
                                    <td>buffer</td>
                                    <td>ArrayBuffer</td>
                                    <td>Image stored in ArrayBuffer</td>
                                </tr>
                                <tr>
                                    <td>width</td>
                                    <td>number</td>
                                    <td>Width of the image</td>
                                </tr>
                                <tr>
                                    <td>height</td>
                                    <td>number</td>
                                    <td>Height of the image</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="ITooltipConfig">Interface ITooltipConfig</h4>
                    <p>Application tooltip config. Based on this config, the application decides what parts of the tooltip should be rendered.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>device</td>
                                    <td>
                                        <HashLink to="#IDeviceTooltipConfig">IDeviceTooltipConfig</HashLink>
                                    </td>
                                    <td>Changes information in devices tooltip</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="IDeviceTooltipConfig">Interface IDeviceTooltipConfig</h4>
                    <p>Device tooltip config to control amount of information that is shown in the tooltip.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>state</td>
                                    <td>boolean</td>
                                    <td>Shows/hides current device state in tooltip</td>
                                </tr>
                                <tr>
                                    <td>groups</td>
                                    <td>boolean</td>
                                    <td>Shows/hides device groups information in tooltip</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="mapAddins__docs-service">
                <div className="mapAddins__docs-service__part">
                    <h2>
                        ActionList service (
                        <a target="_blank" rel="noopener noreferrer" href="https://github.com/Geotab/sdk-map-addin-samples/tree/master/action" aria-label="Example">
                            Example
                        </a>
                        )
                    </h2>
                    <p>Service for showing a custom action list instead of the existing one, or adding custom buttons to existing action lists.</p>
                    <h3>Methods</h3>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Service methods</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        show (position: <HashLink to="#TPosition">TPosition</HashLink>, title: string, items: <HashLink to="#IMenuActionItem">IMenuActionItem</HashLink>
                                        []): void
                                    </td>
                                    <td>Shows custom action menu on certain position</td>
                                </tr>
                                <tr>
                                    <td>
                                        attachMenu (menuName: <HashLink to="#TMenuType">TMenuType</HashLink>, handler: <HashLink to="#TMenuHandler">TMenuHandler</HashLink>
                                        ): void
                                    </td>
                                    <td>Subscribes on event when one of the MyGeotab map menus is shown to add new action button</td>
                                </tr>
                                <tr>
                                    <td>
                                        detachMenu (menuName: <HashLink to="#TMenuType">TMenuType</HashLink>, handler?: <HashLink to="#TMenuHandler">TMenuHandler</HashLink>
                                        ): void
                                    </td>
                                    <td>Unsubscribes on event when one of the MyGeotab map menus is shown</td>
                                </tr>
                                <tr>
                                    <td>attach (eventName: string, eventHandler: (serviceData: any) ={">"} void): void</td>
                                    <td>Attaches event handler to service</td>
                                </tr>
                                <tr>
                                    <td>detach (eventName: string, eventHandler?: (serviceData: any) ={">"} void): void</td>
                                    <td>Detaches event handler to service</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h3>Events</h3>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Event name</th>
                                    <th>Event object</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>CustomEvent</td>
                                    <td>
                                        <div>
                                            data: (object) - Data from <code>IAddinActionItem.data</code> for the current button
                                        </div>
                                    </td>
                                    <td>Event is fired when user clicks on custom button in action list. Event name is defined by "clickEvent" property in custom button object</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="IMenuActionItem">Interface IMenuActionItem</h4>
                    <p>Custom action button options</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>title</td>
                                    <td>string</td>
                                    <td>Button title</td>
                                </tr>
                                <tr>
                                    <td>icon</td>
                                    <td>string</td>
                                    <td>Button icon image</td>
                                </tr>
                                <tr>
                                    <td>url</td>
                                    <td>string</td>
                                    <td>URL to external page. If this property is used than button will be an anchor element</td>
                                </tr>
                                <tr>
                                    <td>clickEvent</td>
                                    <td>string</td>
                                    <td>Name of the event that will be fired when user clicks on this button</td>
                                </tr>
                                <tr>
                                    <td>zIndex</td>
                                    <td>number</td>
                                    <td>Number that defined position of the custom action button in menu</td>
                                </tr>
                                <tr>
                                    <td>data</td>
                                    <td>object</td>
                                    <td>Custom data that will be send with `clickEvent` when user clicks on this button</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="IMenuEventData">Interface IMenuEventData</h4>
                    <p>Data that is passed to the Add-In when all types of map action menus are about to be shown.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>x</td>
                                    <td>number</td>
                                </tr>
                                <tr>
                                    <td>y</td>
                                    <td>number</td>
                                </tr>
                                <tr>
                                    <td>menuName</td>
                                    <td>string</td>
                                </tr>
                                <tr>
                                    <td>location</td>
                                    <td>
                                        <HashLink to="#ILocation">ILocation</HashLink>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="IMapMenuEventData">
                        Interface IMapMenuEventData extends <HashLink to="#IMenuEventData">IMenuEventData</HashLink>
                    </h4>
                    <p>Data that is passed to the Add-In when a map action menu is about to be shown.</p>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="IZoneMenuEventData">
                        Interface IZoneMenuEventData extends <HashLink to="#IMenuEventData">IMenuEventData</HashLink>
                    </h4>
                    <p>Data that is passed to the Add-In when a zone action menu is about to be shown.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>zone</td>
                                    <td>
                                        {"{"} id: string; {"}"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="IRouteMenuEventData">
                        Interface IRouteMenuEventData extends <HashLink to="#IMenuEventData">IMenuEventData</HashLink>
                    </h4>
                    <p>Data that is passed to the Add-In when a route action menu is about to be shown.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>route</td>
                                    <td>
                                        {"{"} id: string; {"}"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="IMarkerMenuEventData">
                        Interface IMarkerMenuEventData extends <HashLink to="#IMenuEventData">IMenuEventData</HashLink>
                    </h4>
                    <p>Data that is passed to the Add-In when a location marker action menu is about to be shown.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>title</td>
                                    <td>string</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="IDeviceMenuEventData">
                        Interface IDeviceMenuEventData extends <HashLink to="#IMenuEventData">IMenuEventData</HashLink>
                    </h4>
                    <p>Data that is passed to the Add-In when a device action menu is about to be shown.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>device</td>
                                    <td>
                                        {"{"} id: string; {"}"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="ITripMenuEventData">
                        Interface ITripMenuEventData extends <HashLink to="#IMenuEventData">IMenuEventData</HashLink>
                    </h4>
                    <p>Data that is passed to the Add-In when a trip action menu is about to be shown.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>dateTime</td>
                                    <td>string</td>
                                </tr>
                                <tr>
                                    <td>device</td>
                                    <td>
                                        {"{"} id: string; {"}"}
                                    </td>
                                </tr>
                                <tr>
                                    <td>trip</td>
                                    <td>
                                        <HashLink to="#ITripData">ITripData</HashLink>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="ITripData">Interface ITripData</h4>
                    <p>Trip data that is passed to the Add-In when a trip action menu is about to be shown.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>start</td>
                                    <td>string</td>
                                </tr>
                                <tr>
                                    <td>stop</td>
                                    <td>string</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="TMenuType">Type TMenuType</h4>
                    <code>"zone" | "map" | "device" | "route" | "marker" | "trip"</code>
                    <p>Types of menus where custom action buttons can be added.</p>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="TMenuHandler">Type TMenuHandler</h4>
                    <pre>
                        (menuName: string, data: <HashLink to="#TMenuEventData">TMenuEventData</HashLink>) ={">"} <HashLink to="#IMenuActionItem">IMenuActionItem</HashLink>
                        [] | Promise{"<"}
                        <HashLink to="#IMenuActionItem">IMenuActionItem</HashLink>
                        []{">"}
                    </pre>
                    <p>Function that will be called when a certain action menu is about to be opened.</p>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="TMenuEventData">Type TMenuEventData</h4>
                    <pre>
                        <HashLink to="#IMapMenuEventData">IMapMenuEventData</HashLink> | <HashLink to="#IZoneMenuEventData">IZoneMenuEventData</HashLink> |{" "}
                        <HashLink to="#IRouteMenuEventData">IRouteMenuEventData</HashLink> | <HashLink to="#IMarkerMenuEventData">IMarkerMenuEventData</HashLink> |{" "}
                        <HashLink to="#IDeviceMenuEventData">IDeviceMenuEventData</HashLink> | <HashLink to="#ITripMenuEventData">ITripMenuEventData</HashLink>
                    </pre>
                    <p>Data that is passed to Add-In based on type of menu that is shown</p>
                </div>
            </div>
            <div className="mapAddins__docs-service">
                <div className="mapAddins__docs-service__part">
                    <h2>
                        Canvas service (
                        <a target="_blank" rel="noopener noreferrer" href="https://github.com/Geotab/sdk-map-addin-samples/tree/master/mapElements" aria-label="Example">
                            Example
                        </a>
                        )
                    </h2>
                    <p>Service for drawing custom shapes on the map.</p>
                    <h3>Methods</h3>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Service methods</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        path (path: <HashLink to="#IPathSeg">IPathSeg</HashLink>
                                        [], zIndex: number): <HashLink to="#ICanvasElement">ICanvasElement</HashLink>
                                        {"<"}
                                        <HashLink to="#ICanvasPathAttributes">ICanvasPathAttributes</HashLink>
                                        {">"}
                                    </td>
                                    <td>Draws SVG path element on the map</td>
                                </tr>
                                <tr>
                                    <td>
                                        rect (coords: <HashLink to="#TPosition">TPosition</HashLink>, width: number, height: number, radius: number, zIndex: number):{" "}
                                        <HashLink to="#ICanvasElement">ICanvasElement</HashLink>
                                        {"<"}
                                        <HashLink to="#ICanvasRectAttributes">ICanvasRectAttributes</HashLink>
                                        {">"}
                                    </td>
                                    <td>Draws SVG rect element on the map</td>
                                </tr>
                                <tr>
                                    <td>
                                        circle (coords: <HashLink to="#TPosition">TPosition</HashLink>, radius: number, zIndex: number):{" "}
                                        <HashLink to="#ICanvasElement">ICanvasElement</HashLink>
                                        {"<"}
                                        <HashLink to="#ICanvasCircleAttributes">ICanvasCircleAttributes</HashLink>
                                        {">"}
                                    </td>
                                    <td>Draws SVG circle element on the map</td>
                                </tr>
                                <tr>
                                    <td>
                                        text (coords: <HashLink to="#TPosition">TPosition</HashLink>, text: string, zIndex: number):{" "}
                                        <HashLink to="#ICanvasElement">ICanvasElement</HashLink>
                                        {"<"}
                                        <HashLink to="#ICanvasTextAttributes">ICanvasTextAttributes</HashLink>
                                        {">"}
                                    </td>
                                    <td>Draws SVG text element on the map</td>
                                </tr>
                                <tr>
                                    <td>
                                        marker (coords: <HashLink to="#TPosition">TPosition</HashLink>, width: number, height: number, source:{" "}
                                        <HashLink to="#TMarkerSource">TMarkerSource</HashLink>, zIndex: number):{" "}
                                        <HashLink to="#ICanvasElement">ICanvasElement</HashLink>
                                        {"<"}
                                        <HashLink to="#ICanvasMarkerAttributes">ICanvasMarkerAttributes</HashLink>
                                        {">"}
                                    </td>
                                    <td>Draws custom image element on the map</td>
                                </tr>
                                <tr>
                                    <td>clear (): void</td>
                                    <td>Clears all drawn elements on the map</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="IPathSeg">Interface IPathSeg</h4>
                    <p>Segment of the path element that will be added in the <code>d</code> attribute.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>type</td>
                                    <td>string</td>
                                    <td>Type of the segment. Supported types `M`, `m`, `L`, `l`, `Z`, `C`, `c`, `S`, `s`</td>
                                </tr>
                                <tr>
                                    <td>points</td>
                                    <td>
                                        <HashLink to="#TPathSegPoint">TPathSegPoint</HashLink>
                                        []
                                    </td>
                                    <td>Locations or coordinates that should be used in current segment</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="ICanvasElement">
                        Interface ICanvasElement{"<"}T extends <HashLink to="#TPathSegPoint">TCanvasElementAttributes</HashLink>
                        {">"}
                    </h4>
                    <p>New map element object.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Method</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        change (attrs: T): <HashLink to="#ICanvasElement">ICanvasElement</HashLink>
                                        {"<"}T{">"}
                                    </td>
                                    <td>Changes style attributes of the current map element</td>
                                </tr>
                                <tr>
                                    <td>remove (): void</td>
                                    <td>Removes current map element</td>
                                </tr>
                                <tr>
                                    <td>isRemoved (): boolean</td>
                                    <td>Returns true if element was removed</td>
                                </tr>
                                <tr>
                                    <td>
                                        attach (event: <HashLink to="#TCanvasElementEvent">TCanvasElementEvent</HashLink>, handler: (data:{" "}
                                        <HashLink to="#ICoordinate">ICoordinate</HashLink>) ={">"}
                                        void): <HashLink to="#ICanvasElement">ICanvasElement</HashLink>
                                        {"<"}T{">"}
                                    </td>
                                    <td>Attaches event handler to current element event</td>
                                </tr>
                                <tr>
                                    <td>
                                        detach (event: <HashLink to="#TCanvasElementEvent">TCanvasElementEvent</HashLink>, handler?: (data:{" "}
                                        <HashLink to="#ICoordinate">ICoordinate</HashLink>) ={">"}
                                        void): <HashLink to="#ICanvasElement">ICanvasElement</HashLink>
                                        {"<"}T{">"}
                                    </td>
                                    <td>Detaches event handler from current element event</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="ICanvasElementStyleAttributes">Interface ICanvasElementStyleAttributes</h4>
                    <p>Style properties that can be changed for every custom element.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>fill</td>
                                    <td>string</td>
                                    <td>Background color of the element</td>
                                </tr>
                                <tr>
                                    <td>stroke</td>
                                    <td>string</td>
                                    <td>Border color of the element</td>
                                </tr>
                                <tr>
                                    <td>stroke-width</td>
                                    <td>number</td>
                                    <td>Border width of the element</td>
                                </tr>
                                <tr>
                                    <td>fill-opacity</td>
                                    <td>number</td>
                                    <td>Opacity of the element</td>
                                </tr>
                                <tr>
                                    <td>font-size</td>
                                    <td>number</td>
                                    <td>Text element font size</td>
                                </tr>
                                <tr>
                                    <td>font-weight</td>
                                    <td>number</td>
                                    <td>Text element font weight</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="ICanvasRectAttributes">
                        Interface ICanvasRectAttributes extends <HashLink to="#ICanvasElementStyleAttributes">ICanvasElementStyleAttributes</HashLink>
                    </h4>
                    <p>Attribute of rect that can be changed for every custom element.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>height</td>
                                    <td>number</td>
                                    <td>Height in pixels of the element</td>
                                </tr>
                                <tr>
                                    <td>width</td>
                                    <td>number</td>
                                    <td>Width in pixels of the element</td>
                                </tr>
                                <tr>
                                    <td>rx</td>
                                    <td>number</td>
                                    <td>Radius in pixels x-axios of the element</td>
                                </tr>
                                <tr>
                                    <td>ry</td>
                                    <td>number</td>
                                    <td>Radius in pixels y-axios of the element</td>
                                </tr>
                                <tr>
                                    <td>coords</td>
                                    <td>
                                        <HashLink to="#TPosition">TPosition</HashLink>
                                    </td>
                                    <td>Position of the element</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="ICanvasTextAttributes">
                        Interface ICanvasTextAttributes extends <HashLink to="#ICanvasElementStyleAttributes">ICanvasElementStyleAttributes</HashLink>
                    </h4>
                    <p>Text element attributes that can be changed for every custom text element.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>dx</td>
                                    <td>number</td>
                                    <td>Offset in pixels x-axios of the element</td>
                                </tr>
                                <tr>
                                    <td>dy</td>
                                    <td>number</td>
                                    <td>Offset in pixels y-axios of the element</td>
                                </tr>
                                <tr>
                                    <td>text</td>
                                    <td>string</td>
                                    <td>Text of the element</td>
                                </tr>
                                <tr>
                                    <td>coords</td>
                                    <td>
                                        <HashLink to="#TPosition">TPosition</HashLink>
                                    </td>
                                    <td>Position of the element</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="ICanvasCircleAttributes">
                        Interface ICanvasCircleAttributes extends <HashLink to="#ICanvasElementStyleAttributes">ICanvasElementStyleAttributes</HashLink>
                    </h4>
                    <p>Attribute of circle that can be changed for every custom element.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>r</td>
                                    <td>number</td>
                                    <td>Radius in pixels of the element</td>
                                </tr>
                                <tr>
                                    <td>coords</td>
                                    <td>
                                        <HashLink to="#TPosition">TPosition</HashLink>
                                    </td>
                                    <td>Position of the element</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="ICanvasPathAttributes">
                        Interface ICanvasPathAttributes extends <HashLink to="#ICanvasElementStyleAttributes">ICanvasElementStyleAttributes</HashLink>
                    </h4>
                    <p>Attribute of path that can be changed for every custom element.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>path</td>
                                    <td>
                                        <HashLink to="#IPathSeg">IPathSeg</HashLink>
                                        []
                                    </td>
                                    <td>path of the element</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="ICanvasMarkerAttributes">
                        Interface ICanvasMarkerAttributes extends <HashLink to="#ICanvasElementStyleAttributes">ICanvasElementStyleAttributes</HashLink>
                    </h4>
                    <p>Attribute of marker that can be changed for every custom element.</p>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>height</td>
                                    <td>number</td>
                                    <td>Height in pixels of the element</td>
                                </tr>
                                <tr>
                                    <td>width</td>
                                    <td>number</td>
                                    <td>Width in pixels of the element</td>
                                </tr>
                                <tr>
                                    <td>x</td>
                                    <td>number</td>
                                    <td>Position of the element</td>
                                </tr>
                                <tr>
                                    <td>y</td>
                                    <td>number</td>
                                    <td>Position of the element</td>
                                </tr>
                                <tr>
                                    <td>dx</td>
                                    <td>number</td>
                                    <td>Offset in pixels x-axios of the element</td>
                                </tr>
                                <tr>
                                    <td>dy</td>
                                    <td>number</td>
                                    <td>Offset in pixels y-axios of the element</td>
                                </tr>
                                <tr>
                                    <td>coords</td>
                                    <td>
                                        <HashLink to="#TPosition">TPosition</HashLink>
                                    </td>
                                    <td>Position of the element</td>
                                </tr>
                                <tr>
                                    <td>href</td>
                                    <td>string</td>
                                    <td>Image href of the element</td>
                                </tr>
                                <tr>
                                    <td>buffer</td>
                                    <td>ArrayBuffer</td>
                                    <td>Image ArrayBuffer of the element</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="TPathSegPoint">Type TPathSegPoint</h4>
                    <pre>
                        <HashLink to="#ILocation">ILocation</HashLink> | <HashLink to="#ICoordinate">ICoordinate</HashLink> | number
                    </pre>
                    <p>Location or coordinate of the next point.</p>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="TPathSegPoint">Type TCanvasElementAttributes</h4>
                    <pre>
                        <HashLink to="#ICanvasRectAttributes">ICanvasRectAttributes</HashLink> |{" "}
                        <HashLink to="#ICanvasTextAttributes">ICanvasTextAttributes</HashLink> |{" "}
                        <HashLink to="#ICanvasCircleAttributes">ICanvasCircleAttributes</HashLink> |{" "}
                        <HashLink to="#ICanvasPathAttributes">ICanvasPathAttributes</HashLink> |{" "}
                        <HashLink to="#ICanvasMarkerAttributes">ICanvasMarkerAttributes</HashLink>
                    </pre>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="TCanvasElementEvent">Type TCanvasElementEvent</h4>
                    <code>"click" | "over" | "out"</code>
                    <p>Supported custom map element event types.</p>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="TMarkerSource">Type TMarkerSource</h4>
                    <pre>string | ArrayBuffer</pre>
                    <p>Marker can be presented as an encoded SVG, base64 string, URL to a third-party resource, or binary array in ArrayBuffer.</p>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="ILocation">Interface ILocation</h4>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>lat</td>
                                    <td>number</td>
                                </tr>
                                <tr>
                                    <td>lng</td>
                                    <td>number</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="ICoordinate">Interface ICoordinate</h4>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>x</td>
                                    <td>number</td>
                                </tr>
                                <tr>
                                    <td>y</td>
                                    <td>number</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mapAddins__docs-service__part">
                    <h4 id="TPosition">Type TPosition</h4>
                    <pre>
                        <HashLink to="#ILocation">ILocation</HashLink> | <HashLink to="#ICoordinate">ICoordinate</HashLink>
                    </pre>
                </div>
            </div>
        </div>
    </div>
);

const exampleAddins: ReactNode = (
    <div className="paragraph">
        <p>
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/Geotab/sdk-map-addin-samples" aria-label="Example">
                Here are some examples
            </a>{" "}
            and{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/Geotab/map-addins-definitions/" aria-label="Definition type files for Map Add-Ins">
                type definition files
            </a>{" "}
            that can help you understand how to work with Map Add-Ins. Download them, unzip the files, then follow the instructions in the ReadMe document.
        </p>
    </div>
);

const pageTitle: PageTitleProps = {
    title: "Geotab Map Add-In Overview",
    breadCrumbItems: ["MYG", "Add-Ins", "Map Add-Ins"]
};

const pageSections: TableOfContentsItem[] = [
    {
        elementId: "installation",
        summary: "Installation",
        details: installation
    },
    {
        elementId: "usage",
        summary: "Usage",
        details: usage
    },
    {
        elementId: "map-addin-services",
        summary: "Map Add-In services",
        details: mapAddinServices
    },
    {
        elementId: "example-addins",
        summary: "Example Add-Ins",
        details: exampleAddins
    }
];

export default function MapAddins() {
    return (
        <Page section={HeaderSections.MyGeotab} pageTitle={pageTitle} tableOfContents={pageSections}>
            <div className="paragraph">
                <p>
                    Map Add-Ins are integrations embedded within the Map or Trips History pages of the MyGeotab platform. Once installed, a panel will appear on the right-side of the Map or Trips
                    History page, containing the UI of the Map Add-In. These Add-Ins can read from and operate on the map, as well as access the MyGeotab APIs. This document outlines the
                    installation process and APIs available for Map Add-In development.
                </p>
                <p>
                    Below is an example of a Map Add-In installed within the Map page of MyGeotab. When a user clicks a vehicle on the map, this integration displays the relevant data for that
                    vehicle.
                </p>
                <figure>
                    <img className="mapAddins__image" src={mapVehicleInfoImage} alt='Figure #1 - The "Vehicle Info" Map Add-In.' />
                    <figcaption>
                        Figure #1 - The {'"'}Vehicle Info{'"'} Map Add-In.
                    </figcaption>
                </figure>
            </div>
        </Page>
    );
}
