import { ReactNode } from "react";
import { Page } from "../../components";
import { HeaderSections } from "../../components/Header/headerSectionsEnum";
import { PageTitleProps } from "../../components/PageTitle/PageTitle";
import { TableOfContentsItem } from "../../components/TableOfContents/TableOfContents";

const android: ReactNode = (
    <div className="paragraph" id="android">
        <a href="https://github.com/Geotab/mobile-sdk-android" target="_blank" rel="noopener noreferrer" aria-label="Geotab mobile SDK Android">
            Gradle dependency
        </a>
    </div>
);

const iOS: ReactNode = (
    <div className="paragraph" id="ios">
        <a href="https://github.com/Geotab/mobile-sdk-ios" target="_blank" rel="noopener noreferrer" aria-label="Geotab mobile SDK iOS">
            Swift package
        </a>
    </div>
);

const pageTitle: PageTitleProps = {
    title: "Mobile SDK",
    breadCrumbItems: ["Drive", "Mobile SDK"]
};

const pageSections: TableOfContentsItem[] = [
    {
        elementId: "android",
        summary: "Android",
        details: android
    },
    {
        elementId: "ios",
        summary: "iOS",
        details: iOS
    }
];

export default function MobileSdk() {
    return <Page section={HeaderSections.Drive} pageTitle={pageTitle} tableOfContents={pageSections} >
        <div className="paragraph">The Geotab Mobile SDK provides anyone with the ability to develop native interactions with the Mobile OS such that operating system permissions, notifications, location,
            camera use among other OS features may be leveraged within the Drive Add-In.</div>
    </Page>;
}