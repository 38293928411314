import { ReactNode } from "react";
import { Link } from "react-router-dom";
import renderStringWithUrl from "./utils/renderStringWithUrl";
import getDataTypeData from "./utils/dataTypeUtils";
import { Page } from "..";
import { PageTitleProps } from "../PageTitle/PageTitle";
import { HeaderSections } from "../Header/headerSectionsEnum";
import { TableOfContentsItem } from "../TableOfContents/TableOfContents";
import "./reference.scss";
import BetaPill from "../BetaPill/BetaPill";
import { Pagination, RateLimit } from "./../ApiReference/utils/apiReferenceUtils";

export interface ApiObjectProperty {
    name: string;
    description: string;
    dataType: string;
    isSupported?: boolean; // myg and mya
    isBeta?: boolean; // myg
}

export interface ApiObjectData {
    description: string;
    properties: ApiObjectProperty[];
    isSupported?: boolean; // myg and mya
    hasValues?: boolean; // myg and mya
    isBeta?: boolean; // myg
    baseType?: string; // myg
    rateLimits?: RateLimit[];
    pagination?: Pagination;
}

export interface ApiObjectProps {
    introduction?: JSX.Element;
    properties: ApiObjectProperty[];
    title: PageTitleProps;
    headerSection: HeaderSections;
    rateLimits?: RateLimit[];
    pagination?: Pagination;
    hasValues?: boolean;
}

export default function ApiObject({ introduction, properties, title, headerSection, hasValues, rateLimits, pagination }: ApiObjectProps): JSX.Element {
    const introductionParagraph: ReactNode = <div className="paragraph">{introduction}</div>;
    let propertyElements = properties.map((property: ApiObjectProperty, index: number) => {
        let dataTypeInformation = getDataTypeData(property.dataType);
        return (
            <div className="object-property-container" key={`param-${index}`}>
                <div className="object-property-header-container">
                    <div>
                        <h2>{property.name}</h2>
                    </div>
                </div>
                <div className="object-property-description-container">
                    <div className="object-property-type-labels">
                        <span>{dataTypeInformation}</span>
                        {property.isBeta && <BetaPill />}
                    </div>
                    {renderStringWithUrl(property.name, property.description, headerSection)}
                </div>
            </div>
        );
    });

    const propertyParagraphs: ReactNode = <div className="paragraph">{propertyElements}</div>;

    let pageSections: TableOfContentsItem[] = [];

    if (introduction) {
        pageSections.push(
            {
                elementId: "introduction",
                summary: "Introduction",
                details: introductionParagraph
            }
        );
    }

    if (properties.length > 0) {
        pageSections.push({
            elementId: hasValues ? "values" : "properties",
            summary: hasValues ? "Values" : "Properties",
            details: propertyParagraphs
        });
    }

    if (rateLimits && rateLimits.length > 0) {
        const rateLimitTable: ReactNode = (
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Method</th>
                            <th>Description</th>
                            <th>Limit</th>
                            <th>Period</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rateLimits.map((rateLimit: RateLimit, index: number) => (
                            <tr key={`rateLimit-${index}`}>
                                <td>{rateLimit.name}</td>
                                <td>{rateLimit.description}</td>
                                <td>{rateLimit.limit}</td>
                                <td>{rateLimit.period}</td>
                                <td>{rateLimit.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
        const rateLimitParagraph: ReactNode = <div className="paragraph">{rateLimitTable}</div>;
        pageSections.push({
            elementId: "rate-limits",
            summary: "Rate limits",
            details: rateLimitParagraph
        });
    }

    if (pagination) {
        const paginationParagraph: ReactNode = (
            <div className="paragraph">
                <div className="object-pagination-container">
                    <div className="object-pagination-header-container">
                        <h2>Results limit</h2>
                    </div>
                    <div className="object-pagination-description-container">{pagination.resultsLimit}</div>
                </div>
                <div className="object-pagination-container">
                    <div className="object-pagination-header-container">
                        <h2>Supported sort</h2>
                    </div>
                    <div className="object-pagination-description-container">
                        {pagination.supportedSorts.map((sort: string, index: number) => (
                            <p key={`pagination-${index}`}>
                                <Link to={`/myGeotab/apiReference/objects/SortBy${sort}`}>SortBy{sort}</Link>
                                {sort === "Date" && pagination.dateSortProperty ? " sorts by the " + pagination.dateSortProperty + " property." : ""}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        );
        pageSections.push({
            elementId: "pagination-details",
            summary: (
                <span>
                    Pagination
                    {pagination.isBeta && <BetaPill />}
                </span>
            ),
            details: paginationParagraph
        });
    }

    return <Page section={headerSection} pageTitle={title} tableOfContents={pageSections} />;
}
