export default function CustomDarkModeTheme(): Record<string, string> {
    return {
        lineNumberColor: "#f1f3f4",
        lineNumberBgColor: "#f1f3f4",
        backgroundColor: "#283142",
        textColor: "#f1f3f4",
        substringColor: "#9ccc65",
        keywordColor: "#78d9ec",
        attributeColor: "#d7aefb",
        selectorTagColor: "#f1f3f4",
        docTagColor: "#f1f3f4",
        nameColor: "#f1f3f4",
        builtInColor: "#f1f3f4",
        literalColor: "#f1f3f4",
        bulletColor: "#f1f3f4",
        codeColor: "#f1f3f4",
        additionColor: "#f1f3f4",
        regexpColor: "#f1f3f4",
        symbolColor: "#f1f3f4",
        variableColor: "#ff8bcb",
        templateVariableColor: "#f1f3f4",
        linkColor: "#f1f3f4",
        selectorAttributeColor: "#78d9ec",
        selectorPseudoColor: "#f1f3f4",
        typeColor: "#78d9ec",
        stringColor: "#9ccc65",
        selectorIdColor: "#f1f3f4",
        quoteColor: "#f1f3f4",
        templateTagColor: "#78d9ec",
        deletionColor: "#f1f3f4",
        titleColor: "#f1f3f4",
        sectionColor: "#d7aefb",
        commentColor: "#ff8bcb",
        metaKeywordColor: "#78d9ec",
        metaColor: "#f1f3f4",
        functionColor: "#f1f3f4",
        numberColor: "#fbbc04"
    };
}
