import { MiniSearchPage } from "../../../utils/indexInterfaces";
import { ReferenceEntry } from "../../ApiReference/ApiReference";
import { ReferenceSections } from "../../ApiReference/referenceSectionsEnum";
import { getReferenceEntries } from "../../ApiReference/utils/apiReferenceUtils";
import { MyAParserOutput } from "../../ApiReference/utils/myAParser";
import { MyGParserOutput } from "../../ApiReference/utils/myGParser";
import { miniSearch } from "../SearchModal";
import { SearchOptions, SearchResult } from "minisearch";
import { addParsedResultsToSearchIndex } from "./searchUtils";

export const searchAndFilter = (query: string): SearchResult[] => {
    const strictSearch: SearchOptions = {
        fields: ["title", "content", "headers"],
        boost: {
            title: 3,
            headers: 2
        },
        fuzzy: 0,
        prefix: false
    };
    const looseyGoosey: SearchOptions = {
        fields: ["title", "content", "headers"], // fields to index for full-text search
        fuzzy: 0.2,
        prefix: true,
        boost: {
            title: 3,
            headers: 2
        }
    };
    let results: SearchResult[] = [];

    if (query.indexOf(" ") >= 0) {
        //there's no exact match in the minisearch library but this is as close as we can get
        let filteredResults: SearchResult[] = [];
        results = miniSearch.search(query, strictSearch);
        let queryTerms: string[] = query.split(" ");
        results.forEach((page: SearchResult) => {
            let isSubset = !queryTerms.some((string) => page.terms.indexOf(string) === -1);
            if (isSubset) {
                filteredResults.push(page);
            }
        });
        return filteredResults;
    } else {
        results = miniSearch.search(query, looseyGoosey);
    }
    return results;
};

export const setStorageAndSearch = (json: MyGParserOutput | MyAParserOutput, section: ReferenceSections, index: MiniSearchPage[]): ReferenceEntry[] => {
    const parsedEntries = getReferenceEntries(json);
    parsedEntries.forEach((entry) => {
        sessionStorage.setItem(entry.name + section, JSON.stringify(entry.details));
    });
    addParsedResultsToSearchIndex(json, section, index, "minisearch");
    miniSearch.removeAll();
    miniSearch.addAll(index);

    return parsedEntries;
};