import renderStringWithUrl from "./utils/renderStringWithUrl";
import { useParams, Navigate } from "react-router-dom";
import { HeaderSections } from "../Header/headerSectionsEnum";
import { ReferenceSections } from "./referenceSectionsEnum";
import ApiObject, { ApiObjectData, ApiObjectProps } from "./ApiObject";
import { useEffect, useState } from "react";
import { referenceDataPromise } from "./ApiReference";
import { Waiting } from "@dev/zenith";
import "./reference.scss";

interface DynamicApiObjectProps {
    headerSection: HeaderSections;
    referenceSection: ReferenceSections;
}

export default function DynamicApiObject({ headerSection, referenceSection }: DynamicApiObjectProps): JSX.Element {
    let pageInfo: ApiObjectProps = {} as ApiObjectProps;
    pageInfo.headerSection = headerSection;
    const { objectId = "" } = useParams();
    const objectDataString: string | null = sessionStorage.getItem(objectId + referenceSection);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        referenceDataPromise
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("An error occurred while processing the objects:", error);
                setIsLoading(false);
            });
    }, [isLoading]);

    if (isLoading) {
        return <Waiting isLoading />; //TODO: Check Loading Spinner
    }

    if (!objectDataString) {
        return <Navigate to="/not-found" replace />;
    }

    // If the above check passes, assume that the data is present
    const storedObjectData: ApiObjectData = JSON.parse(objectDataString);

    const objectDescription: string = storedObjectData.description;
    pageInfo.properties = storedObjectData.properties;
    const displayBetaTag: boolean | undefined = storedObjectData.isBeta;
    pageInfo.introduction = renderStringWithUrl(objectId, objectDescription, headerSection);

    pageInfo.title = {
        title: objectId,
        breadCrumbItems: [referenceSection, "API Reference", "Objects", objectId],
        isBeta: displayBetaTag
    };

    pageInfo.hasValues = storedObjectData.hasValues;
    pageInfo.rateLimits = storedObjectData.rateLimits;
    pageInfo.pagination = storedObjectData.pagination;

    return <ApiObject {...pageInfo} />;
}
