import { ReactNode, useContext } from "react";
import MenuContext from "../../menuContext";
import { Page } from "../../components";
import { PageTitleProps } from "../../components/PageTitle/PageTitle";
import { HeaderSections } from "../../components/Header/headerSectionsEnum";
import { TableOfContentsItem } from "../../components/TableOfContents/TableOfContents";

const overview: ReactNode = (
    <div className="paragraph">
        <p>Toolkits provide helpful integration resources and context which is in addition to the existing documentation and make it easier for partners to get up and running.</p>
        <p>They are accesible via <a href="https://docs.google.com/presentation/d/1fqtMPgsdwF3CQuvhqhC8SBwdI8PZDjUtpVGEzsdDYjo/" target="_blank" rel="noopener noreferrer">Geotab Integrations: Software Technical Toolkit</a> or <a href="https://docs.google.com/presentation/d/1SH-VX9GeVImHUyaqJgCzZ28fMa0-sVqh4ue-sNRTO9M/" target="_blank" rel="noopener noreferrer">Geotab Integrations: Hardware Technical Toolkit</a>.</p>
    </div>
);

const pageTitle: PageTitleProps = {
    title: "Toolkits",
    breadCrumbItems: ["Help & Support", "Toolkits"]
};

const pageSections: TableOfContentsItem[] = [
    {
        elementId: "overview",
        summary: "Overview",
        details: overview
    }
];

export default function Toolkits(): JSX.Element {
    const { activeSiteSection } = useContext(MenuContext);
    return (
        <Page section={activeSiteSection !== HeaderSections.Landing ? activeSiteSection : HeaderSections.MyGeotab} pageTitle={pageTitle} tableOfContents={pageSections} />
    );
}