import { RateLimit } from "./apiReferenceUtils";
import { ReferenceEntry } from "../ApiReference";
import { AllRateLimits } from "../../../pages/myGeotab/guides/rateLimits";
import { ApiMethodData } from "../ApiMethod";


export const WHITESPACE_REGEX = /\s+/g;
export const SPECIAL_CHAR_REGEX = /[^a-zA-Z0-9]/g;


export function pullRateLimitInfo(methodInfo: ReferenceEntry[], objectInfo: ReferenceEntry[]): AllRateLimits {
    let standardRateLimits: { [key: string]: RateLimit[] } = {};
    let nonStandardRateLimits: { [key: string]: RateLimit[] } = {};
    methodInfo.forEach((method) => {
        if (method.details.rateLimits) {
            nonStandardRateLimits[method.name] = method.details.rateLimits;
        }
    });
    objectInfo.forEach((obj) => {
        if (obj.details.rateLimits) {
            standardRateLimits[obj.name] = obj.details.rateLimits;
        }
    });
    return { standardRateLimits: standardRateLimits, nonStandardRateLimits: nonStandardRateLimits };
}

const linkTags = ["see", "a", "seealso"];

export function isId(memberName: string | null): boolean {
    return memberName === "Id";
}

export function isLinkTag(tag: string): boolean {
    return linkTags.includes(tag);
}

export function isTextTag(tag: string): boolean {
    return tag === "text" || tag === "#text";
}

// ApiMethodData can be MyAMethodInfo and MyGMethodInfo
export function handleReturnsTag(target: ApiMethodData, element: Element): void {
    let returnText = "";
    element.childNodes.forEach((child) => {
        let elementChild = child as Element;
        let tagName = elementChild.nodeName;
        if (isTextTag(tagName)) {
            returnText += elementChild.nodeValue?.replace(WHITESPACE_REGEX, " ");
        }
        if (tagName === "see") {
            returnText += elementChild.outerHTML;
        }
    });
    target.returns = returnText.trimStart();
}

export function extractCodeReference(element: Element): string {
    let valueElements = element.getElementsByTagName("value");
    for (let i = 0; i < valueElements.length; i++) {
        let seeElements = valueElements[i].getElementsByTagName("see");
        for (let j = 0; j < seeElements.length; j++) {
            return seeElements[j].attributes.getNamedItem("cref")?.nodeValue || "";
        }
    }
    return "";
}

export function extractLink(element: Element): string {
    let linkText = "";
    const tagName = element.nodeName.toLowerCase();
    if (tagName === "see" || tagName === "a") {
        const text = element.outerHTML;
        const wrappedSeeTagRegEx: RegExp = /<see cref="(.*?)">(.*?)<\/see>/g;
        let result = text;
        if (wrappedSeeTagRegEx.test(text)) {
            result = text.replace(wrappedSeeTagRegEx, '<see cref="$1"/>');
            linkText += result;
        }
        else {
            linkText += element.outerHTML;
        }
    }
    else if (tagName === "seealso") {
        const codeReference = element.attributes.getNamedItem("cref")?.nodeValue || "";
        const member = extractMember(codeReference);
        linkText += member;
    }
    return linkText.trim();
}

export function extractMember(memberName: string): string {
    const fqn = memberName.split(":")[1] || memberName;
    const memberNameArray: string[] = fqn.split(".");
    return memberNameArray[memberNameArray.length - 1];
}

