export function removeHtmlAndSpecificTags(input) {
    // Regular expression to match any HTML <see> tags with cref and extract the last word after the last period
    const regex = /<see cref="T:[\w.]+\.(\w+)"\/?>/g;

    // Replace HTML <see> tags with the captured last word
    const result = input.replace(regex, "$1");

    return result;
}

export function getTitle(fileContent) {
    const pageTitleRegex = /{\s*"title"\s*:\s*"([^"]*)"|{\s*title\s*:\s*"([^"]*)"/;
    const matches = fileContent.match(pageTitleRegex);
    return matches && (matches[1] || matches[2]) ? matches[1] || matches[2] : "";
}

export function getHeaders(fileContent) {
    const pageSectionsSummaryRegex = /"summary"\s*:\s*"([^"]+)"|summary\s*:\s*"([^"]+)"/g;
    const matches = [...fileContent.matchAll(pageSectionsSummaryRegex)];
    return matches.length > 0 ? matches.map((match) => match[1] || match[2]) : [];
}

export function getHeaderIds(fileContent) {
    const pageSectionsElementIdRegex = /"elementId"\s*:\s*"([^"]+)"|elementId\s*:\s*"([^"]+)"/g;
    const matches = [...fileContent.matchAll(pageSectionsElementIdRegex)];
    return matches.length > 0 ? matches.map((match) => match[1] || match[2]) : [];
}

export function getLink(relativePath) {
    return "/" + relativePath.replace(/\\/g, "/").replace(".tsx", "");
}

export function getBreadcrumb(fileContent) {
    const pageBreadCrumbsRegex = /"breadCrumbItems"\s*:\s*(\[[^\]]*\])|breadCrumbItems\s*:\s*(\[[^\]]*\])/;
    const matches = fileContent.match(pageBreadCrumbsRegex);

    if (matches && matches.length > 1) {
        try {
            return matches[1] ? JSON.parse(matches[1]) : matches[2] ? JSON.parse(matches[2]) : "";
        } catch (error) {
            return [];
        }
    } else {
        return [];
    }
}

export function getCategory(title) {
    return title === "API Reference" ? "reference" : "guide";
}


