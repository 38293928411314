import { ReactNode } from "react";
import { Page } from "../../components";
import { HeaderSections } from "../../components/Header/headerSectionsEnum";
import { PageTitleProps } from "../../components/PageTitle/PageTitle";
import { TableOfContentsItem } from "../../components/TableOfContents/TableOfContents";

const whatIsGeotabDrive: ReactNode = (
    <div className="paragraph">
        <p>
            Geotab Drive is a comprehensive fleet management and vehicle tracking application designed to enhance compliance, efficiency, and safety within fleet operations. The mobile app
            integrates with in-vehicle GO devices, OEM telematics, and Custom Telematics Devices, enabling an all-in-one solution for tracking, managing, and sharing key data about your fleet's
            daily Operations.
        </p>
        <h2>What is a Geotab Drive Add-In?</h2>
        <p>
            The MyGeotab Add-In structure can be applied to the Geotab Drive application as well, providing you the ability to extend the functionality for drivers in an environment with sensors
            (e.g. geolocation and acceleration) and actuators (e.g. voice alerts and notifications). This environment must also be completely operable in an offline state - so your Add-In must be
            able to handle having no internet connection, or sleeping in the background of the mobile device.
        </p>
        <h2>What types of integrations are possible?</h2>
        <p>
            There are many categories of possible integrations. Some examples are listed below, and a wider set can be found on the <a href="https://marketplace.geotab.com/about/" target="_blank" rel="noopener noreferrer">Geotab Marketplace</a>.
        </p>
        <ul>
            <li>Routing and Dispatch</li>
            <li>Risk Management</li>
            <li>Mobile Workflow</li>
            <li>Fuel Management</li>
        </ul>
    </div>
);

const pageTitle: PageTitleProps = {
    title: "Introduction",
    breadCrumbItems: ["Drive", "Introduction"]
};

const pageSections: TableOfContentsItem[] = [
    {
        elementId: "what-is-geotab-drive",
        summary: "What is Geotab Drive?",
        details: whatIsGeotabDrive
    }
];

export default function Introduction() {
    return <Page section={HeaderSections.Drive} pageTitle={pageTitle} tableOfContents={pageSections} />;
}
