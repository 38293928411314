import { ReactNode } from "react";
import { Page } from "../../../components";
import { PageTitleProps } from "../../../components/PageTitle/PageTitle";
import { HeaderSections } from "../../../components/Header/headerSectionsEnum";
import { CodeSample } from "../../../components/CodeSamplesContainer";
import myadminApiExample from "../../../assets/images/javascriptExamples/myadminApiExample.png";
import { TableOfContentsItem } from "../../../components/TableOfContents/TableOfContents";
import { Link } from "react-router-dom";

const javascriptCodeExampleSnippet = `<html>
    <body>
        <h1>Device Plans</h1>
        <ul id="devicePlanList"></ul>
        <h1>Device Details</h1>
        <div>
            <div id="firmwareElement"></div>
            <div id="commentsElement"></div>
            <div id="lastCommElement"></div>
            <div id="possibleIssuesElement"></div>
        </div>
        <script type="text/javascript" src="myAdminApi.js"></script>
        <script type="text/javascript">
            var apiKey,
            sessionId,
            logonParams = {
                username: "user@geotab.com",
                password: "<password>",
            };
            myAdminApi().call("Authenticate", logonParams, function (user) {
                apiKey = user.userId;
                sessionId = user.sessionId;
                var deviceIdx,
                listHtml = "",
                devicePlanList = document.getElementById("devicePlanList"),
                devicePlanParams = {
                    apiKey: apiKey,
                    sessionId: sessionId,
                },
                lookupDeviceParams = {
                    apiKey: apiKey,
                    sessionId: sessionId,
                    serialNo: "G6XXX0XXXD08",
                };
                myAdminApi().call("GetDevicePlans", devicePlanParams, function (devicePlans) {
                    for (deviceIdx in devicePlans) {
                        listHtml += "<li>" + devicePlans[deviceIdx].name + "</li>";
                    }
                    devicePlanList.innerHTML = listHtml;
                    myAdminApi().call("LookupDevice", lookupDeviceParams, function (device) {
                        document.getElementById("firmwareElement").innerText = "Firmware: " + device.firmwareVersion;
                        document.getElementById("commentsElement").innerText = "Comments: " + device.comments;
                        document.getElementById("lastCommElement").innerText = "Last Communication: " + device.lastServerCommunication;
                        document.getElementById("possibleIssuesElement").innerText = "Possible Issues: " + device.possibleIssues;
                    });
                });
            }, function (errorMessage, error) {
                var errorIdx,
                alertMsg = "Error Message: " + errorMessage;
                if (error && error.errors) {
                    alertMsg += \\n\\nThe following errors occurred:\\n";
                    for (errorIdx in error.errors) {
                        alertMsg += error.errors[errorIdx].name + "\\n";
                    }
                }
                alert(alertMsg);
            });
        </script>
    </body>
</html>`;

const javascriptExampleCodeSnippet1: ReactNode = (
    <div className="paragraph">
        <CodeSample language="javascript" code={javascriptCodeExampleSnippet} />
        <p>
            The <code>call</code> method uses the following parameters:
        </p>
        <ul>
            <li>Method name;</li>
            <li>Parameters;</li>
            <li>Success callback; and</li>
            <li>Error callback (optional).</li>
        </ul>
        <p>
            <strong>Note</strong>: The Success callback receives the object returned by the API as a parameter. The <Link to="/myAdmin/apiReference/methods">Reference</Link> page provides
            details about the objects returned by each method. In the example above, the error callback is called if the login fails. The error callback receives two parameters: an error message and
            an "errors" object that contains an array of individual errors that occurred. In the example above, the <code>devicePlans</code> object — returned by
            <code>GetDevicePlans</code> — is an array of <code>ApiDevicePlans</code>. The device object, returned by{" "}
            <code>LookupDevice</code>, is an <code>ApiDeviceInstallResult</code>. For more information, see{" "}
            <Link to="/myAdmin/apiReference/methods">Reference</Link>.{" "}
        </p>
        <img src={myadminApiExample} alt="MyAdmin Api Example" className="javascriptExamples__myadminApiExampleIMG" />
    </div>
);

const pageTitle: PageTitleProps = {
    title: "JavaScript Examples",
    breadCrumbItems: ["MYA", "Code Samples", "JavaScript Examples"]
};

const pageSections: TableOfContentsItem[] = [
    {
        elementId: "javascript-example-code-snippet1",
        summary: "Example 1",
        details: javascriptExampleCodeSnippet1
    }
];

export default function JavascriptExamples() {
    return (
        <Page section={HeaderSections.MyAdmin} pageTitle={pageTitle} tableOfContents={pageSections}>
            <div className="paragraph">
                The example below demonstrates a simple JavaScript application that authenticates with the MyAdmin API, then calls <code>GetDevicePlans</code> and{" "}
                <code>LookupDevice</code>.
            </div>
        </Page>
    );
}
