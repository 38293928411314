import { SideNavigationMenuItemType } from "@geotab/react-component-library";
import { IconCircleInfo, IconCodeBox, IconDocument, IconDocumentWithMagnifyingGlass, IconStar } from "../icons";

export const MYADMIN_NAVBAR_ITEMS: SideNavigationMenuItemType[] = [
    {
        label: "Introduction",
        route: "/myAdmin/introduction",
        level: 0,
        order: 0,
        userTypes: [],
        roles: [],
        Icon: IconCircleInfo
    },
    {
        label: "Release Notes",
        route: "/myAdmin/releaseNotes",
        level: 0,
        order: 1,
        userTypes: [],
        roles: [],
        Icon: IconStar
    },
    {
        label: "Guides",
        level: 0,
        order: 2,
        userTypes: [],
        roles: [],
        Icon: IconDocument,
        children: [
            {
                label: "Getting Started",
                route: "/myAdmin/guides/gettingStarted",
                level: 1,
                order: 0,
                roles: [],
                userTypes: []
            },
            {
                label: "Concepts",
                route: "/myAdmin/guides/concepts",
                level: 1,
                order: 1,
                roles: [],
                userTypes: []
            },
            // This link is here on purpose and is intended to link a MyGeotab page from the MyAdmin section
            {
                label: "Using Custom Telematics Devices",
                route: "/myGeotab/guides/usingCustomTelematicsDevices",
                level: 1,
                order: 2,
                roles: [],
                userTypes: []
            },
            {
                label: "Code Base",
                level: 1,
                order: 3,
                roles: [],
                userTypes: [],
                children: [
                    {
                        label: "Javascript",
                        route: "/myAdmin/guides/codeBase/usingWithJavascript",
                        level: 2,
                        order: 0,
                        roles: [],
                        userTypes: []
                    },
                    {
                        label: ".Net",
                        route: "/myAdmin/guides/codeBase/usingWithDotNet",
                        level: 2,
                        order: 1,
                        roles: [],
                        userTypes: []
                    }
                ]
            }
        ]
    },
    {
        label: "API Reference",
        level: 0,
        order: 3,
        userTypes: [],
        roles: [],
        Icon: IconDocumentWithMagnifyingGlass,
        children: [
            {
                label: "Methods",
                route: "/myAdmin/apiReference/methods",
                level: 1,
                order: 0,
                roles: [],
                userTypes: []
            },
            {
                label: "Objects",
                route: "/myAdmin/apiReference/objects",
                level: 1,
                order: 1,
                roles: [],
                userTypes: []
            }
        ]
    },
    {
        label: "Code Samples",
        level: 0,
        order: 4,
        userTypes: [],
        roles: [],
        Icon: IconCodeBox,
        children: [
            {
                label: "Javascript",
                route: "/myAdmin/codeSamples/javascriptExamples",
                level: 1,
                order: 0,
                roles: [],
                userTypes: []
            },
            {
                label: ".Net",
                route: "/myAdmin/codeSamples/dotNetExamples",
                level: 1,
                order: 1,
                roles: [],
                userTypes: []
            }
        ]
    }
];
