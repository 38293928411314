import { SelectField } from "@dev/zenith";
import { TableOfContentsItem, TableOfContentsProps } from "./TableOfContents";
import { ISelectListItem } from "@dev/zenith/dist/selectList/selectListItem";
import { useEffect, useRef, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

export default function TableOfContentsMobile({ items }: TableOfContentsProps): JSX.Element {
    const [activeSection, setActiveSection] = useState<string>(items[0].elementId);
    const pageContentScrollObserver = useRef() as React.MutableRefObject<IntersectionObserver>;
    const navigate: NavigateFunction = useNavigate();
    const MOBILE_PAGE_SCROLL_OBSERVER_THRESHOLD: number = 0.75; // Set the threshold to 0.75 so that the active section changes as soon as a section is 75% within the viewport
    const MOBILE_PAGE_SCROLL_OBSERVER_ROOT_MARGIN: string = "-122px 0px -36px 0px"; // The root margin is set to the height of the header & mobile table of contents
    // & the height of the footer to ensure proper viewport observation

    useEffect((): void => {
        setActiveSection(items[0].elementId);
    }, [items]);

    // TODO: Ignore the useEffect hook for unit test coverage, will be covered in Selenium tests
    /* istanbul ignore next */
    useEffect((): (() => void) => {
        // Create an observer to monitor when the page content scrolls so that the table of contents reflects what the user is looking at
        pageContentScrollObserver.current = new IntersectionObserver(
            (entries) => {
                const visibleSection: Element | undefined = entries.find((entry): boolean => entry.isIntersecting)?.target;

                // Update state with the visible section ID & scroll to that section in the Table of Contents if the Table of Contents is overflowing
                if (visibleSection && visibleSection.id !== activeSection) {
                    setActiveSection(visibleSection.id);
                }
            },
            {
                threshold: MOBILE_PAGE_SCROLL_OBSERVER_THRESHOLD,
                rootMargin: MOBILE_PAGE_SCROLL_OBSERVER_ROOT_MARGIN
            }
        );

        const pageSections: Element[] = [...document.querySelectorAll("details, details summary, details div.detailsContent, div.method-object-container")].filter(
            (section): section is Element => !section.closest(".reference-accordion")
        );

        pageSections.forEach((section): void => {
            pageContentScrollObserver.current.observe(section);
        });

        //Cleanup function to remove observers
        return () => {
            pageSections.forEach((section): void => {
                pageContentScrollObserver.current.unobserve(section);
            });
        };
    }, [activeSection]);

    function handlePageSectionChange(id: string | undefined): void {
        if (id !== undefined) {
            if (id !== activeSection) {
                setActiveSection(id);
                // Navigate doesn't work for hash links, but it does update the URL to include the new hash, so use that and then scroll to the section manually
                navigate(`#${id}`);
                let pageElementToScrollTo: Element | null = document.querySelector(`summary#${id}`) || document.querySelector(`div.method-object-container#${id}`);
                pageElementToScrollTo?.scrollIntoView({ block: "start" });
            }
        }
    }

    return (
        <div className="tableOfContents__mobile">
            <SelectField
                title="Table of Contents"
                items={items.map(
                    (item: TableOfContentsItem): ISelectListItem => ({
                        id: item.elementId,
                        children: item.summary
                    })
                )}
                value={activeSection}
                placeholder="Table of Contents"
                onChange={handlePageSectionChange}
            />
        </div>
    );
}
