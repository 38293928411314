import { Button } from "@dev/zenith";
import { ButtonType } from "@dev/zenith/dist/button/buttonType";
import { GraphicSearchNotFound } from "../components/SearchModal/assets/GraphicSearchNotFound";
import { useNavigate } from "react-router-dom";
import { Header } from "./../components";
import "./pages.scss";
import { useContext, useEffect } from "react";
import MenuContext from "../menuContext";
import { HeaderSections } from "../components/Header/headerSectionsEnum";

export default function NotFoundPage(): JSX.Element {
    const navigate = useNavigate();
    const { setActiveSiteSection } = useContext(MenuContext);

    const handleReturnToHomepage = () => {
        navigate("/");
    };

    useEffect((): void => {
        setActiveSiteSection(HeaderSections.Landing);
    });

    return (
        <div className="error-page__main-container">
            <Header isLandingPage={true} />
            <div className="error-page__content-container">
                <div className="error-page__404-content">
                    <h1 className="title">404 Not Found</h1>
                    <span>The page you were looking for could not be found</span>
                    <Button type={ButtonType.Primary} onClick={handleReturnToHomepage} title="Return to homepage" aria-label="Return to homepage">
                        Return to homepage
                    </Button>
                </div>
                <GraphicSearchNotFound width={734} height={433} />
            </div>
        </div>
    );
}
