import { ReactNode } from "react";
import { Page } from "../../components";
import { HeaderSections } from "../../components/Header/headerSectionsEnum";
import { PageTitleProps } from "../../components/PageTitle/PageTitle";
import { TableOfContentsItem } from "../../components/TableOfContents/TableOfContents";
import { CodeSample } from "../../../src/components/CodeSamplesContainer";
import DVIRLogObjectExample from "../../../src/assets/images/driveExamples/DVIRLogObjectExample.png";
import defectList from "../../../src/assets/images/driveExamples/defectList.png";
import defectList2 from "../../../src/assets/images/driveExamples/defectList2.png";
import defectList3 from "../../../src/assets/images/driveExamples/defectList3.png";
import defectList4 from "../../../src/assets/images/driveExamples/defectList4.png";
import partsAndDefect from "../../../src/assets/images/driveExamples/partsAndDefect.png";
import partsAndDefect2 from "../../../src/assets/images/driveExamples/partsAndDefect2.png";
import UsingThePartsIdtoRepresentTheDefect from "../../../src/assets/images/driveExamples/UsingThePartsIdtoRepresentTheDefect.png";

const dvirApis: ReactNode = (
    <div className="paragraph">
        <h2>Latest updates:</h2>
        <ol>
            <li>
                <h3>Severity level for Defect in DVIRs</h3>
            </li>
            <ol type="a">
                <li>
                    “Unregulated” Defects are defects that are not included in the Electronic Code of Federal Regulations §396.11. For example, paint scratches on the door or the radio button
                    malfunction.
                </li>
                <li>“Unregulated” Defect will be carried over to the next DVIR if it's not been repaired in the previous DVIR. </li>
            </ol>
            <li>
                <h3>Create DVIR without repairing all defects on the pervious DVIR</h3>
            </li>
            <ol type="a">
                <li>
                    DVIR can be certified with outstanding defects. It will remain on that device until this Defect has been repaired. And new DVIR can be created after the previous log has been
                    certified.
                </li>
            </ol>
            <li>
                <h3>Allow repairing of individual defects</h3>
            </li>
            <ol type="a">
                <li>
                    Defect now can be repaired individually with the new DVIRDefect object. A DVIRLog has a list of DVIRDefect objects which contains information such as repairDate, repairUser and
                    repairStatus.
                </li>
                <li>Each DVIRDefect can have three states: Not Repaired, Repaired, Not Necessary </li>
            </ol>
            <li>
                <h3>Allow adding multiple remarks to defects </h3>
            </li>
            <ol type="a">
                <li>Each DVIRDefect has a list of DefectRemark which has information about the date and the user who made this remark and the remark itself.</li>
            </ol>
        </ol>
        <h2>Rules about creating a new DVIRLog</h2>
        <p>
            When creating a new DVIRLog for an existing device, we must first look up its previous DVIRLog. If the previous DVIRLog on this device has not been repaired, we may need to first repair
            the outstanding defects. Some defects can be carried forward from one inspection to another, depending on jurisdiction.
        </p>
    </div>
);

const example: ReactNode = (
    <div className="paragraph">
        <p>
            Here are some API runner examples on how to interact with the new DVIR objects, note that the deviceId, defectId, userId and so on will be different depending on which database you are
            using. The properties which are in <strong>bold</strong>, are the key requirements.
        </p>
        <h2>Workflow for create a new DVIRLog with no previous DVIRLog on the same Device</h2>
        <ol>
            <li>
                <h3>Create a new DVIRLog</h3>
            </li>
            <CodeSample
                language="javascript"
                code={`api.call("Add", {
    "typeName": "DVIRLog",
    "entity": {
        "dateTime": "2018-09-11T18:02:38.618Z",
        "device": {
        "id": deviceId
        },
        "trailer": "NoTrailerId",
        "driver": {
        "id": userId
        },
        "driverRemark": "",
        "DVIRDefects": [
        // This is an unrepaired Defect
        {
            "defect": {
            "id": defectId
            },
            "repairstatus": "NotRepaired"
        }
        ],
        "logType": "PreTrip",
        "defectList": DefectListId,
        "repairRemark": null,
        "isSafeToOperate": null,
        "certifiedBy": "NoUserId",
        "certifyDate": null,
        "certifyRemark": null
    }
}, function(result) {
        console.log("Added  " + result);
}, console.error);`}
            />
            <li>
                <h3>Pull the DVIRLog we just added</h3>
            </li>
            <CodeSample
                language="javascript"
                code={`api.call("Get", {
    "typeName": "DVIRLog",
    "search": {
        "deviceSearch": { 
            "id": deviceId 
        }
    }
}, function(result) {
        console.log("Done: ", result);
}, function(e) {
        console.error("Failed:", e);
});`}
            />
            <li>
                <h3>Repair the DVIRDefect inside the DVIRLog</h3>
            </li>
            <CodeSample
                language="javascript"
                code={`api.call("Set", {
    "typeName": "DVIRLog",
    "entity": {
        "id": dvirLogId,
        "dateTime": "2018-09-11T18:02:38.618Z",
        "device": {
            "id": deviceId
        },
        "trailer": "NoTrailerId",
        "driver": {
            "id": userId
        },
        "driverRemark": "",
        "DVIRDefects": [
            // Repair this DVIRDefect by updating the repairDateTime, repairUser, and repairstatus
            {
                "id": dvirDefectId1,
                "defect": {
                    "id": defectId
                },
                "repairDateTime": "2018-09-11T18:02:38.617Z",
                "repairstatus": "Repaired",
                "repairUser": {
                    "id": userId
                },
            }
        ],
        "logType": "PreTrip",
        "repairRemark": null,
        "isSafeToOperate": null,
        "certifiedBy": "NoUserId",
        "certifyDate": null,
        "certifyRemark": null
    }
}, function (result) {
    console.log("Set success");
}, console.error);`}
            />
            <li>
                <h3>How to add a remark</h3>
            </li>
            <CodeSample
                language="javascript"
                code={`api.call("Set", {
    "typeName": "DVIRLog",
    "entity": {
        "id": dvirLogId,
        "dateTime": "2018-09-11T18:02:38.618Z",
        "device": {
            "id": deviceId
        },
        "trailer": "NoTrailerId",
        "driver": {
            "id": userId
        },
        "driverRemark": "",
        "DVIRDefects": [
            {
                "id": dvirDefectId1,
                "defect": {
                    "id": defectId
                },
                "repairDateTime": "2018-09-11T18:02:38.617Z",
                "repairstatus": "Repaired",
                "repairUser": {
                    "id": userId
                },
                // Add a defectRemarks property inside the DVIRDefect Object
                "defectRemarks": [
                    {
                        "remark": "RemarkOne",
                        "dateTime": "2018-09-11T18:02:38.618Z",
                        "user": {
                            "id": userId
                        },
                    }
                ]
            }
        ],
        "logType": "PreTrip",
        "repairRemark": null,
        "isSafeToOperate": null,
        "certifiedBy": "NoUserId",
        "certifyDate": null,
        "certifyRemark": null
    }
}, function (result) {
    console.log("Set success");
}, console.error);`}
            />
            <li>
                <h3>How to update the remark</h3>
            </li>
            <CodeSample
                language="javascript"
                code={`api.call("Set", {
    "typeName": "DVIRLog",
    "entity": {
        "id": dvirLogId,
        "dateTime": "2018-09-11T18:02:38.618Z",
        "device": {
            "id": deviceId
        },
        "trailer": "NoTrailerId",
        "driver": {
            "id": userId
        },
        "driverRemark": "",
        "DVIRDefects": [
            {
                "id": dvirDefectId1,
                "defect": {
                    "id": defectId
                },
                "repairDateTime": "2018-09-11T18:02:38.617Z",
                "repairstatus": "Repaired",
                "repairUser": {
                    "id": userId
                },
                "defectRemarks": [
                    {
                        // Add the ID and update the remark
                        "id": defectRemarkId,
                        "remark": "Updated Remark",
                        "dateTime": "2018-09-11T18:02:38.618Z",
                        "user": {
                            "id": userId
                        },
                    }
                ]
            }
        ],
        "logType": "PreTrip",
        "repairRemark": null,
        "isSafeToOperate": null,
        "certifiedBy": "NoUserId",
        "certifyDate": null,
        "certifyRemark": null
    }
}, function (result) {
    console.log("Set success");
}, console.error);`}
            />
            <li>
                <h3>How to certify the DVIRLog</h3>
            </li>
            <CodeSample
                language="javascript"
                code={`api.call("Set", {
    "typeName": "DVIRLog",
    "entity": {
        "id": dvirLogId,
        "dateTime": "2018-09-11T18:02:38.618Z",
        "device": {
            "id": deviceId
        },
        "trailer": "NoTrailerId",
        "driver": {
            "id": userId
        },
        "driverRemark": "",
        "DVIRDefects": [
            {
                "id": dvirDefectId1,
                "defect": {
                    "id": defectId
                },
                "repairDateTime": "2018-09-11T18:02:38.617Z",
                "repairstatus": "Repaired",
                "repairUser": {
                    "id": userId
                },
                "defectRemarks": [
                    {
                        "id": defectRemarkId,
                        "remark": "Updated Remark",
                        "dateTime": "2018-09-11T18:02:38.618Z",
                        "user": {
                            "id": userId
                        },
                    }
                ]
            }
        ],
        "logType": "PreTrip",
        "repairRemark": null,
        "isSafeToOperate": null,
        // Update the certify properties 
        "certifiedBy": {
            "id": userId
        },
        "certifyDate": "2018-09-11T18:02:38.618Z",
        "certifyRemark": "Good to go"
    }
}, function (result) {
    console.log("Set success");
}, console.error);`}
            />
        </ol>
        <h2>Workflow for create a new DVIRLog with previous DVIRLog on the same Device</h2>
        <ol>
            <li>
                <h3>Need to pull previous DVIR on this device. </h3>
            </li>
            <CodeSample
                language="javascript"
                code={`api.call("Get", {
    "typeName": "DVIRLog",
    search: {
        deviceSearch: { id: deviceId }
    },
}, function (result) {
    console.log("Done: ", result);
}, function (e) {
    console.error("Failed:", e);
});`}
            />
            <li>
                <h3>Create a new DVIRLog with a carryover defect from previous log</h3>
            </li>
            <CodeSample
                language="javascript"
                code={`api.call("Add", {
    "typeName": "DVIRLog",
    "entity": {
        "dateTime": "2018-09-11T18:02:38.618Z",
        "device": {
            "id": deviceId
        },
        "trailer": "NoTrailerId",
        "driver": {
            "id": userId
        },
        "driverRemark": "",
        "DVIRDefects": [
            // This is a carryover Defect from previous DVIRLog
            {
                "id": dvirDefectId,
                "defect": {
                    "id": defectId
                },
                "repairstatus": "NotRepaired"
            }
        ],
        "logType": "PreTrip",
        "repairRemark": null,
        "isSafeToOperate": null,
        "certifiedBy": "NoUserId",
        "certifyDate": null,
        "certifyRemark": null
    }
}, function (result) {
    console.log("Added  " + result);
}, console.error);`}
            />
            <li>
                <h3>Create a new DVIRLog with a carryover defect from previous log and additional defects</h3>
            </li>
            <CodeSample
                language="javascript"
                code={`api.call("Add", {
    "typeName": "DVIRLog",
    "entity": {
        "dateTime": "2018-09-11T18:02:38.618Z",
        "device": {
            "id": deviceId
        },
        "trailer": "NoTrailerId",
        "driver": {
            "id": userId
        },
        "driverRemark": "",
        "DVIRDefects": [
            // This is a new Defect
            {
                "defect": {
                    "id": defectId
                },
                "repairstatus": "NotRepaired"
            },
            // This is a carryover Defect from previous DVIRLog
            {
                "id": dvirDefectId,
                "defect": {
                    "id": defectId
                },
                "repairstatus": "NotRepaired"
            }
        ],
        "logType": "PreTrip",
        "repairRemark": null,
        "isSafeToOperate": null,
        "certifiedBy": "NoUserId",
        "certifyDate": null,
        "certifyRemark": null
    }
}, function (result) {
    console.log("Added  " + result);
}, console.error);`}
            />
        </ol>
    </div>
);

const defectListAndDefectInDVIRLog: ReactNode = (
    <div className="paragraph">
        <p>Below is a typical example of a DVIRLog object:</p>
        <img src={DVIRLogObjectExample} alt="A typical example of a DVIRLog object" />
        <p>Most of the object inside DVIRLog are minified, but you can use the id provided to get the full object.</p>
        <p>
            In the above example, <strong>defectList</strong> has an id with value “b278A”, we can use this id to get the full <strong>defectList</strong> which used to create this{" "}
            <strong>DVIRLog</strong>.
        </p>
        <CodeSample
            language="javascript"
            code={`api.call("Get", {
    "typeName": "Group",
    "search": {
        id: "b278A"
    },
    "resultsLimit": 10
}, function (result) {
    console.log("Done: ", result);
}, function (e) {
    console.error("Failed:", e);
});`}
        />
        <p>
            Now we have the entire <strong>defectList</strong> which contains <strong>Parts</strong> and the <strong>Defect</strong> under each <strong>Part</strong>
        </p>
        <img src={defectList} alt="DefectList containing parts and the defect under each part" />
        <p>
            If we expand the children property in this <strong>defectList</strong> we will have a list of <strong>Parts</strong> with their name, id and other properties.
        </p>
        <img src={defectList2} alt="DefectList object expanded to show parts with their name, id and other properties" />
        <p>
            If we expand even further into <strong>Parts</strong>, we can find a list of <strong>Defect</strong> objects under each Part with their own id. In the example below the{" "}
            <strong>Part</strong> is “Brakes (Parking)” with id “b278B”, and the Defect under that <strong>Parts</strong> is “slow to release” with id “b278C”
        </p>
        <img src={defectList3} alt="DefectList expanded further into parts to show a list of defect objects under each part with their own id" />
        <p>
            This <strong>Defect</strong> id “b278C” is also the <strong>Defect</strong> id we use inside the <strong>DVIRDefect</strong> object
        </p>
        <img src={defectList4} alt="Showing that this Defect id “b278C” is also the Defect id we use inside the DVIRDefect object" />
        <p>
            So the best way to understand the meaning of each <strong>Defect</strong> in DVIRDefect is to first retrieve the <strong>defectList</strong> used to create the DVIRLog, and then look up
            the <strong>Defect</strong> id in this list to understand which <strong>Part</strong> the <strong>Defect</strong> belongs to.
        </p>
        <img src={partsAndDefect} alt="Illustrating the process of identifying a Defect's corresponding Part in the defect list" />
        <p>
            The number of <strong>Defects</strong> under each Part are finite so if a user couldn't find the appropriate <strong>Defect</strong> inside one <strong>Part</strong>, they can choose
            “Other” as the <strong>Defect</strong> (this can be disabled for the defect list in MyGeotab)
        </p>
        <img src={partsAndDefect2} alt="Explaining the selection of 'Other' for unlisted defects in a Part in the defect list" />
        <p>
            When a user chooses “Other”, we use the <strong>Parts</strong> id to represent the <strong>Defect</strong>.
        </p>
        <img src={UsingThePartsIdtoRepresentTheDefect} alt="Explaining the selection of 'Other' for unlisted defects in a Part in the defect list" />
        <p>
            Note that the <strong>Defect</strong> id “b278B” in the <strong>DVIRLog</strong> on the left is the id for the <strong>Part</strong> “Brakes (Parking)”, not a <strong>Defect</strong> id
            under this <strong>Part</strong> such as “b278C” which is “slow to release”
        </p>
        <p>
            Note:
            <br></br>
            If “Other” defect is selected, it is required to add a remark to describe the defect. So <strong>DVIRDefect</strong> with Defect id being a Part id requires at least one{" "}
            <strong>DefectRemark</strong>.
        </p>
    </div>
);

const otherNotes: ReactNode = (
    <div className="paragraph">
        If DVIRLog's RepairBy & RepairDate is not populated, and all DVIRDefect under that Log is repaired, we will use last repaired DVIRDefect's RepairedBy & RepairDate to populate the DVIRLog
        RepairBy and RepairDate.
    </div>
);

const pageTitle: PageTitleProps = {
    title: "Examples",
    breadCrumbItems: ["Drive", "Examples"]
};

const pageSections: TableOfContentsItem[] = [
    {
        elementId: "dvir-apis",
        summary: "DVIR APIs",
        details: dvirApis
    },
    {
        elementId: "example",
        summary: "Example",
        details: example
    },
    {
        elementId: "defect-list-and-defect-in-dvir-log",
        summary: "defectList and Defect in DVIRLog",
        details: defectListAndDefectInDVIRLog
    },
    {
        elementId: "other-notes",
        summary: "Other notes",
        details: otherNotes
    }
];

export default function Examples() {
    return <Page section={HeaderSections.Drive} pageTitle={pageTitle} tableOfContents={pageSections} />;
}
