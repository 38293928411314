import { ReactNode } from "react";
import { Page } from "..";
import { PageTitleProps } from "../PageTitle/PageTitle";
import { HeaderSections } from "../Header/headerSectionsEnum";
import { TableOfContentsItem } from "../TableOfContents/TableOfContents";
import renderStringWithUrl from "./utils/renderStringWithUrl";
import getDataTypeData from "./utils/dataTypeUtils";
import { CodeSamples, CodeSamplesContainer } from "../CodeSamplesContainer";
import { WIP_STRING, makeTryMeLink } from "./utils/apiReferenceUtils";
import "./reference.scss";
import { Pill } from "@dev/zenith";
import BetaPill from "../BetaPill/BetaPill";
import { RateLimit } from "./../ApiReference/utils/apiReferenceUtils";
import InformationalBox from "../InformationalBox/InformationalBox";
import { Link } from "react-router-dom";

export interface ApiMethodParameter {
    name: string;
    description: string;
    dataType: string;
    isSupported?: boolean; // myg and mya
    isRequired?: boolean; // myg and mya
    isBeta?: boolean; // myg
}

export interface ApiMethodData {
    description: string;
    parameters: ApiMethodParameter[];
    roles?: string[]; // mya
    returns: string;
    example: string;
    remarks?: string; // TODO: remarks is not being displayed. this is only in mya
    isSupported?: boolean; // myg and mya
    isBeta?: boolean; // myg
    rateLimits?: RateLimit[];
}

export interface ApiMethodProps {
    introduction: JSX.Element;
    parameters?: ApiMethodParameter[];
    roles?: string[]; // mya
    returns?: JSX.Element;
    codeSamples: CodeSamples;
    title: PageTitleProps;
    headerSection: HeaderSections;
    rateLimits?: RateLimit[];
}

export default function ApiMethod({ introduction, parameters, returns, codeSamples, title, headerSection, rateLimits, roles }: ApiMethodProps): JSX.Element {
    let pageSections: TableOfContentsItem[] = [];

    const introductionParagraph: ReactNode = <div className="paragraph">{introduction}</div>;
    pageSections.push({
        elementId: "introduction",
        summary: "Introduction",
        details: introductionParagraph
    });

    if (roles && roles.length > 0) {
        const rolesText: string = roles.join(", ");

        const rolesParagraph: ReactNode = (
            <div className="paragraph">
                {rolesText}
            </div>
        );

        pageSections.push({
            elementId: "required-roles",
            summary: "Required roles",
            details: rolesParagraph
        });
    }

    if (parameters && parameters.length > 0) {
        let parameterElements = parameters.map((parameter: ApiMethodParameter, index: number) => {
            let dataTypeInformation = getDataTypeData(parameter.dataType);
            return (
                <div className="method-parameter-container" key={`param-${index}`}>
                    <div className="method-parameter-header-container">
                        <div>
                            <h2>{parameter.name}</h2>
                        </div>
                    </div>
                    <div className="method-parameter-description-container">
                        <div className="method-parameter-type-labels">
                            <span>{dataTypeInformation}</span>
                            {parameter.isRequired && <Pill type={"warning"}>Required</Pill>}
                            {parameter.isBeta && <BetaPill />}
                        </div>
                        {renderStringWithUrl(parameter.name, parameter.description, headerSection)}
                    </div>
                </div>
            );
        });

        const parameterParagraphs: ReactNode = <div className="paragraph">{parameterElements}</div>;
        pageSections.push({
            elementId: "parameters",
            summary: "Parameters",
            details: parameterParagraphs
        });
    }

    if (returns) {
        const returnDescription: ReactNode = <div className="paragraph">{returns}</div>;
        pageSections.push({
            elementId: "return-value",
            summary: "Return value",
            details: returnDescription
        });
    }

    if (codeSamples.javascript && codeSamples.javascript !== "" && codeSamples.javascript !== WIP_STRING) {
        title.button = { text: "Try Me", link: makeTryMeLink(codeSamples.javascript) };
    } else {
        codeSamples.javascript = WIP_STRING;
    }

    const tryMeCodeBlock: ReactNode = (
        <div className="paragraph">
            <CodeSamplesContainer {...codeSamples} />
        </div>
    );
    pageSections.push({
        elementId: "code-sample",
        summary: "Code samples",
        details: tryMeCodeBlock
    });

    if (rateLimits && rateLimits.length > 0) {
        const specialRateLimitMethods: string[] = ["Get", "Set", "Add", "Remove", "GetCountOf", "GetFeed"];
        const rateLimitInformation: ReactNode = (
            <InformationalBox>
                <p>
                    This rate limit <strong> only applies to objects that do not have a more specific rate limit</strong>. Review the{" "}
                    <Link to="/myGeotab/apiReference/objects">object reference</Link> to determine the specific rate limit.
                </p>
            </InformationalBox>
        );
        const rateLimitTable: ReactNode = (
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Method</th>
                            <th>Description</th>
                            <th>Limit</th>
                            <th>Period</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rateLimits.map((rateLimit: RateLimit, index: number) => (
                            <tr key={`rateLimit-${index}`}>
                                <td>{rateLimit.name}</td>
                                <td>{rateLimit.description}</td>
                                <td>{rateLimit.limit}</td>
                                <td>{rateLimit.period}</td>
                                <td>{rateLimit.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );

        const rateLimitParagraph: ReactNode = (
            <div className="paragraph">
                {rateLimits.some((x) => specialRateLimitMethods.includes(x.name)) && rateLimitInformation}
                {rateLimitTable}
            </div>
        );
        pageSections.push({
            elementId: "rate-limits",
            summary: "Rate limits",
            details: rateLimitParagraph
        });
    }

    return <Page section={headerSection} pageTitle={title} tableOfContents={pageSections} />;
}
