import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./tableOfContents.scss";
import { HashLink } from "react-router-hash-link";
import { JSX } from "react/jsx-runtime";

export interface TableOfContentsItem {
    summary: ReactNode;
    elementId: string;
    details?: ReactNode;
}

export interface TableOfContentsProps {
    items: TableOfContentsItem[];
}

export default function TableOfContents({ items }: TableOfContentsProps): JSX.Element {
    const [activeSection, setActiveSection] = useState<string>(items[0].elementId);
    const pageContentScrollObserver = useRef() as React.MutableRefObject<IntersectionObserver>;
    const tableOfContentsScrollObserver = useRef() as React.MutableRefObject<IntersectionObserver>;

    const tableOfContentsRef = useRef() as React.MutableRefObject<HTMLUListElement>;
    const activeListItemRef = useRef() as React.MutableRefObject<HTMLLIElement>;

    const TOC_HEADING_OFFSET: number = 45; // 45px is the total height of the table of contents header including padding/margin
    const TABLE_OF_CONTENTS_SCROLL_OBSERVER_THRESHOLD: number = 1; // Set the threshold to 1 to look for the entire first element if it is in view or not
    const DESKTOP_PAGE_SCROLL_OBSERVER_THRESHOLD: number = 0; // Set the threshold to 0 so that the active section changes as soon as a section enters the viewport
    const DESKTOP_PAGE_SCROLL_OBSERVER_ROOT_MARGIN: string = "-64px 0px -36px 0px"; // The root margin is set to the height of the header & height of the footer to ensure proper viewport observation


    // useEffect hook implementation copied from:
    // https://netacci.hashnode.dev/how-to-highlight-active-navigation-on-scroll-in-react#heading-intersection-observer-api
    // TODO: Ignore the useEffect hook for unit test coverage, will be covered in Selenium tests
    /* istanbul ignore next */
    useEffect((): (() => void) => {
        let tableOfContentsElement: HTMLUListElement = tableOfContentsRef.current;
        let firstListItemElement: HTMLLIElement = tableOfContentsElement.querySelector("li:first-of-type") as HTMLLIElement;

        // Create an observer to monitor when the page content scrolls so that the table of contents reflects what the user is looking at
        pageContentScrollObserver.current = new IntersectionObserver(
            (entries) => {
                const visibleSection: Element | undefined = entries.find((entry): boolean => entry.isIntersecting)?.target;

                tableOfContentsElement = tableOfContentsRef.current;
                let tocContainer: DOMRect = tableOfContentsElement.getBoundingClientRect();
                let activeListItemElement: HTMLLIElement = activeListItemRef.current;
                let activeItemContainer: DOMRect = activeListItemElement.getBoundingClientRect();
                let isTocOverflowing: boolean = tableOfContentsElement.scrollHeight > tableOfContentsElement.clientHeight;

                // Update state with the visible section ID & scroll to that section in the Table of Contents if the Table of Contents is overflowing
                if (visibleSection) {
                    setActiveSection(visibleSection.id);

                    if (isTocOverflowing) {
                        if (activeItemContainer.top < tocContainer.top + TOC_HEADING_OFFSET || activeItemContainer.bottom >= tocContainer.bottom) {
                            // If the active table of contents item is not in the viewport, scroll to it
                            activeListItemElement.scrollIntoView({ block: "center", inline: "nearest" });
                        } else if (activeItemContainer.top >= tocContainer.top && activeItemContainer.bottom < tocContainer.bottom) {
                            // Using "nearest" instead of any other block option, because other options will cause the main page to scroll unintentionally
                            activeListItemElement.scrollIntoView({ block: "nearest", inline: "nearest" });
                        }
                    }
                }
            },
            {
                threshold: DESKTOP_PAGE_SCROLL_OBSERVER_THRESHOLD,
                rootMargin: DESKTOP_PAGE_SCROLL_OBSERVER_ROOT_MARGIN
            }
        );

        const pageSections: Element[] = [...document.querySelectorAll("details, details summary, details div.detailsContent, div.method-object-container")].filter(
            (section): section is Element => !section.closest(".reference-accordion")
        );

        pageSections.forEach((section): void => {
            pageContentScrollObserver.current.observe(section);
        });

        // Create an observer to monitor when the table of contents is being scrolled (aka when the first li element is out of view) so that a drop shadow is applied to the "Table of contents" heading (aka the 'before' pseudo element)
        tableOfContentsScrollObserver.current = new IntersectionObserver(([e]) => tableOfContentsElement.classList.toggle("is-pinned", e.intersectionRatio < 1), {
            threshold: TABLE_OF_CONTENTS_SCROLL_OBSERVER_THRESHOLD
        });
        tableOfContentsScrollObserver.current.observe(firstListItemElement);

        //Cleanup function to remove observers
        return () => {
            pageSections.forEach((section): void => {
                pageContentScrollObserver.current.unobserve(section);
            });
            tableOfContentsScrollObserver.current.unobserve(firstListItemElement);
        };
    }, []);

    useEffect((): void => {
        setActiveSection(items[0].elementId);
    }, [items]);

    return (
        <ul className="tableOfContents" ref={tableOfContentsRef}>
            {items.map(
                (item: TableOfContentsItem): JSX.Element => (
                    <li
                        key={item.elementId}
                        className={activeSection === item.elementId ? "tableOfContents__item--active" : ""}
                        ref={activeSection === item.elementId ? activeListItemRef : undefined}
                    >
                        <HashLink to={`#${item.elementId}`}>{item.summary}</HashLink>
                    </li>
                )
            )}
        </ul>
    );
}
