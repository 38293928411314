import { useEffect, useRef } from "react";
import { Button } from "@dev/zenith";
import { ButtonType } from "@dev/zenith/dist/button/buttonType";
import BreadCrumbTrail from "../BreadCrumbTrail/BreadCrumbTrail";
import BetaPill from "../BetaPill/BetaPill";
import "./pageTitle.scss";


export interface TitleButton {
    text: string;
    link: string;
}

export interface PageTitleProps {
    title: string;
    breadCrumbItems: string[];
    isBeta?: boolean;
    button?: TitleButton;
}

export default function PageTitle({ title, breadCrumbItems, isBeta, button }: PageTitleProps): JSX.Element {
    const pageTitlePositionObserver = useRef() as React.MutableRefObject<IntersectionObserver>;
    const pageTitleRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const PAGE_TITLE_IS_IN_VIEW_THRESHOLD = 1;

    useEffect((): (() => void) => {
        let headerElement: HTMLDivElement = document.querySelector(".header-container") as HTMLDivElement;
        let pageTitleElement: HTMLDivElement = pageTitleRef.current;

        // Create an observer to monitor when the Header is sticky (aka when the Page Title goes out of view) so that the Header has a drop shadow on scroll
        pageTitlePositionObserver.current = new IntersectionObserver(([e]) => headerElement.classList.toggle("is-pinned", e.intersectionRatio < 1), {
            threshold: PAGE_TITLE_IS_IN_VIEW_THRESHOLD
        });
        pageTitlePositionObserver.current.observe(pageTitleElement);
        return () => {
            pageTitlePositionObserver.current.unobserve(pageTitleElement);
        };
    }, []);

    const handlePageTitleButtonClick = function () {
        window.open(button?.link, "_blank", "noopener noreferrer");
    };

    return (
        <div className="page-title-container">
            <div className="pageTitle" ref={pageTitleRef}>
                <BreadCrumbTrail crumbs={breadCrumbItems} />
                <div className="pageTitle__subContainer">
                    <h1 className="pageTitle__titleText">{title}</h1>
                    {isBeta && <BetaPill />}
                </div>
            </div>
            {button && (
                <div className="page-title-button-container">
                    <Button onClick={handlePageTitleButtonClick} type={ButtonType.Primary} title={button.text} aria-label={button.text}>
                        {button.text}
                    </Button>
                </div>
            )}
        </div>
    );
}