export default function CodeSampleStyle(): Record<string, string> {
    return {
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "16px",
        textAlign: "left",
        padding: "24px",
        borderRadius: "8px"
    };
}
