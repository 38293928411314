import algoliasearch, { SearchClient, SearchIndex } from "algoliasearch";
import { AlgoliaSection } from "../../../utils/indexInterfaces";

interface SearchSnippet {
    matchLevel: string;
    value: string;
}

export interface AlgoliaSearchResult extends AlgoliaSection {
    snippets: {
        content: SearchSnippet;
        headers: SearchSnippet[];
        title: SearchSnippet;
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function instanceOfAlgoliaSearchResult(object: any): object is AlgoliaSearchResult {
    return "objectID" in object;
}

export const algoliaSearch = async (query: string): Promise<AlgoliaSearchResult[]> => {
    const client: SearchClient = algoliasearch("NSVQJ92SLA", "aa6d07e3e75382382833589fbf07c509"); //this is a read-only rate-limited api key meant to be publicly visible
    const index: SearchIndex = client.initIndex("test_sdk_site");

    return new Promise((resolve, reject) => {
        index
            .search(query)
            .then(({ hits }) => {
                //throw new Error("testing backup search"); //use for testing minisearch
                resolve(
                    hits.map(
                        (hit) =>
                            ({
                                /* eslint-disable @typescript-eslint/no-explicit-any */
                                objectID: hit.objectID as unknown as number,
                                title: (hit as any).title,
                                content: (hit as any).content,
                                headers: (hit as any).headers,
                                headerIds: (hit as any).headerIds,
                                link: (hit as any).link,
                                category: (hit as any).category,
                                breadCrumb: (hit as any).breadCrumb,
                                snippets: hit._snippetResult
                                /* eslint-enable @typescript-eslint/no-explicit-any */
                            }) as AlgoliaSearchResult
                    )
                );
            })
            .catch((error) => {
                reject(error);
            });
    });
};
/* eslint-enable @typescript-eslint/no-explicit-any */
