import { IconSearch } from "@dev/zenith/dist/icons/iconSearch";
import SearchModal from "../SearchModal/SearchModal";
import { HeaderProps } from "./Header";
import { Button, SelectField } from "@dev/zenith";
import { useContext, useState } from "react";
import { LogoGeotabSDK } from "../Logo/LogoGeotabSDK";
import { ButtonType } from "@dev/zenith/dist/button/buttonType";
import { ISelectListItem } from "@dev/zenith/dist/selectList/selectListItem";
import { HeaderSections } from "./headerSectionsEnum";
import "./header.scss";
import { useNavigate } from "react-router-dom";
import MenuContext from "../../menuContext";

const headerSectionItems: ISelectListItem[] = [
    {
        id: HeaderSections.MyGeotab,
        children: "MyGeotab"
    },
    {
        id: HeaderSections.MyAdmin,
        children: "MyAdmin"
    },
    {
        id: HeaderSections.Hardware,
        children: "Hardware"
    },
    {
        id: HeaderSections.Drive,
        children: "Drive"
    }
];

export default function HeaderMobile({ isLandingPage }: HeaderProps): JSX.Element {
    const [isSearchModalOpen, setSearchModalOpen] = useState<boolean>(false);
    const { activeSiteSection, setActiveSiteSection } = useContext(MenuContext);
    const navigate = useNavigate();

    const openSearchModal = (): void => {
        setSearchModalOpen(true);
    };

    const closeSearchModal = (): void => {
        setSearchModalOpen(false);
    };

    const handleSectionChange = (val: string | undefined): void => {
        switch (val) {
            case HeaderSections.MyGeotab:
                setActiveSiteSection(HeaderSections.MyGeotab);
                navigate("/myGeotab/introduction");
                break;
            case HeaderSections.MyAdmin:
                setActiveSiteSection(HeaderSections.MyAdmin);
                navigate("/myAdmin/introduction");
                break;
            case HeaderSections.Hardware:
                setActiveSiteSection(HeaderSections.Hardware);
                navigate("/hardware/introduction");
                break;
            case HeaderSections.Drive:
                setActiveSiteSection(HeaderSections.Drive);
                navigate("/drive/introduction");
                break;
            default:
                break;
        }
    };

    const headerClass = isLandingPage ? "header-container landing-page-border" : "header-container";

    return (
        <div>
            <header className={headerClass}>
                {isLandingPage &&
                    <div className="header-container__left">
                        <LogoGeotabSDK className="geotab-sdk-logo" />
                    </div>
                }
                <div className="header-container__menu-select">
                    {!isLandingPage &&
                        <SelectField
                            title="Site Menu"
                            items={headerSectionItems}
                            value={activeSiteSection}
                            onChange={handleSectionChange}
                        />}
                </div>
                <div className="header__container__right">
                    <Button onClick={openSearchModal} type={ButtonType.Secondary} title="Search" aria-label="Search" className="header-search-button">
                        <IconSearch />
                    </Button>
                </div>
            </header>
            <SearchModal isOpen={isSearchModalOpen} onClose={closeSearchModal} />
        </div>
    );
}