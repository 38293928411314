import { useState } from "react";
import CodeSample from "./CodeSample";
import { TabBar } from "@dev/zenith";
import { ITab } from "@dev/zenith/dist/tabBar/tabBar";

export interface CodeSamples {
    javascript?: string;
    csharp?: string;
    java?: string;
    python?: string;
}

const languages: Record<string, string> = {
    javascript: "JavaScript",
    csharp: "C#",
    java: "Java",
    python: "Python"
};

/*Renders a component that displays code Examples in different programming languages.

@param { CodeSamples } props - An object containing code examples for various programming languages.
Example usage:
const samples: CodeSamples = {
 javascript: `// Your JavaScript code here`,
 csharp: `// Your C# code here`,
 java: `// Your Java code here`,
 python: `## Your Python code here`
};
<CodeSamplesContainer {...samples}></CodeSamplesContainer>
};*/

export default function CodeSamplesContainer(props: CodeSamples): JSX.Element {
    const [activeTabId, setActiveTabId] = useState<string>("0");

    const handleTabChange = (tabId: string): void => {
        setActiveTabId(tabId);
    };

    let tabsArray: ITab[] = Object.keys(props).map((language, index) => ({
        id: index.toString(),
        content: <CodeSample language={language} code={props[language as keyof CodeSamples]!}></CodeSample>,
        name: languages[language as keyof CodeSamples]
    }));

    return <TabBar tabs={tabsArray} activeTabId={activeTabId} onTabChange={handleTabChange} />;
}
