import { IconClipboard, IconServer } from "@geotab/react-component-library";
import { MatchInfo } from "minisearch";
import { Link, LinkProps } from "react-router-dom";
import { pullText, findHeaderId, getID, ISearchResult } from "./utils/searchUtils";
import { HashLink, HashLinkProps } from "react-router-hash-link";
import BreadCrumbTrail from "../BreadCrumbTrail/BreadCrumbTrail";
import { ForwardRefExoticComponent, RefAttributes, useContext } from "react";
import { ErrorHandlerContext } from "../Error/ErrorBoundary";
import { searchIndex } from "../../utils/searchIndex";
import { instanceOfAlgoliaSearchResult } from "./utils/algoliaUtils";
import "./searchModal.scss";

type LinkType = ForwardRefExoticComponent<LinkProps & RefAttributes<HTMLAnchorElement>> | ForwardRefExoticComponent<HashLinkProps & RefAttributes<HTMLAnchorElement>>;
interface LinkInfo {
    path: string;
    linkType: LinkType;
}

interface ResultsListProps {
    searchResults: ISearchResult[];
    onClose: () => void;
}

const highlightMatch = (text: string, matchTerms: MatchInfo): JSX.Element[] => {
    //search can hit on multiple different words so we add all of them to the regex
    //we need to highlight based on matches instead of user input as fuzzy search allows for typos/partial matches
    const expressionString: string = Object.keys(matchTerms).toString().replace(",", "|");
    const regex = new RegExp(`(${expressionString})`, "gi");
    return text.split(regex).map((part, index) =>
        regex.test(part) ? (
            <span className="search-result-match" key={index}>
                {part}
            </span>
        ) : (
            <span key={index}>{part}</span>
        )
    );
};

const highlightAlgoliaText = (text: string): JSX.Element[] => {
    const regex = new RegExp("(<em>[A-Za-z]*</em>)", "g");
    return text.split(regex).map((part, index) =>
        regex.test(part) ? (
            <span className="search-result-match" key={index}>
                {part.replace(new RegExp("<em>|</em>", "g"), "")}
            </span>
        ) : (
            <span key={index}>{part}</span>
        )
    );
};

let onClick = (closeCallback: () => void, resetError: () => void, event: React.MouseEvent<HTMLAnchorElement>): void => {
    resetError();

    //allows for users to open results in a new tab without closing: ctrl + t or cmd + t or middle mouse button click
    if (event.ctrlKey || event.shiftKey || event.metaKey || event.button === 1) {
        return;
    }

    closeCallback();
};

const buildLinkInfo = (result: ISearchResult): LinkInfo => {
    let headerId: string | null;
    if (instanceOfAlgoliaSearchResult(result)) {
        headerId = result.headerIds.length === 1 ? result.headerIds[0] : null;
    } else {
        headerId = findHeaderId(result.id, result.match, searchIndex);
    }

    return {
        path: headerId === null ? result.link : `${result.link as string}#${headerId}`,
        linkType: headerId === null ? Link : HashLink
    };
};

export default function ResultsList(props: ResultsListProps): JSX.Element {
    const { resetError } = useContext(ErrorHandlerContext);
    return (
        <div className="search-results-container">
            <ul>
                {props.searchResults.map((item: ISearchResult) => {
                    const linkInfo: LinkInfo = buildLinkInfo(item);
                    const ListItemLink: LinkType = linkInfo.linkType;

                    return (
                        <ListItemLink
                            to={linkInfo.path}
                            key={`search-${getID(item)}`}
                            onClick={function (event: React.MouseEvent<HTMLAnchorElement>) {
                                onClick(props.onClose, resetError, event);
                            }}
                            className="search-result-link"
                        >
                            <li key={`search-${getID(item) as unknown as string}`}>
                                <div className="result-listing-header-container">
                                    <div className={item.category === "guide" ? "guide-icon" : ""}>{item.category === "guide" ? <IconClipboard /> : <IconServer />}</div>
                                    <div className={item.category === "guide" ? "result-item-title guide-title" : "result-item-title"}>
                                        <span>{instanceOfAlgoliaSearchResult(item) ? highlightAlgoliaText(item.snippets.title.value) : highlightMatch(item.title, item.match)}</span>
                                        <BreadCrumbTrail crumbs={item.breadCrumb} />
                                    </div>
                                </div>
                                <div className="search-results-snippet">
                                    {instanceOfAlgoliaSearchResult(item)
                                        ? highlightAlgoliaText(item.snippets.content.value)
                                        : highlightMatch(pullText(getID(item), item.terms[0], searchIndex), item.match)}
                                </div>
                            </li>
                        </ListItemLink>
                    );
                })}
            </ul>
        </div>
    );
}
