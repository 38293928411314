import { Pill } from "@dev/zenith";
import { IconBeta } from "@dev/zenith/dist/icons/iconBeta";
import "./betaPill.scss";

export default function BetaPill(): JSX.Element {
    return (
        <Pill icon={IconBeta} type={"info"} className="beta-pill">
            Beta
        </Pill>
    );
}
