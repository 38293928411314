/* eslint-disable max-len */
import * as React from "react";
import { LogoProps } from "@geotab/react-component-library";

export const IconDocument = React.forwardRef<SVGSVGElement, LogoProps>(({ color = "currentColor", ...props }, forwardedRef) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} ref={forwardedRef}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.33301 2.49984C3.33301 2.0396 3.7061 1.6665 4.16634 1.6665H15.833C16.2932 1.6665 16.6663 2.0396 16.6663 2.49984V17.4998C16.6663 17.9601 16.2932 18.3332 15.833 18.3332H4.16634C3.7061 18.3332 3.33301 17.9601 3.33301 17.4998V2.49984ZM4.99967 3.33317V16.6665H14.9997V3.33317H4.99967ZM6.66634 5.83317C6.66634 5.37293 7.03944 4.99984 7.49967 4.99984H12.4997C12.9599 4.99984 13.333 5.37293 13.333 5.83317C13.333 6.29341 12.9599 6.6665 12.4997 6.6665H7.49967C7.03944 6.6665 6.66634 6.29341 6.66634 5.83317ZM6.66634 9.1665C6.66634 8.70627 7.03944 8.33317 7.49967 8.33317H12.4997C12.9599 8.33317 13.333 8.70627 13.333 9.1665C13.333 9.62674 12.9599 9.99984 12.4997 9.99984H7.49967C7.03944 9.99984 6.66634 9.62674 6.66634 9.1665ZM6.66634 12.4998C6.66634 12.0396 7.03944 11.6665 7.49967 11.6665H9.16634C9.62658 11.6665 9.99967 12.0396 9.99967 12.4998C9.99967 12.9601 9.62658 13.3332 9.16634 13.3332H7.49967C7.03944 13.3332 6.66634 12.9601 6.66634 12.4998Z"
            fill={color}
        />
    </svg>
));

IconDocument.displayName = "IconDocument";
