import SearchNotFound from "./SearchNotFound";
import SearchPrompt from "./SearchPrompt";
import ResultsList from "./ResultsList";
import { SearchSections } from "./searchSectionsEnum";
import { ISearchResult } from "./utils/searchUtils";
import "./searchModal.scss";

interface TabContentProps {
    inputValue: string;
    tab: string;
    searchResults: ISearchResult[];
    onClose: () => void;
}

const resultSwitch = (tab: string, results: ISearchResult[]): ISearchResult[] => {
    switch (tab) {
        case SearchSections.APIReference:
            return results.filter((result) => result.category === "reference");
        case SearchSections.Guides:
            return results.filter((result) => result.category === "guide");
        case SearchSections.All:
        default:
            return results;
    }
};

const showContent = (inputString: string, results: ISearchResult[], closeCallback: () => void): JSX.Element => {
    if (inputString === "") {
        return <SearchPrompt />;
    } else if (results.length === 0) {
        return <SearchNotFound searchString={inputString} />;
    } else {
        return <ResultsList searchResults={results} onClose={closeCallback} />;
    }
};

export default function SearchResultTabContent(props: TabContentProps): JSX.Element {
    let filteredResults: ISearchResult[] = resultSwitch(props.tab, props.searchResults);
    return (
        <div className={props.searchResults.length > 0 && props.inputValue !== "" ? "tab-container search-results-container" : "tab-container"}>
            {showContent(props.inputValue, filteredResults, props.onClose)}
        </div>
    );
}
