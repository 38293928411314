import { Button } from "@dev/zenith";
import { ButtonType } from "@dev/zenith/dist/button/buttonType";
import { GraphicSearchNotFound } from "../SearchModal/assets/GraphicSearchNotFound";
import { Header } from "..";
import "../../../src/pages/pages.scss";
import { useContext } from "react";
import { ErrorHandlerContext } from "./ErrorBoundary";

export default function UnexpectedErrorPage() {
    const { resetError } = useContext(ErrorHandlerContext);

    const handleRefresh = () => {
        resetError();
        window.location.reload();
    };

    return (
        <div className="error-page__main-container">
            <Header isLandingPage={true}/>
            <div className="error-page__content-container">
                <h1 className="title">Oops, something went wrong</h1>
                <Button type={ButtonType.Primary} onClick={handleRefresh} title="Refresh the page" aria-label="Refresh the page">Refresh the page</Button>
                <GraphicSearchNotFound width={740} height={440} />
            </div>
        </div>
    );
}
