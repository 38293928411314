import { ReactNode, useEffect, useState } from "react";
import { Page } from "../../../components";
import CodeSample from "../../../components/CodeSamplesContainer/CodeSample";
import { PageTitleProps } from "../../../components/PageTitle/PageTitle";
import { HeaderSections } from "../../../components/Header/headerSectionsEnum";
import { TableOfContentsItem } from "../../../components/TableOfContents/TableOfContents";
import "../../../pages/pages.scss";
import { Link } from "react-router-dom";
import { referenceDataPromise } from "../../../components/ApiReference/ApiReference";
import { RateLimit } from "../../../components/ApiReference/utils/apiReferenceUtils";
import { pullRateLimitInfo } from "../../../components/ApiReference/utils/parserUtils";
import { Waiting } from "@dev/zenith";
import InformationalBox from "../../../components/InformationalBox/InformationalBox";

export interface AllRateLimits {
    standardRateLimits: { [key: string]: RateLimit[] };
    nonStandardRateLimits: { [key: string]: RateLimit[] };
}

const understandingBaseRateLimits: ReactNode = (
    <div className="paragraph">
        <p>
            You can see the details of the specific rate limits for each Method and Entity on the Geotab SDK site <Link to="/myGeotab/apiReference/methods">here</Link>.
        </p>
        <p>
            While limits are in “Coming Soon”, the X-Rate-Limit-Limit, X-Rate-Limit-Remaining, X-Rate-Limit-Reset headers are set even though the server will not limit the request. This allows any
            consumer to observe the headers ahead of the limits being rolled out.
        </p>
        <p>
            If using the .NET Geotab.Checkmate.Objectmodel nuget package, there is no access to the headers of the request. To facilitate testing of how your integration works with the new limits, a
            new optional parameter has been added to the API.cs constructor. Constructing with the optional ApiClientConfiguration with EnableRateLimitObservation set to true and run your
            integration The nuget package will throw an OverLimitException from the library when the returned result is over a given limit even though the server is not limiting the request.
        </p>
        <p>This will give integrators the opportunity to test how their interactions will react before the limits are strictly imposed by the API.</p>
        <h2>OverLimitException</h2>
        <p>
            When a rate limit is exceeded, an OverLimitException error is returned. A header (<code>Retry-After</code>) is also set with time remaining for the limit to reset.
        </p>
        <h2>Header example</h2>
        <code>Retry-After: 58</code>
        <CodeSample
            language="json"
            code={`{
    "error": {
        "message": "API calls quota exceeded. Maximum admitted 10 per 1m.",
        "code": -32000,
        "data": {
            "id": "b83dc64f-3976-4b35-8e32-c55b3a4adc2f",
            "type": "OverLimitException"
        }
    },
    "jsonrpc": "2.0"
}
`}
        />
        <h2>Headers</h2>
        <p>
            If a rate limit is applied to an API, with a <strong>successful</strong> JSON-RPC response, headers are set with rate limit information:
        </p>
        <ul>
            <li>
                <code>X-Rate-Limit-Limit:</code> the rate limit period (eg. 1s, 1m, 12h, 1d)
            </li>
            <li>
                <code>X-Rate-Limit-Remaining:</code> number of request remaining
            </li>
            <li>
                <code>X-Rate-Limit-Reset:</code> UTC date time (ISO 8601) when the limit resets
            </li>
        </ul>
    </div>
);

const understandingTiers: ReactNode = (
    <div className="paragraph">
        <p>
            Tiers refers to the number of <strong>tracked assets</strong> managed by a database. This includes only devices that have hardware reporting active data into MyGeotab including the Go
            Device, Go Anywhere, and Custom Telematics devices.
        </p>
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th colSpan={2}>Tracked Assets on a Database</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th>Tier</th>
                        <th>Lower Bound</th>
                        <th>Upper Bound</th>
                        <th>Multiplier</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>0</td>
                        <td>1,000</td>
                        <td>x1</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>1,001</td>
                        <td>10,000</td>
                        <td>x5</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>10,001</td>
                        <td>25,000</td>
                        <td>x15</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>25,001</td>
                        <td>&infin;</td>
                        <td>x25</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p>
            The following public endpoints are <strong>excluded</strong> from tiered rate limits, and will always have a fixed base limit:
        </p>
        <ul>
            <li>Authenticate</li>
            <li>CreateDatabase</li>
            <li>SetUserPassword</li>
            <li>DeviceShare</li>
        </ul>
    </div>
);

const avoidingAPIRateLimits: ReactNode = (
    <div className="paragraph">
        <h2>Use the MyGeotab API Adapter</h2>
        <p>
            The MyGeotab API Adapter solution serves as both an example of proper integration via data feeds and the potential foundation for those seeking to develop new integrations with the
            Geotab platform. Essentially, it uses data feeds to pull the most common data sets from a MyGeotab database and stream the data into tables within a SQL Server, PostgreSQL or Oracle
            database.
        </p>
        <p>
            Read more about it{" "}
            <a href="https://support.geotab.com/en-GB/mygeotab/doc/api-adapter" target="_blank" rel="noopener noreferrer">
                here
            </a>
            .
        </p>
        <h2>Get vs GetFeed</h2>
        <p>
            It is preferred to use <Link to="/myGeotab/apiReference/methods/GetFeed">GetFeed</Link> instead of a Get call for an entity which supports it.
        </p>
        <h2>Wait logic between calls</h2>
        <p>
            A straightforward solution can be introducing wait times between subsequent calls. It is suggested that ensuring at least <b>1010 milliseconds</b> has elapsed between calls.
        </p>
    </div>
);

const handlingRateLimits: ReactNode = (
    <div className="paragraph">
        <p>
            The general strategy for handling rate limits requires the integrator or partner to implement an error handler in code that recognizes rate limit errors and pauses requests until the
            limit resets. This will prevent your app from continuously trying to make requests once the rate limit is hit.
        </p>
        <h2>Using exponential backoff</h2>
        <p>
            Exponential Backoff is an essential error-handling strategy in network applications. In this method, a client will retry a failed request after waiting for an increasing amount of time
            before each retry.
        </p>
        <p>
            When a request fails due to rate limiting, the client waits for a predetermined period before retrying the request. If the request fails again, the client waits longer for the next
            attempt, and this process continues up to a maximum delay limit. This approach helps to prevent frequent hits to the rate limit and reduces the load on the server.
        </p>
        <h2>JavaScript example implementation</h2>
        <p>Below is an example of how you might currently be making API calls:</p>
        <CodeSample
            language="JavaScript"
            code={`function getDevices(api) {
    return new Promise((resolve, reject) => {
        api.call("Get", {
            typeName: "Device",
            "resultsLimit": 10
        }, result => {
            resolve(result);
        }, error => {
            reject(error);
        });
    });
}   
`}
        />
        <p>To handle rate limits effectively, you can wrap your base function with exponential backoff logic. The following steps outline this process:</p>
        <ol>
            <li>Set the Maximum Number of Attempts</li>
            <li>Calculate the Exponential Delay</li>
            <li>Try the Call</li>
            <li>Catch the OverLimitException</li>
            <li>Wait for the Delay</li>
            <li>Repeat steps 1-5 until either the maximum number of attempts is reached, any other error is thrown, or results are successfully returned.</li>
        </ol>
        <p>Here is an example implementation:</p>
        <CodeSample
            language="JavaScript"
            code={`async function retryGetDevices(api, attempt = 1) {
    const maxAttempts = 5; // Maximum number of attempts
    const delay = (ms) => new Promise(res => setTimeout(res, ms));
    const exponentialDelay = Math.pow(2, attempt) * 1000; // Exponential delay in milliseconds
            
    try {
        return await getDevices(api);
    } catch (error) {
        if (error && error.data.type === "OverLimitException") {
            if (attempt < maxAttempts) {
                await delay(exponentialDelay);
                return retryGetDevices(api, attempt + 1);
            } else {
                throw new Error("Max retry attempts after OverLimitException reached.");
            }
        } else {
            throw error; // Re-throw the error for non-OverLimitException cases
        }
    }
}          
`}
        />
        <p>This function could be used within a try-catch block to handle any resultant errors:</p>
        <CodeSample
            language="JavaScript"
            code={`// Async function to fetch device data with retry mechanism
async function fetchDeviceData() {
    const api = initializeGeotabApi(credentials); // Assuming authentication method is configured
            
    try {
        const deviceData = await retryGetDevices(api);
        // ... Process the data
    } catch (error) {
        // ... Handle the error
    }
}      
`}
        />
    </div>
);

const pageTitle: PageTitleProps = {
    title: "Working with Rate Limits",
    breadCrumbItems: ["MYG", "Guides", "Working With Rate Limits"],
    isBeta: true
};

export const pageSections: TableOfContentsItem[] = [
    {
        elementId: "understanding-base-rate-limits",
        summary: "Understanding the base rate limits",
        details: understandingBaseRateLimits
    },
    {
        elementId: "understanding-tiers",
        summary: "Understanding tiers",
        details: understandingTiers
    },
    {
        elementId: "avoiding-api-rate-limits",
        summary: "Avoiding API rate limits",
        details: avoidingAPIRateLimits
    },
    {
        elementId: "handling-rate-limits",
        summary: "Handling rate limits",
        details: handlingRateLimits
    },
    {
        elementId: "rate-limit-rules-get",
        summary: "Rate limit rules: Get",
        details: <></>
    },
    {
        elementId: "rate-limit-rules-getcountof",
        summary: "Rate limit rules: GetCountOf",
        details: <></>
    },
    {
        elementId: "rate-limit-rules-set",
        summary: "Rate limit rules: Set",
        details: <></>
    },
    {
        elementId: "rate-limit-rules-add",
        summary: "Rate limit rules: Add",
        details: <></>
    },
    {
        elementId: "rate-limit-rules-remove",
        summary: "Rate limit rules: Remove",
        details: <></>
    },
    {
        elementId: "rate-limit-rules-getfeed",
        summary: "Rate limit rules: GetFeed",
        details: <></>
    },
    {
        elementId: "rate-limit-rules-web-methods",
        summary: "Rate limit rules: Web methods",
        details: <></>
    }
];

export let basicLimitsByMethodNames: { [key: string]: RateLimit[] } = {
    "Get": [],
    "GetFeed": [],
    "Add": [],
    "Set": [],
    "GetCountOf": [],
    "Remove": []
};

export const buildStandardMethodTables = (documentation: { [key: string]: RateLimit[] }): void => {
    Object.keys(basicLimitsByMethodNames).forEach(method => {
        basicLimitsByMethodNames[method] = [];
    });
    //move the dictionary around to have the method as the key instead of the entity type
    Object.entries(documentation).forEach(([entityName, limitArray]) => {
        limitArray.forEach(limitRule => {
            basicLimitsByMethodNames[limitRule.name].push(
                {
                    name: entityName,
                    limit: limitRule.limit,
                    period: limitRule.period,
                    status: limitRule.status,
                    description: limitRule.description
                }
            );
        });
    });

    Object.entries(basicLimitsByMethodNames).forEach(([methodName, limitArray]) => {
        pageSections.find(section => section.elementId === `rate-limit-rules-${methodName.toLowerCase()}`)!.details =
            (
                <div className="paragraph" key={`${methodName}-limit`}>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Entity</th>
                                    <th>Limit</th>
                                    <th>Period</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            {buildRateLimitTableBody(limitArray)}
                        </table>
                    </div>
                </div>
            );
    });
};

export const buildWebMethodsTable = (documentation: { [key: string]: RateLimit[] }): void => {
    let tableContents: RateLimit[] = [];
    Object.entries(documentation).forEach(([methodName, limitArray]) => {
        limitArray.forEach(limitRule => {
            if (!(methodName in basicLimitsByMethodNames)) {
                tableContents.push(
                    {
                        name: methodName,
                        limit: limitRule.limit,
                        period: limitRule.period,
                        status: limitRule.status,
                        description: limitRule.description
                    }
                );
            }
        });
    });

    pageSections.find(section => section.elementId === "rate-limit-rules-web-methods")!.details =
        (
            <div className="paragraph">
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Method</th>
                                <th>Limit</th>
                                <th>Period</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        {buildRateLimitTableBody(tableContents)}
                    </table>
                </div>
            </div>
        );
};

export const buildRateLimitTableBody = (limitRules: RateLimit[]): ReactNode =>
    <tbody>
        {limitRules.map((rule, key) =>
            <tr key={`${rule.name}-row-${key}`}>
                <td>{rule.name}</td>
                <td>{rule.limit}</td>
                <td>{rule.period}</td>
                <td>{rule.status}</td>
            </tr>
        )}
    </tbody>;

export const showErrorMessages = (): void => {
    pageSections.filter(section => section.elementId.includes("rate-limit-rules")).forEach(section => {
        section.details = <InformationalBox>
            There was an error displaying this information. Please check again later.
        </InformationalBox>;
    });
};

export default function RateLimits() {
    const [allRateLimits, setAllRateLimits] = useState<AllRateLimits>({ standardRateLimits: {}, nonStandardRateLimits: {} });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        referenceDataPromise
            .then((parsedEntries) => {
                setAllRateLimits(pullRateLimitInfo(parsedEntries.myGMethods, parsedEntries.myGObjects));
                setHasError(false);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("An error occurred while pulling rate limit info: ", error);
                setHasError(true);
                setIsLoading(false);
            });
    }, [isLoading, hasError]); //DO NOT add the allRateLimits state here, it will trigger an infinite render loop

    if (!isLoading) {
        if (hasError) {
            showErrorMessages();
        }
        else {
            buildStandardMethodTables(allRateLimits.standardRateLimits);
            buildWebMethodsTable(allRateLimits.nonStandardRateLimits);
        }
    }

    return (
        <Page section={HeaderSections.MyGeotab} pageTitle={pageTitle} tableOfContents={pageSections}>
            <div className="paragraph">
                <p>
                    As part of Geotab's ongoing commitment to service scalability and reliability, we are implementing a MyGeotab SDK update which will ensure future use of new or existing APIs results in minimized performance risk and improves overall API call efficiency.
                </p>
                <p>
                    Geotab will implement new API rate limits on MyGeotab, which will affect customers using the MyGeotab API. <b>These API rate limits will apply to each unique combination of method, entity, username, and database.</b> One User-Agent’s rate limiting will not affect another.
                </p>
                <p>
                    If you are an integrator or partner with an active integration it is <b>extremely important</b> that you review your code to ensure that you are aligned with Geotab’s best practice.
                </p>
            </div>
            {isLoading && <Waiting isLoading />}
        </Page>
    );
}
