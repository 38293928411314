import { Link } from "react-router-dom";
import { FooterDivider, LogoGeotabFooter } from "../Footer";
import "./footer.scss";

export interface FooterLink {
    href: string;
    label: string;
    isExternalLink: boolean;
    onClick?: () => void;
}

export const footerLinks: FooterLink[] = [
    {
        href: "https://www.geotab.com/security",
        label: "Security",
        isExternalLink: true
    },
    {
        href: "https://docs.google.com/document/d/1sVygLN02w2xNovFY4q5vw-oAzfYxCd7WLhyToElgDbs/pub",
        label: "Privacy Policy",
        isExternalLink: true
    },
    {
        href: "https://docs.google.com/document/d/1aFJnFYpHCN4bFe6yVhj5m5AiJClfucRhJWVSDBqRObg/edit?usp=sharing",
        label: "Terms of Service",
        isExternalLink: true
    },
    {
        href: "/myGeotab/addIns/addInStorage",
        label: "Storage Preferences",
        isExternalLink: false
    },
    {
        href: "#",
        label: "Cookie Preferences",
        isExternalLink: false,
        onClick: () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: Ignore error for global Osano object, Osano is being imported within public/index.html
            Osano.cm.showDrawer("osano-cm-dom-info-dialog-open");
        }
    }
];

export default function Footer(): JSX.Element {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-container">
            <div className="footer-container__left">
                <LogoGeotabFooter />
                <div className="link-main-container">
                    {footerLinks.map((link, index) => (
                        <div key={link.label} className="link-container">
                            <Link to={link.href} {...(link.isExternalLink ? { target: "_blank", rel: "noopener noreferrer" } : {})} onClick={link.onClick}>
                                {link.label}
                            </Link>
                            <span className="footer-link-divider">{index < footerLinks.length - 1 && <FooterDivider />}</span>
                        </div>
                    ))}
                </div>
            </div>

            <div className="footer-container__right">
                <small className="footer-copyright">Copyright © {currentYear} Geotab</small>
            </div>
        </footer>
    );
}
