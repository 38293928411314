import { IconGeotabDrive, IconGrid, IconMarketplace, IconMyGShort, IconQuestionSupport, SideNavigationMenuItemType } from "@geotab/react-component-library";
import { IconCodeTerminal, IconMyAShort } from "../icons";

export const TERTIARY_NAVBAR_ITEMS: SideNavigationMenuItemType[] = [
    {
        label: "API Runner",
        // TODO: UPDATE THE URL IF NEEDED FOR THE API RUNNER WHEN THE NEW SITE IS LIVE
        externalRoute: "https://geotab.github.io/sdk/software/api/runner.html",
        level: 0,
        order: 0,
        userTypes: [],
        roles: [],
        Icon: IconCodeTerminal
    },
    {
        label: "Geotab Apps",
        level: 0,
        order: 1,
        userTypes: [],
        roles: [],
        Icon: IconGrid,
        children: [
            {
                label: "MyGeotab",
                externalRoute: "https://my.geotab.com/",
                level: 1,
                order: 0,
                userTypes: [],
                roles: [],
                Icon: IconMyGShort
            },
            {
                label: "MyAdmin",
                externalRoute: "https://myadmin.geotab.com/",
                level: 1,
                order: 1,
                userTypes: [],
                roles: [],
                Icon: IconMyAShort
            },
            {
                label: "Drive App",
                externalRoute: "https://my.geotab.com/drive/",
                level: 1,
                order: 2,
                userTypes: [],
                roles: [],
                Icon: IconGeotabDrive
            },
            {
                label: "Marketplace",
                externalRoute: "https://marketplace.geotab.com/",
                level: 1,
                order: 3,
                userTypes: [],
                roles: [],
                Icon: IconMarketplace
            },
            {
                label: "Geotab.com",
                externalRoute: "https://www.geotab.com/",
                level: 1,
                order: 4,
                userTypes: [],
                roles: []
            }
        ]
    },
    {
        label: "Help & Support",
        level: 0,
        order: 2,
        userTypes: [],
        roles: [],
        Icon: IconQuestionSupport,
        children: [
            {
                label: "Integrators Hub",
                externalRoute: "https://community.geotab.com/s/integrators-hub",
                level: 1,
                order: 0,
                userTypes: [],
                roles: []
            },
            {
                label: "Developer Discussions",
                externalRoute: "https://community.geotab.com/s/developers-hub",
                level: 1,
                order: 1,
                userTypes: [],
                roles: []
            },
            {
                label: "Geotab Community",
                externalRoute: "https://community.geotab.com/",
                level: 1,
                order: 2,
                userTypes: [],
                roles: []
            },
            {
                label: "Toolkits",
                route: "/helpAndSupport/toolkits",
                level: 1,
                order: 3,
                userTypes: [],
                roles: []
            }
        ]
    }
];
