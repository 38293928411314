import { SideNavigationMenuItemType } from "@geotab/react-component-library";
import { IconCircleInfo, IconDocumentWithMagnifyingGlass, IconPuzzlePiece, IconCodeBox, IconDocument } from "../icons";

export const DRIVE_NAVBAR_ITEMS: SideNavigationMenuItemType[] = [
    {
        label: "Introduction",
        route: "/drive/introduction",
        level: 0,
        order: 0,
        userTypes: [],
        roles: [],
        Icon: IconCircleInfo
    },
    {
        label: "Developing Add-Ins",
        route: "/drive/addIns/developingAddins",
        level: 0,
        order: 1,
        userTypes: [],
        roles: [],
        Icon: IconPuzzlePiece
    },
    {
        label: "API Reference",
        route: "/drive/apiReference",
        level: 0,
        order: 2,
        userTypes: [],
        roles: [],
        Icon: IconDocumentWithMagnifyingGlass
    },
    {
        label: "Mobile SDK",
        route: "/drive/mobileSdk",
        level: 0,
        order: 3,
        userTypes: [],
        roles: [],
        Icon: IconDocument
    },
    {
        label: "Examples",
        route: "/drive/examples",
        level: 0,
        order: 4,
        userTypes: [],
        roles: [],
        Icon: IconCodeBox
    }
];
