import { MatchInfo, SearchResult } from "minisearch";
import { AlgoliaSection, MiniSearchPage } from "../../../utils/indexInterfaces.js";
import { ReferenceSections } from "../../ApiReference/referenceSectionsEnum";
import { removeHtmlAndSpecificTags } from "../../../utils/searchIndexUtils";
import { ApiMethodParameter } from "../../ApiReference/ApiMethod";
import { ApiObjectProperty } from "../../ApiReference/ApiObject";
import { AlgoliaSearchResult } from "./algoliaUtils.js";

const EXCERPT_CHAR_LENGTH: number = 200;
let currentIDNumber: number = 0;

interface ReferenceItem {
    description: string;
    parameters?: ApiMethodParameter[];
    returns?: string;
    example?: string;
    isBeta?: boolean;
    properties?: ApiObjectProperty[];
    remarks?: string;
}

export type ISearchResult = SearchResult | AlgoliaSearchResult;
export type ISearchDocument = MiniSearchPage | AlgoliaSection;

export const getID = (document: ISearchDocument | ISearchResult): number => ("id" in document ? document.id : document.objectID);

const setID = (document: ISearchDocument, id: number): void => {
    if ("id" in document) {
        document.id = id;
    } else {
        document.objectID = id;
    }
};

export const findStartingStringIndex = (numCharsAhead: number, numCharsAfter: number, remainingLength: number, foundIndex: number): number => {
    let half: number = Math.floor(remainingLength / 2);
    if (numCharsAhead < half) {
        return 0;
    } else if (numCharsAfter < half) {
        return foundIndex - remainingLength + numCharsAfter;
    } else {
        return foundIndex - half;
    }
};

export const pullText = (searchId: number, matchTerm: string, index: ISearchDocument[]): string => {
    let i: number = index.findIndex((document: ISearchDocument) => getID(document) === searchId);
    let contentString: string = index[i].content;

    let stringIndex: number = contentString.indexOf(matchTerm);
    if (stringIndex >= 0) {
        let excerpt: string = "";
        let numRemainingChars: number = EXCERPT_CHAR_LENGTH - matchTerm.length;
        let numCharsAhead: number = contentString.substring(0, stringIndex).length;
        let numCharsAfter: number = contentString.substring(stringIndex + matchTerm.length).length;
        if (numCharsAhead + numCharsAfter <= numRemainingChars) {
            return contentString;
        }
        let excerptStartingIndex: number = findStartingStringIndex(numCharsAhead, numCharsAfter, numRemainingChars, stringIndex);
        excerpt += contentString.substring(excerptStartingIndex, stringIndex);
        numRemainingChars -= excerpt.length;
        if (excerptStartingIndex > 0) {
            excerpt = "..." + excerpt;
        }
        excerpt += matchTerm;
        excerpt += contentString.substring(stringIndex + matchTerm.length, stringIndex + matchTerm.length + numRemainingChars);
        if (excerpt.length - excerpt.indexOf(matchTerm) - matchTerm.length !== numCharsAfter) {
            excerpt += "...";
        }
        return excerpt;
    }
    //if there's no match in the page content, return the first portion of the page
    return contentString.substring(0, EXCERPT_CHAR_LENGTH) + "...";
};

export const findHeaderId = (searchId: number, matchTerms: MatchInfo, index: ISearchDocument[]): string | null => {
    let isHeaderLink = false;
    let headerMatch = "";
    for (const term in matchTerms) {
        if (matchTerms[term].includes("headers")) {
            isHeaderLink = true;
            headerMatch = term;
            break;
        }
    }
    if (isHeaderLink) {
        let i: number = index.findIndex((document: ISearchDocument) => getID(document) === searchId);
        let pageHeaders: string[] = index[i].headers;
        let headerIndex = pageHeaders.findIndex((header) => header.toLowerCase().includes(headerMatch));
        return index[i].headerIds[headerIndex];
    }
    return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addParsedResultsToSearchIndex = (json: { [key: string]: ReferenceItem }, referenceSection: ReferenceSections, index: any, search: string): void => {
    Object.keys(json).forEach((key) => {
        let entry = json[key];
        let itemType: string = "parameters" in entry ? "Methods" : "Objects";
        let linkBase: string = referenceSection === ReferenceSections.MyGeotab ? "/myGeotab" : "/myAdmin";
        let link: string = `${linkBase}/apiReference/${itemType.toLowerCase()}/${key}`;
        if (index.findIndex((document: ISearchDocument) => document.link === link) < 0) {
            let searchDocument: ISearchDocument;
            searchDocument =
                search === "minisearch"
                    ? { id: 0, title: "", headers: [], headerIds: [], content: "", link: "", breadCrumb: [], category: "" }
                    : { objectID: 0, title: "", headers: [], headerIds: [], content: "", link: "", breadCrumb: [], category: "" };
            let currentId: number = getID(index[index.length - 1]);
            setID(searchDocument, currentId + 1);
            searchDocument.title = key;
            searchDocument.headers = ["Introduction", "Parameters", "Return value", "Code samples"];
            searchDocument.headerIds = ["introduction", "parameters", "return value", "code samples"];
            searchDocument.link = link;
            searchDocument.breadCrumb = [referenceSection, "API Reference", itemType, key];
            searchDocument.content = removeHtmlAndSpecificTags(entry.description);
            if (itemType === "Methods") {
                searchDocument.breadCrumb[searchDocument.breadCrumb.length - 1] += " (...)";
            }
            searchDocument.category = "reference";
            if (entry.parameters) {
                searchDocument.content += removeHtmlAndSpecificTags(JSON.stringify(entry.parameters));
            }
            if (entry.properties) {
                searchDocument.content += removeHtmlAndSpecificTags(JSON.stringify(entry.properties));
            }
            if (entry.returns) {
                searchDocument.content += removeHtmlAndSpecificTags(entry.returns);
            }
            if (entry.remarks) {
                searchDocument.content += removeHtmlAndSpecificTags(entry.remarks);
            }

            if (search === "algolia") {
                currentIDNumber = getID(searchDocument);
                let sizedRecords: AlgoliaSection[] = [];
                splitRecords(searchDocument as AlgoliaSection, sizedRecords, currentIDNumber);
                index.push(...sizedRecords);
            } else {
                index.push(searchDocument);
            }
        }
    });
};

export const splitRecords = (firstSearchObject: AlgoliaSection, firstRecordArray: AlgoliaSection[], idNumber: number): number => {
    let incNumber = idNumber;
    const splitRecordsRec = (searchObject: AlgoliaSection, recordArray: AlgoliaSection[]): void => {
        let size = Buffer.byteLength(JSON.stringify(searchObject)); //algolia restricts index size to 10k bytes
        if (size <= 10000) {
            recordArray.push(searchObject);
        } else {
            let secondIndex = { ...searchObject };
            secondIndex.objectID = incNumber++;
            secondIndex.content = searchObject.content.substring(searchObject.content.length / 2);
            searchObject.content = searchObject.content.substring(0, searchObject.content.length / 2);
            splitRecordsRec(searchObject, recordArray);
            splitRecordsRec(secondIndex, recordArray);
        }
    };
    splitRecordsRec(firstSearchObject, firstRecordArray);
    return incNumber;
};
