import { HeaderSections } from "../Header/headerSectionsEnum";
import { TableOfContentsItem } from "../TableOfContents/TableOfContents";
import { ReferenceSections } from "./referenceSectionsEnum";
import { fetchXml } from "./utils/apiReferenceUtils";
import renderStringWithUrl from "./utils/renderStringWithUrl";
import Page from "../Page/Page";
import { useNavigate } from "react-router-dom";
import { Button, Waiting } from "@dev/zenith";
import { ButtonType } from "@dev/zenith/dist/button/buttonType";
import BetaPill from "../BetaPill/BetaPill";
import { PageTitleProps } from "../PageTitle/PageTitle";
import myGParser, { MyGMethodInfo, MyGObjectInfo, MyGParserOutput } from "./utils/myGParser";
import myAParser, { MyAMethodInfo, MyAObjectInfo, MyAParserOutput } from "./utils/myAParser";
import { useEffect, useState } from "react";
import { searchIndex } from "../../utils/searchIndex";
import "./reference.scss";
import { setStorageAndSearch } from "../SearchModal/utils/minisearchUtils";

interface ApiReferenceProps {
    headerSection: HeaderSections;
    referenceSection: ReferenceSections;
    entryType: string;
}

interface ReferenceCache {
    myGMethods: ReferenceEntry[];
    myGObjects: ReferenceEntry[];
    myAMethods: ReferenceEntry[];
    myAObjects: ReferenceEntry[];
}

export interface ReferenceEntry {
    name: string;
    details: MyGMethodInfo | MyGObjectInfo | MyAMethodInfo | MyAObjectInfo;
}

export const referenceDataPromise = (async () => {
    try {
        let xml: Document = await fetchXml(ReferenceSections.MyGeotab);

        let myGObjectsJson: MyGParserOutput = myGParser(xml, "object");
        const parsedMyGObjects = setStorageAndSearch(myGObjectsJson, ReferenceSections.MyGeotab, searchIndex);

        let myGMethodsJson: MyGParserOutput = myGParser(xml, "method");
        const parsedMyGMethods = setStorageAndSearch(myGMethodsJson, ReferenceSections.MyGeotab, searchIndex);

        xml = await fetchXml(ReferenceSections.MyAdmin);

        let myAObjectsJson: MyAParserOutput = myAParser(xml, "object");
        const parsedMyAObjects = setStorageAndSearch(myAObjectsJson, ReferenceSections.MyAdmin, searchIndex);

        let myAMethodsJson: MyAParserOutput = myAParser(xml, "method");
        const parsedMyAMethods = setStorageAndSearch(myAMethodsJson, ReferenceSections.MyAdmin, searchIndex);

        return {
            myGMethods: parsedMyGMethods,
            myGObjects: parsedMyGObjects,
            myAMethods: parsedMyAMethods,
            myAObjects: parsedMyAObjects
        };
    } catch (error) {
        console.error("Error fetching or parsing the XML:", error);
        throw error;
    }
})();

const filterEntries = (referenceCache: ReferenceCache, referenceSection: ReferenceSections, entryType: string): ReferenceEntry[] => {
    if (referenceSection === ReferenceSections.MyGeotab) {
        return entryType === "Methods" ? referenceCache.myGMethods : referenceCache.myGObjects;
    } else {
        return entryType === "Methods" ? referenceCache.myAMethods : referenceCache.myAObjects;
    }
};

export default function ApiReference({ headerSection, referenceSection, entryType }: ApiReferenceProps): JSX.Element {
    let referenceItems: JSX.Element[] = [];
    const pageSections: TableOfContentsItem[] = [];
    const [entries, setEntries] = useState<ReferenceCache>({ myGMethods: [], myGObjects: [], myAMethods: [], myAObjects: [] });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        referenceDataPromise
            .then((parsedEntries) => {
                setEntries(parsedEntries);
                setIsLoading(false);
                setHasError(false);
            })
            .catch((error) => {
                console.error("An error occurred while processing the objects:", error);
                setIsLoading(false);
                setHasError(true);
            });
    }, [entries, isLoading]);

    if (hasError) {
        throw new Error("Unable to pull reference data");
    }

    if (isLoading) {
        return (
            //TODO: Check Loading Spinner
            <div title="waiting-screen">
                <Waiting isLoading />
            </div>
        );
    }

    let filteredEntries: ReferenceEntry[] = filterEntries(entries, referenceSection, entryType);
    referenceItems = filteredEntries.map((entryDetails: ReferenceEntry) => {
        let pageSectionObject: TableOfContentsItem = {
            elementId: entryDetails.name,
            summary: entryDetails.name,
            details: renderStringWithUrl(entryDetails.name, entryDetails.details.description, headerSection)
        };

        const handleViewDetails = () => {
            navigate(`./${entryDetails.name}`);
        };

        pageSections.push(pageSectionObject);

        let displayName: string = entryType === "Methods" ? entryDetails.name + " (...)" : entryDetails.name;

        return (
            <div key={entryDetails.name} className="paragraph method-object-container" id={entryDetails.name}>
                <h3 className="methods__method-title">
                    {displayName}
                    {entryDetails.details.isBeta && <BetaPill />}
                    <div className="methods__view-button">
                        <Button type={ButtonType.Primary} title={`View ${displayName} details`} aria-label={`View ${entryDetails.name} details`} onClick={handleViewDetails}>
                            View
                        </Button>
                    </div>
                </h3>
                {renderStringWithUrl(entryDetails.name, entryDetails.details.description, headerSection)}
            </div>
        );
    });

    const pageTitle: PageTitleProps = {
        title: entryType,
        breadCrumbItems: [referenceSection, "API Reference", entryType]
    };

    return (
        <Page section={headerSection} pageTitle={pageTitle} tableOfContents={pageSections}>
            {referenceItems}
        </Page>
    );
}
