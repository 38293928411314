import { ReactNode, useContext, useEffect } from "react";
import PageContent from "../PageContent/PageContent";
import { PageTitleProps } from "../PageTitle/PageTitle";
import { HeaderSections } from "../Header/headerSectionsEnum";
import { TableOfContentsItem } from "../TableOfContents/TableOfContents";
import MenuContext from "../../menuContext";

interface PageProps {
    section: string;
    pageTitle?: PageTitleProps;
    tableOfContents?: TableOfContentsItem[];
    children?: ReactNode;
}

export default function Page(props: PageProps): JSX.Element {
    const isLandingPage: boolean = props.section === HeaderSections.Landing;

    const { setActiveSiteSection } = useContext(MenuContext);

    useEffect((): void => {
        setActiveSiteSection(props.section);
    });

    return (
        <PageContent isLandingPage={isLandingPage} pageTitle={props.pageTitle} pageContent={props.children} tableOfContents={props.tableOfContents} />
    );
}
