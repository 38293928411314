import { ReactNode } from "react";

import { Page } from "./../../../components";
import { PageTitleProps } from "./../../../components/PageTitle/PageTitle";
import { HeaderSections } from "./../../../components/Header/headerSectionsEnum";
import { TableOfContentsItem } from "./../../../components/TableOfContents/TableOfContents";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import CodeSample from "../../../components/CodeSamplesContainer/CodeSample";
import InformationalBox from "../../../components/InformationalBox/InformationalBox";

import driveAddInImage from "./../../../assets/images/driveAddIns/drive-addins_0.png";
import driveSSOHosMainPage from "./../../../assets/images/driveAddIns/driveSSOHosMainPage.png";
import driveSSOHosLoginPage from "./../../../assets/images/driveAddIns/driveSSOHosLoginPage.png";
import driveSSOHosVerifyOnly from "./../../../assets/images/driveAddIns/driveSSOHosVerifyOnly.png";
import driveAppBoundDomainsErrorScreen from "./../../../assets/images/driveAddIns/driveAppBoundDomainsErrorScreen.png";

const launchingDriveAddins: ReactNode = (
    <div className="paragraph">
        <p>Geotab Drive Add-Ins will also display differently, on both the dashboard and the menu.</p>
        <img src={driveAddInImage} alt="Drive dashboard" />
        <p>
            To make an Add-In on the Geotab Drive app, the <strong>item</strong> in your configuration file must have a <strong>path</strong> that equals "<strong>DriveAppLink/</strong>" (including
            a trailing forward slash). The menuName, url, and version will remain unchanged. For example:
        </p>
        <h2>Listing 1 - Geotab Drive "item" configuration</h2>
        <CodeSample
            language="json"
            code={`"items": [{
    "version": "1.0",
    "url": "addinFile.html",
    "path": "DriveAppLink/",
    "menuName": {
        "en": "English Label",
        "fr": "French Label"
    }
}]`}
        />
        <InformationalBox>
            <p>
                The array of <strong>items</strong> also allows you to have one link item in MyGeotab, and another link item to Geotab Drive
            </p>
        </InformationalBox>
        <p>
            There are also 2 additional properties that are optional for the configuration file that control the availability to additional page lifecycle methods, <em>onStartup</em> and{" "}
            <em>onShutdown</em>. These configuration properties are boolean, they can be set to <em>true</em> if they are to be used and <em>false</em> when not in use. By default these 2 properties
            will be set to false if not included in the configuration file.
        </p>
        <ol>
            <li>
                <strong>onStartup:</strong> Startup Add-Ins are executed when a driver logs in to the Drive App for the first time.
            </li>
            <li>
                <strong>onShutdown:</strong> The onShutdown property must be set to true to execute an Add-In when logging out of Geotab Drive.
            </li>
        </ol>
        <InformationalBox>
            <p>
                More information about these 2 methods can be found in this{" "}
                <a
                    href="https://docs.google.com/document/d/1-r9o9epj61WMmGxRveA9SXR86lQGHcxgMh8lsVXGL54/edit?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Drive App Add-In SDK (Software Development Kit)"
                >
                    document
                </a>
                .
            </p>
        </InformationalBox>
        <p>e.g.</p>
        <CodeSample
            language="json"
            code={`{
    "name": "addin-name",
    "supportEmail": "mysupport@support.com",
    "version": "1.0.0",
    "items": [{
        "url": "index.html",
        "path": "DriveAppLink/",
        "menuName": {
            "en": "Add-In"
        },
        "icon": "images/icon.svg"
    }, {
        "url": "myGeotabConfigurationPage.html",
        "path": "AdministrationLink/",
        "menuName": {
            "en": "Add-In Configurations"
        },
        "icon": "images/icon.svg"
    }],
    "onStartup": true,
    "onShutdown": true
}`}
        />
    </div>
);

const driveThirdPartyFromURISchema: ReactNode = (
    <div className="paragraph">
        <InformationalBox>
            <p>Drive app v4.1.0+</p>
        </InformationalBox>
        <p>
            It's possible to open different applications like prontoforms or native calendar from Add-Ins. To do so, it's important to construct correct URI schema string and pass it to{" "}
            <code>window.open</code>.
        </p>
        <p>For example:</p>
        <CodeSample language="javascript" code={`window.open(uriSchemaString, "_system")`} />
        <p>Make sure to read carefully documentation of the app you're trying to open to use correct schema. For example, to open twitter application from Add-In you should use:</p>
        <CodeSample language="javascript" code={`window.open("twitter://messages", "_system")`} />
        <p>
            You can't use just <code>twitter://</code> as it's not correct and app won't open. You need to specify which page you want to open:{" "}
            <code>messages</code>, <code>account</code> etc.
        </p>
        <p>To open webpage you need to use the same method, but with this notation:</p>
        <CodeSample language="javascript" code={`window.open("https://google.com", "_blank")`} />
        <InformationalBox>
            <p>
                <code>_blank</code> is important, especially for iOS devices
            </p>
        </InformationalBox>
    </div>
);

const driveFromThirdParty: ReactNode = (
    <div className="paragraph">
        <p>On Android and iOS devices with the Geotab Drive app installed, a URL handler is registered which can:</p>
        <ul>
            <li>Launch Drive</li>
            <li>Navigate to a specified page in Drive</li>
            <li>Automatically log into Drive with a token (session ID, user name, and database name) retrieved by authenticating against MyGeotab's API in a third-party app</li>
        </ul>
        <p>Deep linking is used to provide a seamless link from a third-party app into the Geotab Drive app.</p>
        <p>On the most basic level, launching Geotab Drive to the main screen, can be executed by creating a link to:</p>
        <CodeSample language="javascript" code={`geotabdrive://`} />

        <p>From there, it's possible to automatically login and/or link to specific modules or pages of the Geotab Drive app.</p>
        <p>
            For more information on how to automatically log in, enable single sign-on, and navigate to desired pages on the Geotab Drive app, please refer to the{" "}
            <HashLink to="/drive/addIns/developingAddins#drive-single-sign-on">Single sign-on</HashLink> section below.
        </p>
    </div>
);

const driveSingleSignOn: ReactNode = (
    <div className="paragraph">
        <h3>Automatic login and single sign-on</h3>
        <p>
            If the application is newly installed (the driver has not previously logged in) or the authentication is handled by a 3rd party application, it's possible to login using the{" "}
            <code>geotabdrive://</code> link. This prevents a driver from having to enter their login information twice.
        </p>
        <InformationalBox>
            <p>Note that automated login will always direct the driver through the login workflow as per regulations</p>
        </InformationalBox>
        <p>The required parameters to automatically login are:</p>
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Parameter</th>
                        <th>Example</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>UserName</td>
                        <td>joe@mycompany.com</td>
                    </tr>
                    <tr>
                        <td>Session ID</td>
                        <td>1234567</td>
                    </tr>
                    <tr>
                        <td>Database name</td>
                        <td>my_company</td>
                    </tr>
                    <tr>
                        <td>Server name</td>
                        <td>my.geotab.com</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p>
            The session ID is obtained by authenticating against the MyGeotab API, which should be performed by the 3rd party application. This can be obtained using the standard{" "}
            <HashLink to="/myGeotab/guides/concepts#authentication">Authenticate API call</HashLink> or, if you're using SAML, the authenticateSaml API call (refer to the{" "}
            <a
                href="https://docs.google.com/document/d/1HlFpXjG09JOaRaf7ocXGEPsWVDGvjWOofz18h8DZL18/edit#heading=h.6gqkmkwes4dm"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Geotab MyGeotab SSO via SAML"
            >
                SAML 2.0 Setup guide
            </a>{" "}
            for more details).
        </p>
        <InformationalBox>
            Both the Authenticate() and AuthenticateSaml() API calls return a <Link to="/myGeotab/apiReference/objects/LoginResult">LoginResult</Link> object, containing the credentials
            (database, userName, and sessionId) and the server name.
        </InformationalBox>
        <p>Once these parameters are obtained, the URL can be constructed as follows, using the example information as above:</p>
        <CodeSample language="javascript" code={`geotabdrive://login,(credentials:(database:'my_company',userName:'joe@mycompany.com',sessionId:'1234567'),server:'my.geotab.com')`} />
        <p>If there is a target page, add the 'next' parameter to the page parameters, like:</p>
        <CodeSample language="javascript" code={`geotabdrive://login,(next:hos/main,credentials:(database:'my_company',userName:'joe@mycompany.com',sessionId:'1234567'),server:'my.geotab.com')`} />
        <p>The above will log in the driver, direct them through the login workflow (selecting vehicle, performing DVIR), and then end up at the target page.</p>
        <h3>Codrivers</h3>
        <p>
            To login a co-driver, if there's already a driver logged in, then by triggering a <code>geotabdrive://</code> scheme URL with a <strong>new</strong>{" "}
            driver's session it will log them in as a co-driver.
        </p>
        <p>There isn't an API available to discover whether drivers are already logged in.</p>
        <p>
            If the <em>new</em> driver's session is for a different database or server URL, then they will be rejected for automatic authentication.
        </p>
        <h3>Pages & Parameters</h3>
        <p>The deep link URL follows the URL scheme found by using Geotab Drive in the browser, and as such, the URLs for pages can be found by first navigating to:</p>
        <a href="https://my.geotab.com/drive" target="_blank" rel="noopener noreferrer" aria-label="MyGeotab drive page">
            https://my.geotab.com/drive
        </a>{" "}
        <p>And then navigating around until the desired page is found. For instance, getting the HOS main page:</p>
        <img src={driveSSOHosMainPage} alt="HOS main page"></img>
        <p>
            The URL fragment of interest here is the{" "}
            <em>
                <u>hos/main</u>
            </em>{" "}
            segment, which can then be used in the above deep link URL as:
        </p>
        <CodeSample language="javascript" code={`geotabdrive://hos/main`} />
        <p>If the driver is not currently logged in, they will be prompted to first, before being redirected to the target page.</p>
        <InformationalBox>
            In this <HashLink to="/drive/addIns/developingAddins#drive-single-sign-on">section</HashLink>, the doc outlined how to pre-authenticate so the driver will be able to instantly launch,
            login, and visit the target page with one tap.
        </InformationalBox>
        <p>
            For an example of how to pass page parameters to the page, the HOS Verify page uses a parameter “verifyOnly” to the main HOS Logs page. The URL fragment of interest as before now
            includes a parameter, in parentheses, separated from the main page name by a comma:
        </p>
        <img src={driveSSOHosVerifyOnly} alt="HOS verify logs"></img>
        <p>The deep link URL in this case would look something like:</p>
        <CodeSample language="javascript" code={`geotabdrive://hos/logs,(verifyOnly:true)`} />
        <InformationalBox>
            The URL parameters are formatted in{" "}
            <a href="https://github.com/Nanonid/rison" target="_blank" rel="noopener noreferrer" aria-label="MyGeotab drive page">
                Rison
            </a>
            , a representation of JSON data which is URL-safe.
        </InformationalBox>
        <h3>Forcing the login workflow</h3>
        <p>
            Linking to the pages above using the deep link scheme <strong>does not</strong> force the user to use the login workflow if they are already logged in (for example, selecting vehicles
            and performing DVIR).
        </p>
        <p>If the login workflow is required without using a target page, deep link to:</p>
        <CodeSample language="javascript" code={`geotabdrive://login`} />
        <p>
            If the login workflow <strong>is</strong> required when using a target page, pass the target page as the “next” parameter to the login page:
        </p>
        <img src={driveSSOHosLoginPage} alt="Drive login workflow"></img>
        <p>The deep link URL would look like:</p>
        <CodeSample language="javascript" code={`geotabdrive://login,(next:dvir/main)`} />
        <p>If a link is required to a page with parameters, the "next" value should be enclosed in apostrophes:</p>
        <CodeSample language="javascript" code={`geotabdrive://login,(next:'hos/logs,(verifyOnly:true)')`} />
        <h3>Examples</h3>
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Page</th>
                        <th>Deep link URL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Bring app to foreground on current page; or launch app</td>
                        <td>geotabdrive://</td>
                    </tr>
                    <tr>
                        <td>Main</td>
                        <td>geotabdrive://main</td>
                    </tr>
                    <tr>
                        <td>HOS Main</td>
                        <td>geotabdrive://hos</td>
                    </tr>
                    <tr>
                        <td>HOS Logs</td>
                        <td>geotabdrive://hos/logs</td>
                    </tr>
                    <tr>
                        <td>HOS Graph</td>
                        <td>geotabdrive://hos/graph</td>
                    </tr>
                    <tr>
                        <td>DVIR Main</td>
                        <td>geotabdrive://dvir</td>
                    </tr>
                    <tr>
                        <td>Messages Main</td>
                        <td>geotabdrive://messaging</td>
                    </tr>
                    <tr>
                        <td>Force login workflow</td>
                        <td>geotabdrive://login</td>
                    </tr>
                    <tr>
                        <td>DVIR Logs with workflow</td>
                        <td>geotabdrive://login,(next:dvir/main)</td>
                    </tr>
                    <tr>
                        <td>HOS Verify with workflow</td>
                        <td>geotabdrive://login,(next:'hos/logs,(verifyOnly:true)')</td>
                    </tr>
                    <tr>
                        <td>Automatic login</td>
                        <td>geotabdrive://login,(credentials:(database:'my_company',userName:'joe@mycompany.com',sessionId:'1234567'),server:'my12.geotab.com')</td>
                    </tr>
                    <tr>
                        <td>Automatic login with HOS Main as the target page</td>
                        <td>geotabdrive://hos,(credentials:(database:'my_company',userName:'joe@mycompany.com',sessionId:'1234567'),server:'my12.geotab.com')</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <h3>Encoding Deep Links on iOS</h3>
        <p>Recent versions of iOS require encoding of deep links when they are used with the platform's URL class. </p>
        <CodeSample
            language="javascript"
            code={`let path = "login,(credentials:(database:'testdb',sessionId:'123456',userName:'tester@geotab.com'),server:'my.geotab.com')"
let url = URL(string: "geotabdrive://" + path.addingPercentEncoding(withAllowedCharacters: .urlPathAllowed)!)
`}
        />
        <p>
            Use the <code>addingPercentEncoding(withAllowedCharacters: .urlPathAllowed)</code> API to transform the path component of the link and append it to the{" "}
            <code>geotabdrive://</code> scheme afterward.
        </p>
    </div>
);

const driveAppBoundDomains: ReactNode = (
    <div className="paragraph">
        <h3>App-Bound Domains on iOS</h3>
        <p>
            Geotab Drive uses{" "}
            <a href="https://webkit.org/blog/10882/app-bound-domains/" target="_blank" rel="noreferrer noopener" aria-label="MyGeotab drive page">
                App-Bound Domains
            </a>
            {" "}on iOS, boosting the privacy and security for its users. This feature ensures that when you're using the Geotab Drive app, it remains within Geotab's own websites and doesn't venture out to others that are not predefined—fortifying driver digital protection.
        </p>
        <p>
            With App-Bound Domains, the primary web view of the Geotab Drive app will be blocked from loading any website not belonging to Geotab's designated domains, such as those under geotab.com. If the web view tries to navigate to a site outside of the allowed domains, an error message will pop up.
        </p>
        <img src={driveAppBoundDomainsErrorScreen} alt="App-Bound domain error screen"></img>
        <p>
            Note that this domain-bound restriction is specific to direct navigation within the main web view—things like link clicks or script-directed changes to the web page address. Alternative elements like new browser windows, inline frames (iframes), or general web requests <b>aren't affected by this restriction</b>, although standard rules of web interaction still apply.
        </p>
        <p>
            If you need your Add-In to go beyond the geotab.com domain within the web view, opting for an iframe or triggering a new browser window with <code>window.open()</code> with a target set to <code>_self</code>, could be the workaround.
        </p>
        <p>
            Moreover, the native APIs that Geotab apps inject into web views are only accessible on the bound domains on the main web view. Consequently, any Add-In running in an iframe or a new window won't be able to tap into the api.mobile JavaScript API.
        </p>
    </div>
);

const pageTitle: PageTitleProps = {
    title: "Developing Add-Ins",
    breadCrumbItems: ["Drive", "Developing Add-Ins"]
};

const pageSections: TableOfContentsItem[] = [
    {
        elementId: "launching-drive-add-ins",
        summary: "Launching Drive Add-Ins",
        details: launchingDriveAddins
    },
    {
        elementId: "drive-add-in-third-party-uri-schema",
        summary: "Opening third-party applications using URI schema",
        details: driveThirdPartyFromURISchema
    },
    {
        elementId: "drive-add-in-from-third-party",
        summary: "Opening Geotab Drive from third-party applications (aka deep linking)",
        details: driveFromThirdParty
    },
    {
        elementId: "drive-single-sign-on",
        summary: "Single sign-on",
        details: driveSingleSignOn
    },
    {
        elementId: "drive-app-bound-domains",
        summary: "App-bound domains",
        details: driveAppBoundDomains
    }
];

export default function DriveDevelopingAddIns() {
    return (
        <Page section={HeaderSections.Drive} pageTitle={pageTitle} tableOfContents={pageSections}>
            <div className="paragraph">
                <p>
                    All Add-Ins that have been designed to work with MyGeotab will work on the Geotab Drive app as well. Your Add-In will be completely downloaded for all referenced links, images,
                    and scripts upon user login. This way, as the user is authenticated over the internet - they will have your Add-In with them as they travel or disconnect from the network. If
                    your Add-In requires dynamic loading of CSS, images, or JavaScript - these requests will fail if the user does not have a network connection. As such you should either: include
                    all dependencies on creation of the Add-In, explicitly link to them, or provide a fallback if state.online returns False.
                </p>
            </div>
        </Page>
    );
}
