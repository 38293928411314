import { ReactNode, useEffect, useState } from "react";
import Header from "../Header/Header";
import { Footer, PageTitle, TableOfContents } from "..";
import { PageTitleProps } from "../PageTitle/PageTitle";
import "./pageContent.scss";
import { TableOfContentsItem } from "../TableOfContents/TableOfContents";
import { createAccordions } from "../Accordion/Accordion";
import TableOfContentsMobile from "../TableOfContents/TableOfContentsMobile";
import HeaderMobile from "../Header/HeaderMobile";

interface PageContentProps {
    isLandingPage: boolean;
    pageTitle?: PageTitleProps;
    pageContent: ReactNode;
    tableOfContents?: TableOfContentsItem[];
}

const MOBILE_SCREEN_SIZE = 960;

export default function PageContent({ isLandingPage, pageTitle, pageContent, tableOfContents }: PageContentProps): JSX.Element {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= MOBILE_SCREEN_SIZE);
    function handleWindowSizeChange(): void {
        setIsMobile(window.innerWidth <= MOBILE_SCREEN_SIZE);
    }

    useEffect((): void => {
        window.addEventListener("resize", handleWindowSizeChange);
    }, [isMobile]);

    return (
        <div className="pageContent__container" data-testid="page-component">
            {isMobile && <HeaderMobile isLandingPage={isLandingPage} />}
            {!isMobile && <Header isLandingPage={isLandingPage} />}
            <div className="pageContent__subContainer">
                {pageTitle && <PageTitle {...pageTitle} />}
                {(tableOfContents && tableOfContents.length > 1 && isMobile) && <TableOfContentsMobile items={tableOfContents} />}
                <div className={isLandingPage ? "pageContent__landing" : "pageContent"}>
                    <div className={isLandingPage ? "" : "pageContent__scrollableArea"}>
                        {pageContent}
                        {!pageTitle?.title.toLowerCase().match(/^(methods|method|objects|object)$/) && createAccordions(tableOfContents || [])}
                    </div>
                    {(tableOfContents && tableOfContents.length > 1 && !isMobile) && <TableOfContents items={tableOfContents} />}
                </div>
                <Footer />
            </div>
        </div>
    );
}
