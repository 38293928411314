import { ReferenceEntry } from "../ApiReference";
import { ReferenceSections } from "../referenceSectionsEnum";
import { MyAParserOutput } from "./myAParser";
import { MyGParserOutput } from "./myGParser";

const RUNNER_URL: string = "https://geotab.github.io/sdk/software/api/runner.html";
export const WIP_STRING = "/* Work in progress - Coming soon */";

export interface RateLimit {
    name: string;
    limit: string;
    period: string;
    description: string;
    status: string;
}

export interface Pagination {
    supportedSorts: string[];
    resultsLimit: string;
    isBeta: boolean;
    dateSortProperty?: string;
}

interface XmlUrlInfo {
    mainUrl: string;
    backupUrl: string;
}

type SortableEntry = [string, object];

export default function sortAlphabetical(a: SortableEntry, b: SortableEntry) {
    if (a[0] < b[0]) {
        return -1;
    }

    if (a[0] > b[0]) {
        return 1;
    }

    return 0;
}

const XML_URLS: { [key: string]: XmlUrlInfo } = {
    [ReferenceSections.MyGeotab]: {
        "mainUrl": "https://alpha.geotab.com/sdk.xml",
        "backupUrl": "https://my.geotab.com/sdk.xml"
    },
    [ReferenceSections.MyAdmin]: {
        "mainUrl": "https://myadmin.geotab.com/sdk-xml/MyAdminAPI.xml",
        "backupUrl": "https://developers.geotab.com/MyAdminAPI.xml"
    }
};

export const getXmlText = async (referenceSection: ReferenceSections): Promise<string> => {
    try {
        let response: Response = await fetch(XML_URLS[referenceSection].mainUrl);

        if (!response.ok) {
            throw new Error(`Network response was not 200 for ${referenceSection}: ${response.status}`);
        } else {
            let xmlText: string = await response.text();
            return xmlText;
        }
    } catch (error) {
        console.warn(`Failed to fetch main URL for ${referenceSection} (${XML_URLS[referenceSection].mainUrl}), resorting to back-up. More details:`, error);

        try {
            let response: Response = await fetch(XML_URLS[referenceSection].backupUrl);

            if (!response.ok) {
                throw new Error(`Network response was not 200 for ${referenceSection}: ${response.status}`);
            } else {
                let xmlText: string = await response.text();
                return xmlText;
            }
        } catch (secondError) {
            console.error(`Failed to fetch back-up URL for ${referenceSection} (${XML_URLS[referenceSection].backupUrl}). More details:`, secondError);
            return "";
        }
    }
};

export const makeTryMeLink = (snippet: string): string => {
    let sample = {
        javascript: snippet,
        css: "",
        html: "",
        options: {
            js: true,
            css: false,
            html: false,
            console: true,
            output: true
        }
    };
    return RUNNER_URL + "#" + btoa(encodeURIComponent(JSON.stringify(sample)));
};

export const getReferenceEntries = (json: MyGParserOutput | MyAParserOutput): ReferenceEntry[] =>
    Object.entries(json)
        .sort(sortAlphabetical)
        .map(
            ([itemName, itemDetails]) =>
                ({
                    name: itemName,
                    details: itemDetails
                }) as ReferenceEntry
        );

export const fetchXml = async (section: ReferenceSections): Promise<Document> => {
    let xmlText: string = await getXmlText(section);
    let parser: DOMParser = new DOMParser();
    return parser.parseFromString(xmlText, "text/xml");
};
