export default function CustomLightModeTheme(): Record<string, string> {
    return {
        lineNumberColor: "#37474f",
        lineNumberBgColor: "#f1f3f4",
        backgroundColor: "#f1f3f4",
        textColor: "#37474f",
        substringColor: "#188038",
        keywordColor: "#1967d2",
        attributeColor: "#9334e6",
        selectorTagColor: "#37474f",
        docTagColor: "#37474f",
        nameColor: "#37474f",
        builtInColor: "#37474f",
        literalColor: "#37474f",
        bulletColor: "#37474f",
        codeColor: "#37474f",
        additionColor: "#37474f",
        regexpColor: "#37474f",
        symbolColor: "#37474f",
        variableColor: "#b80672",
        templateVariableColor: "#37474f",
        linkColor: "#37474f",
        selectorAttributeColor: "#1967d2",
        selectorPseudoColor: "#37474f",
        typeColor: "#9334e6",
        stringColor: "#188038",
        selectorIdColor: "#37474f",
        quoteColor: "#37474f",
        templateTagColor: "#1967d2",
        deletionColor: "#37474f",
        titleColor: "#37474f",
        sectionColor: "#9334e6",
        commentColor: "#b80672",
        metaKeywordColor: "#1967d2",
        metaColor: "#37474f",
        functionColor: "#37474f",
        numberColor: "#c5221f"
    };
}
