import { IconSearch } from "@dev/zenith/dist/icons/iconSearch";

export default function SearchPrompt(): JSX.Element {
    return (
        <div className="empty-search-container">
            <IconSearch size={"large"} />
            <span>Start typing to search</span>
        </div>
    );
}
