import React, { createContext, useState, ReactNode } from "react";
import { HeaderSections } from "./components/Header/headerSectionsEnum";

interface MenuContextType {
    activeSiteSection: string;
    setActiveSiteSection: (activeSiteSection: string) => void;
}

const MenuContext = createContext<MenuContextType>({
    activeSiteSection: HeaderSections.Landing,
    setActiveSiteSection: () => {}
});

interface MenuProviderProps {
    children: ReactNode;
}

export const MenuProvider: React.FC<MenuProviderProps> = ({ children }) => {
    const [activeSiteSection, setActiveSiteSection] = useState<string>(HeaderSections.Landing);

    return <MenuContext.Provider value={{ activeSiteSection, setActiveSiteSection }}>{children}</MenuContext.Provider>;
};

export default MenuContext;
